import { readToken, writeToken } from './ls-token';
import { removeAuthHeaders, setAuthHeaders } from '../api/api-instance';
import { validateToken } from '../api/auth-api';
import { SET_AUTHORIZED, TOGGLE_PRELOADER } from '../../store/action-types';
import { store } from '../../store/store';
import { clearLocalStorage } from './ls';
import { LS_AUTH_TOKEN_KEY } from '../constants';
import { actualizeMeetings } from './actualize-meetings';
import { actualizeProfile } from './actualize-profile';
import { setCurrentProfile } from "../../store/profile/actions";

const handleOnInvalidCredentials = (dispatch) => {
  clearLocalStorage(LS_AUTH_TOKEN_KEY);
  removeAuthHeaders();
  dispatch(setCurrentProfile());
  dispatch({ type: SET_AUTHORIZED, payload: false });
};

export const authorize = async (token = readToken()) => {
  const dispatch = store.dispatch;

  if (token) {
    setAuthHeaders(token);

    try {
      const { code } = await validateToken(token);

      if (code === 200) {
        writeToken(token);
        await actualizeProfile(dispatch);

        // Avoid unexpected token reset on app crashes
        try {
          dispatch({ type: SET_AUTHORIZED, payload: code === 200 });
          dispatch({ type: TOGGLE_PRELOADER, payload: false });
        } catch (e) {
          console.error(e);
        }

        actualizeMeetings(dispatch);

        return true;
      }
    } catch (e) {
      handleOnInvalidCredentials(dispatch);
    }
  }

  dispatch({ type: SET_AUTHORIZED, payload: false });

  return false;
};
