import React from 'react';
import styled from 'styled-components';
import { FormGroup } from '../FormGroup';
import { Button } from '../Button';
import { COLORS } from '../../constants';

//todo имплементировать этот компонент в Unite и Recommendations
const Card = styled((props) => {
  return <div className={props.className}>{props.children}</div>;
})`
  position: relative;
  border-radius: 6px;
  background-color: ${COLORS.greyBg};
  border: 1px solid ${COLORS.grey2};
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;

  ${FormGroup.Container} {
    input {
      background-color: white;
    }
  }

  ${Button.Container} {
    &:disabled {
      background-color: white;
    }
  }
`;

export { Card };
