import React, { useCallback, useEffect, useState } from 'react';
import { Unite } from '../../common/components/Unite';
import { Container } from '../../common/components/Container';
import { Header } from '../../common/components/Header';
import { Margin } from '../../common/components/Margin';
import { getMeetingsList } from '../../common/api/meetings-api';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../common/components/Heading';
import { UserCard } from '../../common/components/UserCard';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

const ExtendedUniteSkeleton = styled((props) => <UserCard.Skeleton className={props.className} />)`
  margin-top: ${(p) => p.top ?? 16}px;
`;

const Unites = () => {
  const { t } = useTranslation();
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMeetings = async () => {
    const data = await getMeetingsList();
    setMeetings(data.meetings);
    setLoading(false);
  };

  useEffect(() => {
    document.title = t('Uniter — Мои юнайты');
    fetchMeetings();
  }, []); //eslint-disable-line

  const currentMeetings = meetings.filter((m) => m.current);
  const previousMeetings = meetings.filter((m) => !m.current);
  const hasCurrentMeetings = !!currentMeetings.length;
  const hasPreviousMeetings = !!previousMeetings.length;

  const mapMeetings = useCallback((m, idx) => {
    return (
      <Margin top={idx ? 16 : 24} key={m.id}>
        <Unite
          id={m.id}
          updated={m.updated}
          reviewedData={{ teamComment: m.team_comment, personalComment: m.personal_comment, score: m.score }}
          result={m.result}
          fetchCb={fetchMeetings}
          partner={m.partner}
        />
      </Margin>
    );
  }, []);

  return (
    <Container>
      <Header>{t('Мои юнайты')}</Header>
      {!loading && !hasCurrentMeetings && !hasPreviousMeetings && (
        <p>{t('Мы еще ни с кем не успели вас познакомить, в понедельник отправим приглашение на почту')}</p>
      )}

      {loading && (
        <>
          <Skeleton height={32} />
          <ExtendedUniteSkeleton top={24} />
          <ExtendedUniteSkeleton />
          <ExtendedUniteSkeleton />
        </>
      )}
      {hasCurrentMeetings && (
        <>
          <Heading level={2}>{t('Текущие юнайты')}</Heading>
          {currentMeetings.map(mapMeetings)}
        </>
      )}
      {hasPreviousMeetings && (
        <Margin top={hasCurrentMeetings && 56}>
          <Heading level={2}>{t('Прошедшие юнайты')}</Heading>
          {previousMeetings.map(mapMeetings)}
        </Margin>
      )}
    </Container>
  );
};

export { Unites };
