import React from 'react';

export const SIDEBAR_ICONS = {
  search: (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24" height="24"
         viewBox="0 0 24 24"
         fill="none"
         stroke="currentColor"
         strokeWidth="2"
         strokeLinecap="round"
         strokeLinejoin="round"
         >
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
      <circle cx="12" cy="12" r="3"></circle>
    </svg>
  ),
  feedback: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="5"
        width="20"
        height="14"
        rx="2"
        stroke="#98A1AB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 6L12 12L21 6" stroke="#98A1AB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  unites: (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3" />
      <line x1="4" y1="21" x2="20" y2="21" />
    </svg>
  ),
  invites: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="8" r="5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6183 16.5901C12.8182 16.2096 11.9279 16 11.0003 16C8.33191 16 5.97316 17.7341 5.17726 20.281L5.04579 20.7017C4.88105 21.2289 5.17485 21.7897 5.70199 21.9545C6.22914 22.1192 6.79001 21.8254 6.95475 21.2983L7.08622 20.8776C7.6212 19.1656 9.20668 18 11.0003 18C11.727 18 12.4195 18.1913 13.0218 18.5328C13.0866 17.8335 13.2954 17.1759 13.6183 16.5901Z"
        fill="#98A1AB"
      />
      <path d="M16 19H22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 16V22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  settings: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="7" r="3" stroke="#98A1AB" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7324 8C16.9026 7.70583 17 7.36429 17 7C17 6.63571 16.9026 6.29417 16.7324 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H16.7324ZM13.2676 8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H13.2676C13.0974 6.29417 13 6.63571 13 7C13 7.36429 13.0974 7.70583 13.2676 8Z"
        fill="#98A1AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7324 18C10.9026 17.7058 11 17.3643 11 17C11 16.6357 10.9026 16.2942 10.7324 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H10.7324ZM7.26756 18H4C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16H7.26756C7.09739 16.2942 7 16.6357 7 17C7 17.3643 7.09739 17.7058 7.26756 18Z"
        fill="#98A1AB"
      />
      <circle cx="9" cy="17" r="3" stroke="#98A1AB" strokeWidth="2" />
    </svg>
  ),
  recommendations: (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <circle cx="12" cy="12" r="6" />
      <circle cx="12" cy="12" r="2" />
    </svg>
  ),
  uniterPlus: (
    <svg
      width="14"
      height="9"
      viewBox="0 0 16 9"
      fill="white">
      <path
        d="M5.5 0V5.38636C5.5 6.20028 4.81392 6.5 4 6.5C3.18608 6.5 2.5 6.20028 2.5 5.38636V0H0V5.59091C0 7.58523 1.74148 9 4 9C6.24148 9 8 7.58523 8 5.59091V0H5.5Z"
        fill="white" />
      <rect x="12" y="1" width="2" fill="white" height="6" />
      <rect x="10" y="3" width="6" fill="white" height="2" />
    </svg>
  ),
  development: (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  ),
};
