import { components } from 'react-select';
import React from 'react';

export const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0.5C3.8525 0.5 0.5 3.8525 0.5 8C0.5 12.1475 3.8525 15.5 8 15.5C12.1475 15.5 15.5 12.1475 15.5 8C15.5 3.8525 12.1475 0.5 8 0.5ZM11.75 10.6925L10.6925 11.75L8 9.0575L5.3075 11.75L4.25 10.6925L6.9425 8L4.25 5.3075L5.3075 4.25L8 6.9425L10.6925 4.25L11.75 5.3075L9.0575 8L11.75 10.6925Z"
          fill="white"
        />
      </g>
    </svg>
  </components.MultiValueRemove>
);
