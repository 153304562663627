import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '../../../../app/routing';
import { config, isDevelopment } from '../../../../config';
import { callFeedbackModal } from '../../../../features/Feedback';
import { getProfileState } from '../../../../store/profile/selectors';
import { COLORS, MOBILE_BREAKPOINT } from '../../../constants';
import { invitesPermissions, recommendationsPermissions, unitesPermissions } from '../../../permissions';
import { SIDEBAR_ICONS } from './icons';

const StyledContainer = styled.div`
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 16px;
  }
`;

const StyledLinkLabel = styled('div')`
  margin-left: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.grey1Text};
`;

const StyledLink = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 16px;
  position: relative;
  fill: none;
  background: none;
  border-radius: 6px;

  svg,
  svg * {
    :not([fill]) {
      stroke: ${(p) => (p.disabled ? COLORS.grey2Text : COLORS.grey1Text)} !important;
    }
    &[fill='#98A1AB'] {
      fill: ${(p) => (p.disabled ? COLORS.grey2Text : COLORS.grey1Text)} !important;
    }
  }

  &.active {
    background-color: ${COLORS.white};
    border-radius: 6px;

    ${StyledLinkLabel} {
      color: ${COLORS.black};
    }

    svg,
    svg * {
      :not([fill]) {
        stroke: ${COLORS.black} !important;
      }
      &[fill='#98A1AB'], &[fill='white'] {
        fill: ${COLORS.black} !important;
      }
    }
  }

  transition: background-color 0.15s;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    &.active {
      background-color: ${COLORS.greyBg};
      border-radius: 0;
    }
  }

  ${StyledLinkLabel} {
    color: ${(p) => (p.disabled ? COLORS.grey2Text : COLORS.grey1Text)};
  }
`;

const PlusBagde = styled.div`
  background: ${COLORS.orangeGradient};
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 6px;
`;

const SidebarMenu = () => {
  const { t } = useTranslation();
  const profile = useSelector(getProfileState);
  const isInvitesPermitted = useMemo(() => invitesPermissions(profile.status), [profile.status]);
  const isUnitesPermitted = useMemo(() => unitesPermissions(profile.status), [profile.status]);
  const isRecommendationsPermitted = useMemo(() => recommendationsPermissions(profile.status), [profile.status]);
  const unitesHandleOnClick = useCallback((e) => (!isUnitesPermitted ? e.preventDefault() : null), [
    isUnitesPermitted,
  ]);
  const invitesHandleOnClick = useCallback((e) => (!isInvitesPermitted ? e.preventDefault() : null), [
    isInvitesPermitted,
  ]);
  const recommendationsOnClick = useCallback((e) => (!isRecommendationsPermitted ? e.preventDefault() : null), [
    isRecommendationsPermitted,
  ]);

  return (
    <StyledContainer>
      {config.features.recommendations && (
        <StyledLink
          to={ROUTES.recommendations.path}
          onClick={recommendationsOnClick}
          disabled={!isRecommendationsPermitted}
        >
          {SIDEBAR_ICONS.recommendations}
          <StyledLinkLabel>{t('Рекомендации')}</StyledLinkLabel>
        </StyledLink>
      )}
      {config.features.godMode && (config.features.uniterPlus || profile.has_subscription) && <StyledLink to={ROUTES.usersSearch.path}>
        {SIDEBAR_ICONS.search}
        <StyledLinkLabel>{t('Режим бога')}</StyledLinkLabel>
      </StyledLink>
      }
      <StyledLink to={ROUTES.unites.path} onClick={unitesHandleOnClick} disabled={!isUnitesPermitted}>
        {SIDEBAR_ICONS.unites}
        <StyledLinkLabel>{t('Мои юнайты')}</StyledLinkLabel>
      </StyledLink>

      <StyledLink to={ROUTES.invite.path} onClick={invitesHandleOnClick} disabled={!isInvitesPermitted}>
        {SIDEBAR_ICONS.invites}
        <StyledLinkLabel>{t('Пригласить друга')}</StyledLinkLabel>
      </StyledLink>

      <StyledLink as="button" onClick={callFeedbackModal}>
        {SIDEBAR_ICONS.feedback}
        <StyledLinkLabel>{t('Обратная связь')}</StyledLinkLabel>
      </StyledLink>

      {config.features.uniterPlus && !profile.has_subscription && (
        <StyledLink
          to={ROUTES.uniterPlus.path}
        >
          <PlusBagde>
             {SIDEBAR_ICONS.uniterPlus}
          </PlusBagde>
          <StyledLinkLabel>{t('Улучшить аккаунт')}</StyledLinkLabel>
        </StyledLink>
      )}

      {isDevelopment() && (
        <>
          <hr />
          <StyledLink to={ROUTES.networkingSettings.path}>
            {SIDEBAR_ICONS.development}
            <StyledLinkLabel>Networking settings</StyledLinkLabel>
          </StyledLink>
        </>
      )}
    </StyledContainer>
  );
};

export { SidebarMenu };
