import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useStage = (props) => {
  const history = useHistory();
  const { push = history.push, initialStage, stages } = props;
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const stageQuery = stages[queries.stage];
  const [{ stage, direction }, setStage] = useState({ stage: initialStage, direction: 'down' });

  const pushStage = (stageToPush) => {
    const stagesKeys = Object.keys(stages);
    const stageKey = stagesKeys.find((key) => stages[key] === stageToPush);
    const search = queryString.stringify({ ...queries, stage: stageKey });
    push({ search });
  };

  useEffect(() => {
    if (!stageQuery) {
      pushStage(initialStage);
      return;
    }

    setStage((prev) => ({ direction: stageQuery > prev.stage ? 'down' : 'up', stage: stageQuery }));
  }, [stageQuery]); //eslint-disable-line

  useEffect(() => {
    setTimeout(() => pushStage(initialStage), 0);
  }, []); //eslint-disable-line

  return [{ initialStage, stage, direction }, pushStage];
};
