import { SET_AUTHORIZED, SET_LNGS_LOADED, SET_MEETINGS, TOGGLE_PRELOADER, TOGGLE_SIDEBAR } from './action-types';

const defaultState = {
  isSidebarOpened: false,
  showPreloader: false,
  isAuthorized: 'pending',
  translationLoaded: false,
  meetings: {
    united: null,
    week: [],
  },
};

export const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LNGS_LOADED:
      return {
        ...state,
        translationLoaded: payload
      }
    case TOGGLE_PRELOADER:
      return {
        ...state,
        showPreloader: payload,
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpened: !state.isSidebarOpened,
      };
    case SET_AUTHORIZED:
      return {
        ...state,
        isAuthorized: payload,
      };
    case SET_MEETINGS:
      return {
        ...state,
        meetings: { ...payload, meeting: payload.meeting },
      };
    default:
      return state;
  }
};
