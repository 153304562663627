/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Header } from '../../common/components/Header';
import { Container } from '../../common/components/Container';
import { Margin } from '../../common/components/Margin';
import { InviteByEmail } from './components/invite-by-email';
import { InviteLink } from './components/invite-link';
import { InvitationsList } from './components/invitations-list';
import { useTranslation } from 'react-i18next';
import { InviteShareLink } from './components/invite-share-link';

const Invites = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Uniter — Пригласить друга');
  }, []); //eslint-disable-line

  return (
    <Container>
      <Header children={t('Пригласить друга')} />
      <InviteByEmail />
      <Margin top={56}>
        <InviteLink />
      </Margin>
      <Margin top={56}>
        <InviteShareLink />
      </Margin>
      <Margin top={56}>
        <InvitationsList />
      </Margin>
    </Container>
  );
};

export { Invites };
