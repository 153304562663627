import React from 'react';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const transitionProps = {
  timeout: { enter: 0, exit: 350 },
};

const transitionController = (state, direction) => {
  const enteringDirection = direction === 'down' ? '' : '-';
  const exitingDirection = direction === 'down' ? '-' : '';

  switch (state) {
    case 'entering':
      return css`
        transform: translateY(${enteringDirection}10vh);
        opacity: 0;
        top: 0;
      `;
    case 'entered':
      return css`
        transform: translateY(0);
        opacity: 1;
        top: 0;
        position: relative;
      `;
    case 'exiting':
      return css`
        transform: translateY(${exitingDirection}10vh);
        opacity: 0;
        top: 0;
      `;
    case 'exited':
      return css`
        opacity: 0;
        transform: translateY(${enteringDirection}10vh);
      `;
    default:
      return '';
  }
};

const TransitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: -1000vh;
  width: 100%;
  flex: 1;

  transition: transform ${transitionProps.timeout.exit}ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s,
    opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;

  ${(p) => transitionController(p.state, p.direction)}
`;

const TransitionListItem = ({ show, children, direction, ...props }) => {
  return (
    <Transition in={show} {...transitionProps} mountOnEnter unmountOnExit {...props}>
      {(state) => (
        <TransitionContainer state={state} direction={direction}>
          {children}
        </TransitionContainer>
      )}
    </Transition>
  );
};

TransitionListItem.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  direction: PropTypes.string,
};

export { TransitionListItem };
