import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { OuterContainer } from '../common/components/OuterContainer';
import { PrivateRoute } from '../common/components/PrivateRoute';
import { PublicRoute } from '../common/components/PublicRoute';
import { Sidebar } from '../common/components/Sidebar';
import { APPROVED, GHOST, LEAD, NOT_RELEVANT, PENDING, REGISTERED } from '../common/constants';
import { gaSendPageView, gaSetUser } from '../common/helpers/google-analytics';
import { isRouteMatched } from "../common/helpers/routes";
import { useRoutingController } from '../common/hooks/use-routing-controller';
import { config } from '../config';
import { AuthFromEmail } from '../features/AuthFromEmail';
import { Congratulations } from '../features/Congratulations';
import { Invites } from '../features/Invites';
import { NetworkingSettings } from '../features/NetworkingSettings';
import { PaymentComplete } from '../features/PaymentComplete';
import { Profile } from '../features/Profile';
import { Recommendations } from '../features/Recommendations';
import { TelegramIntegrationPopup } from '../features/TelegramIntegrationPopup';
import { UniterPlus } from '../features/UniterPlus';
import { Unites, UnitesConfirmation } from '../features/Unites';
import { UsersSearch } from "../features/UsersSearch/users-search";
import { RouteJoinRoom } from '../routes/RouteJoinRoom';
import { RouteLogin } from '../routes/RouteLogin';
import { RouteRegistration } from '../routes/RouteRegistration';

//TODO: связать разлинковку сайдбара с роутами ('disabled' etc)

export const ROUTES = {
  networkingSettings: { exact: true, path: '/networking/settings', component: NetworkingSettings },
  congratulations: { exact: true, path: '/congratulations', component: Congratulations },
  paymentComplete: { exact: true, path: '/complete', component: PaymentComplete },
  recommendations: { exact: true, path: '/recommendations', component: Recommendations },
  joinRoom: { exact: true, path: '/registration', component: RouteJoinRoom },
  uniterPlus: { exact: true, path: '/plus', component: UniterPlus },
  profile: { exact: true, path: '/profile', component: Profile },
  invite: { exact: true, path: '/invite', component: Invites },
  unitesConfirmation: { exact: true, path: '/unites/confirmation', component: UnitesConfirmation, useLayout: false},
  unites: { exact: true, path: '/unites', component: Unites },
  usersSearch: { exact: true, path: '/search', component: UsersSearch, isAccessible: config.features.godMode },
};

export const mapRoutesByStatus = (status, hasSubscription) => {
  const approvedRoutes = [ROUTES.invite, ROUTES.profile, ROUTES.unites, ROUTES.unitesConfirmation, ROUTES.networkingSettings, ROUTES.joinRoom];

  if (config.features.recommendations) {
    approvedRoutes.push(ROUTES.recommendations);
  }

  if (config.features.uniterPlus) {
    approvedRoutes.push(ROUTES.uniterPlus);
    approvedRoutes.push(ROUTES.usersSearch);
  } else if (hasSubscription) {
    approvedRoutes.push(ROUTES.usersSearch);
  }

  switch (status) {
    case APPROVED:
      return approvedRoutes;
    case REGISTERED:
      return [ROUTES.profile, ROUTES.paymentComplete, ROUTES.invite, ROUTES.joinRoom];
    case PENDING:
      return [ROUTES.profile, ROUTES.joinRoom];
    case NOT_RELEVANT:
      return [ROUTES.profile];
    case GHOST:
      return [ROUTES.profile, ROUTES.invite];
    case LEAD:
      return [ROUTES.profile];
    default:
      return [];
  }
};

const Routing = () => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const isAuthorized = useSelector((state) => state.common.isAuthorized);
  const congratulated = useSelector((state) => state.profile.congratulated);
  const congratulatedPaid = useSelector((state) => state.profile.congratulatedPaid);
  const status = useSelector((state) => state.profile.status);
  const email = useSelector((state) => state.profile.email);
  const hasSubscription = useSelector((state) => state.profile.has_subscription);

  useRoutingController();

  useEffect(() => {
    gaSendPageView();
  }, [pathname]);

  useEffect(() => {
    if (email) {
      gaSetUser(email);
    }
  }, [email]); //eslint-disable-line

  if (isAuthorized === 'pending') {
    return null;
  }

  if (!isAuthorized) {
    const redirect = () => <Redirect to="/login" />;

    return (
      <OuterContainer>
        <Switch>
          <PublicRoute isAuthorized={isAuthorized} exact path="/login" component={RouteLogin} />
          <PublicRoute isAuthorized={isAuthorized} exact path="/registration" component={RouteRegistration} />
          <PublicRoute isAuthorized={isAuthorized} exact path="/us-verify-link" component={AuthFromEmail} />
          <PublicRoute isAuthorized={isAuthorized} component={redirect} />
        </Switch>
      </OuterContainer>
    );
  }

  if (config.showPriorityPassPayment && congratulatedPaid === false && status === REGISTERED && ROUTES.paymentComplete.path !== pathname) {
    history.push({ pathname: '/complete' });
    return <Redirect to={ROUTES.paymentComplete.path} />;
  }

  if (congratulated === false && status === APPROVED) {
    return <Congratulations />;
  }

  const redirect = () => <Redirect to={ROUTES.profile.path} />;

  const matchedRoute = mapRoutesByStatus(status).find((route) =>
    isRouteMatched({ to: route.path, location, strict: route.strict, exact: route.exact }));

  const isAccessible = typeof matchedRoute?.isAccessible === 'boolean' ?  matchedRoute.isAccessible: true;

  return (
    <>
      {matchedRoute?.useLayout === false ? null : <Sidebar />}
      <Switch>
        {mapRoutesByStatus(status, hasSubscription).map((route) => (
          <PrivateRoute isAuthorized={isAuthorized} isAccessible={isAccessible} key={route.path} {...route} />
        ))}
        <PrivateRoute isAuthorized={isAuthorized} component={redirect} />
      </Switch>
      <TelegramIntegrationPopup />
    </>
  );
};

export { Routing };
