import { LS_AUTH_TOKEN_KEY } from '../constants';
import { writeLocalStorage } from './ls';

export const writeToken = (token) => {
  writeLocalStorage(LS_AUTH_TOKEN_KEY, token);
};

export const readToken = () => {
  return localStorage.getItem(LS_AUTH_TOKEN_KEY);
};
