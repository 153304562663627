import { useCountdown } from '../../../common/hooks/use-countdown';
import { getUnixTime, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { applyTimeZoneOffset } from '../../../common/helpers/time-zone';

const UniterPlusSubscriptionCountdown = ({ sale, expiration, onCountdown }) => {
  const [{ time, countdown }, setCountdown] = useCountdown();

  useEffect(() => {
    if (countdown === null) {
      onCountdown();
    }
  }, [countdown, onCountdown]);

  useEffect(() => {
    if (sale && expiration) {
      const time = getUnixTime(applyTimeZoneOffset(parseISO(expiration)));
      const nowTime = getUnixTime(Date.now());
      const diff = time - nowTime;
      setCountdown(diff);
    }
  }, [expiration, sale, setCountdown]);

  useEffect(() => {
    return () => setCountdown(null);
  }, [setCountdown]);

  return time;
};

export { UniterPlusSubscriptionCountdown };
