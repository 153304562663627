import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switcher } from '../../common/components/Switcher';
import { Heading } from '../../common/components/Heading';
import { Schedule } from '../../common/components/Schedule';
import { Checkbox } from '../../common/components/Checkbox';
import { Margin } from '../../common/components/Margin';
import { Button } from '../../common/components/Button';
import { COLORS, MOBILE_BREAKPOINT } from '../../common/constants';
import { getProfileState } from '../../store/profile/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentMeetingSettings,
  getSettingsEnums,
  postMeetingUnite,
  requestMeeting,
} from '../../common/api/meeting-settings-api';
import { YouAreIn } from '../YouAreIn';
import { OuterContainer } from '../../common/components/OuterContainer';
import { WideHeaderContainer } from '../../common/components/WideHeaderContainer';
import { actualizeMeetings } from '../../common/helpers/actualize-meetings';
import { RelativeContainer } from '../../common/components/RelativeContainer';
import { Spinner } from '../../common/components/Spinner';
import { formatWeekHintText, getRandomPriorityTag } from './utils';
import { getPriorityTags } from './constants';
import { InfoHint } from '../../common/components/InfoHint';
import { Trans, useTranslation } from 'react-i18next';
import { MultiSelect } from '../../common/components/MultiSelect';
import { Tooltip } from '../../common/components/Tooltip';
import { HelpIcon } from '../../common/components/FormGroup/form-group';
import { settings } from '../../settings';
import { Br } from '../../common/components/Br';
import { NumberInput } from '../../common/components/NumberInput';
import { getHasSubscription } from '../../store/selectors';

const defaultPriorityTags = [];

const NetworkingSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { frequented_places, rooms: roomsFromStore = [] } = useSelector(getProfileState);
  const hasSubscription = useSelector(getHasSubscription);
  const rooms = roomsFromStore.map((room) => ({
    default: room.default,
    value: room.id,
    label: room.name,
    pic: room.pic,
  }));

  const [randomPriorityTag] = useState(getRandomPriorityTag(getPriorityTags()));

  const [meetingWeekStartDate, meetingWeekEndDate] = useSelector((state) => state.common.meetings.week);
  const [goalsEnums, setGoalsEnums] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const [goals, setGoals] = useState(goalsEnums);
  const [preferredTimeSlots, setPreferredTimeSlots] = useState([]);
  const [meetingsNumber, setMeetingsNumber] = useState(1);
  const [priorityTags, setPriorityTags] = useState(defaultPriorityTags);
  const [message, setMessage] = useState('');
  const [useHub, setUseHub] = useState(false);
  const [sealed] = useState(false);
  const [room, setRoom] = useState(rooms[0]);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fetchMeetingSettings = useCallback(async () => {
    //set enums
    const { enums } = await getSettingsEnums();
    setGoalsEnums(enums.goals);

    //set settings
    const { settings } = await getCurrentMeetingSettings();
    setGoals(settings.goals || []);
    setPreferredTimeSlots(settings.preferred_time_slots);
    setUseHub(settings.use_hub);
    setMeetingsNumber(settings.meetings_number);
    setPriorityTags(settings.priority_tags.filter((pt) => pt.trim() !== '').map((pt) => ({ label: pt, value: pt })));

    setLoading(false);
  }, []);

  const handleOnSubmit = useCallback(async () => {
    try {
      setSubmitLoading(true);
      const requestedMeetingData = await requestMeeting({
        preferred_time_slots: preferredTimeSlots,
        priority_tags: priorityTags.map(({ label }) => label) || [],
        use_hub: useHub,
        rooms: [room.value], //todo скоро здесь должен быть список из нескольких значений
        meetings_number: meetingsNumber,
        goals,
      });

      await postMeetingUnite();

      if (requestedMeetingData.code === 409) {
        setMessage(t('Изменить настройки можно только один раз за неделю'));
      }

      if (requestedMeetingData.code === 201) {
        await actualizeMeetings(dispatch);
        setSubmitLoading(false);
        setSubmitted(true);
      }
    } catch (e) {
      console.error(e);
    }
    setSubmitLoading(false);
  }, [dispatch, goals, meetingsNumber, preferredTimeSlots, priorityTags, room.value, t, useHub]);

  const goalsMapper = useCallback(
    (ge) => {
      const idx = goals.findIndex((g) => g === ge);
      const removeGoal = (goal) => [...goal.slice(0, idx), ...goal.slice(idx + 1)];
      const handleOnChange = (value) => setGoals((g) => (value ? [...g, ge] : removeGoal(g)));
      const canAddGoal = goals.length < 3;
      const checked = goals.includes(ge);

      return (
        <Margin top={32} key={ge}>
          <Checkbox
            checked={goals.includes(ge)}
            onChange={handleOnChange}
            label={ge}
            disabled={!checked && !canAddGoal}
          />
        </Margin>
      );
    },
    [goals],
  );

  useEffect(() => {
    document.title = t('Uniter — Подтвердите участие');
    fetchMeetingSettings();
  }, []); //eslint-disable-line

  const tooltipText = useMemo(
    () =>
      //prettier-ignore
      hasSubscription ? (
        <Trans t={t}>
          Укажите теги, если хотите увеличить вероятность юнайта с необходимым человеком:<Br />
          • «инвестор», если ищете инвестиции в свой проект<Br />
          • «ios», если ищете ios разработчика<Br />
          • «маркетинг», если хотите пообщаться со специалистом по маркетингу,<Br />
          и т.д.
        </Trans>
      ) : (
        <Trans t={t}>
          Укажите тег, если хотите увеличить вероятность юнайта с необходимым человеком:<Br />
          • «инвестор», если ищете инвестиции в свой проект<Br />
          • «ios», если ищете ios разработчика<Br />
          • «маркетинг», если хотите пообщаться со специалистом по маркетингу,<Br />
          и т.д.<Br /><Br />
          Пользователям без подписки U+ доступен только один тег.
        </Trans>
      ),
    [hasSubscription, t],
  );

  const hintText = useMemo(() => formatWeekHintText([meetingWeekStartDate, meetingWeekEndDate]), [
    meetingWeekEndDate,
    meetingWeekStartDate,
  ]);
  const showRoomSelector = rooms.length === 1 ? !rooms[0].default : rooms.length > 1;
  const canCreatePriorityTag = hasSubscription || !priorityTags.length;

  if (submitted) {
    return <YouAreIn />;
  }

  return (
    <OuterContainer>
      <WideHeaderContainer marginTop={80}>
        <Heading>{t('Познакомить вас на этой неделе?')}</Heading>
      </WideHeaderContainer>

      <RelativeContainer>
        <Spinner overlay show={submitLoading || loading} />
        <Switcher
          label={t(`Желательно {{place}}`, { place: frequented_places ? frequented_places[0] : '' })}
          checked={useHub}
          onChange={setUseHub}
        />

        <Margin top={40}>
          <Heading level={2}>{t('Выберите удобное время')}</Heading>
        </Margin>
        <Margin top={8} bottom={8}>
          <InfoHint text={hintText} showIcon={false} />
        </Margin>
        <Margin top={16}>
          <Schedule schedule={preferredTimeSlots} setSchedule={setPreferredTimeSlots} />
        </Margin>
        <Margin top={32}>
          <NumberInput
            label={t('Количество встреч на этой неделе')}
            onChange={setMeetingsNumber}
            value={meetingsNumber}
            max={settings.maxMeetingsNumber(hasSubscription)}
            min={1}
            inputDisabled
          />
        </Margin>

        <Margin top={40}>
          <Heading
            level={2}
            rowComponents={
              <Tooltip text={t('Можно указать до трех целей')}>{(ref) => <HelpIcon forwardRef={ref} />}</Tooltip>
            }
          >
            {t('Ваши цели')}
          </Heading>
        </Margin>
        {goalsEnums.map(goalsMapper)}

        {showRoomSelector && (
          <Margin top={40}>
            <MultiSelect
              label={t('Комната')}
              onChange={setRoom}
              value={room}
              options={rooms}
              isMulti={false}
              hideSelectedOptions={false}
              optionsHasPic
            />
          </Margin>
        )}

        <Margin top={24}>
          <MultiSelect
            value={priorityTags}
            onChange={setPriorityTags}
            label={hasSubscription ? t('Ключевые слова') : t('Ключевое слово')}
            noOptionsMessage={
              canCreatePriorityTag ? t('Введите ключевое слово') : t('Сейчас можно ввести только один тег')
            }
            placeholder={randomPriorityTag}
            tooltipText={tooltipText}
            isCreatable={canCreatePriorityTag}
            isMulti
          />
        </Margin>

        {message && (
          <Margin top={24}>
            <p style={{ color: COLORS.red }}>{message}</p>
          </Margin>
        )}

        <Margin top={100} bottom={window.innerWidth <= MOBILE_BREAKPOINT ? 100 : undefined}>
          <Button onClick={handleOnSubmit} disabled={sealed}>
            {t('Подтвердить')}
          </Button>
        </Margin>
      </RelativeContainer>
    </OuterContainer>
  );
};

export { NetworkingSettings };
