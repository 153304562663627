export const REGISTRATION_STAGES = {
  room: 1,
  auth: 2,
  login: 3,
  email: 4,
  code: 5,
  1: 11,
  2: 12,
  3: 13,
  4: 14,
  complete: 15,
};
