import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import { FORMGROUP_ICONS } from './form-group-icons';
import Skeleton from 'react-loading-skeleton';
import TextareaAutosize from 'react-textarea-autosize';
import { validLink } from '../../validators/validators';
import { Tooltip } from '../Tooltip';

export const HelpIcon = styled((props) => (
  <svg ref={props.forwardRef} className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52153 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 17H12.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
))`
  stroke: ${COLORS.blue};

  :hover {
    stroke: hsl(219,77%,42%)};
  }
`;

//TODO Поправить штуку со скроллом
const StyledInput = styled.input`
  overflow: ${(p) => (p.theme.scroll ? 'auto' : 'hidden')};
  max-height: ${(p) => (p.theme.scroll ? '300px' : 'unset')};
  resize: none;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: ${(p) => (p.theme.hasTooltip ? '64px' : '16px')};
  padding-top: ${(p) => (p.theme.showLabel ? '26px' : '18px')};
  padding-bottom: ${(p) => (p.theme.showLabel ? '12px' : '18px')};
  position: relative;
  transition: 0.15s;
  width: 100%;
  border: none;
  background-color: ${(p) => (p.theme.validation ? COLORS.redBg : p.theme.backgroundColor)};
  border-bottom: 2px solid ${(p) => (p.theme.validation ? COLORS.red : COLORS.greyBg)};
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => (p.theme.validation ? COLORS.red : COLORS.black)};
  appearance: none;

  &:focus {
    border-radius: 4px 4px 0 0;
    border-color: ${(p) => (p.theme.validation ? COLORS.red : COLORS.blue)};
  }

  &::placeholder {
    color: ${(p) => (p.theme.validation ? COLORS.redBg2 : COLORS.grey2Text)};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset;
  }

  &:disabled {
    -webkit-text-fill-color: ${COLORS.grey1Text};
    opacity: 1;
    color: ${COLORS.grey1Text};
    cursor: not-allowed;
  }

  ${(p) => p.theme.icon && `padding-left: 56px`};
`;

const StyledLabel = styled.label`
  display: inline-block;
  z-index: 2;
  left: 16px;
  top: 8px;
  width: max-content;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => (p.validation ? COLORS.red : COLORS.grey1Text)};
  position: absolute;
  ${(p) => p.icon && `left: 56px`}
`;

const StyledContainer = styled.div`
  display: ${(p) => (p.hidden ? 'none' : 'block')};
  position: relative;
  width: 100%;

  ${HelpIcon} {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

const StyledIcon = styled.div`
  position: absolute;
  left: 19px;
  top: 19px;
  width: 20px;
  height: 20px;
  background: url(${(p) => FORMGROUP_ICONS[p.name]}) no-repeat;
  background-size: contain;
  z-index: 2;
`;

const FormGroup = ({
  value,
  onChange,
  placeholder,
  as,
  label,
  backgroundColor,
  validation,
  name,
  forwardRef,
  loading,
  icon,
  iconLink,
  hidden,
  scroll,
  tooltipText,
  ...props
}) => {
  const handleOnChange = (e) => onChange && onChange(name ? { name, value: e.target.value } : e.target.value);
  const validIconLink = validLink.test(iconLink);

  return (
    <StyledContainer className="form-group" hidden={hidden}>
      {loading ? (
        <Skeleton height={60} />
      ) : (
        <>
          {icon && (
            <StyledIcon
              as={validIconLink ? 'a' : 'div'}
              name={icon}
              href={(validIconLink && iconLink) || undefined}
              target={validIconLink ? '_blank' : undefined}
            />
          )}
          <StyledLabel icon={!!icon} validation={validation}>
            {label}
          </StyledLabel>
          <StyledInput
            minRows={3}
            as={as === 'textarea' ? TextareaAutosize : as}
            placeholder={placeholder}
            value={value || ''}
            onChange={handleOnChange}
            ref={forwardRef}
            theme={{ showLabel: !!label, backgroundColor, icon: !!icon, validation, scroll, hasTooltip: tooltipText }}
            {...props}
          />
          {tooltipText && <Tooltip text={tooltipText}>{(ref) => <HelpIcon forwardRef={ref} />}</Tooltip>}
        </>
      )}
    </StyledContainer>
  );
};

FormGroup.defaultProps = {
  as: 'input',
  value: '',
  backgroundColor: COLORS.greyBg,
  scroll: false,
};

FormGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  as: PropTypes.oneOf(['input', 'textarea']),
  backgroundColor: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  validation: PropTypes.bool,
  forwardRef: PropTypes.func,
  loading: PropTypes.bool,
  icon: PropTypes.string,
  iconLink: PropTypes.string,
  hidden: PropTypes.bool,
  scroll: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

FormGroup.Container = StyledContainer;

export { FormGroup };
