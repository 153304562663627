import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { region, REGION_CI, REGION_RU } from '../../../config';
import innovators from './assets/innovators.svg';
import uniter from './assets/uniter.svg';

const resolveLogo = () => {
  switch (region) {
    case REGION_CI:
      return { src: innovators, width: 128, height: 44 };
    case REGION_RU:
    default:
      return { src: uniter, width: 87, height: 31 };
  }
};

/**
 * Logo component with user navigation ability
 * @param {String} to address url
 * @param {String} toOutside address url using native 'a' tag instead of <Link />
 */
const Logo = styled(({ className, to, toOutside }) => {
  return toOutside ? (
    <a href={toOutside} className={className} /> //eslint-disable-line
  ) : to ? (
    <Link to={to} className={className} />
  ) : (
    <div className={className} />
  );
})`
  display: block;
  width: ${resolveLogo().width}px;
  height: ${resolveLogo().height}px;
  min-height: 31px;
  background: url(${resolveLogo().src}) no-repeat;
  background-size: contain;
  background-position-y: center;
`;

Logo.propTypes = {
  to: PropTypes.string,
  toOutside: PropTypes.string,
};

export { Logo };
