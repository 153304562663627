import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants';

const blinking = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

const Indicator = styled(({ className }) => {
  return <span className={className} />;
})`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  flex-shrink: 0;
  display: inline-flex;
  background: ${(p) => COLORS[p.color]};
  animation: ${(p) => p.blink && blinking} 1.5s infinite;
  margin-right: 8px;
  margin-top: -2px;
  vertical-align: middle;
`;

Indicator.propTypes = {
  color: PropTypes.oneOf(['red', 'green', 'orange']),
  blink: PropTypes.bool,
};

export { Indicator };
