import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { FormGroup } from "../../../common/components/FormGroup";
import { Button } from "../../../common/components/Button";
import { Spinner } from "../../../common/components/Spinner";
import PropTypes from "prop-types";
import ArrowRight from "../../../common/components/Icons/arrow-right";
import Box from "../../../common/components/Box";
import { Card } from "../../../common/components/Card";
import { Tags } from '../../../common/components/Tags';
import { i18next } from '../../../i18n/i18n';
import { useSelector } from "react-redux";
import { getProfileState } from "../../../store/profile/selectors";

const TAGS = [
  i18next.t('разработка'),
  i18next.t('дизайн'),
  i18next.t('финансы'),
  'AI',
  'ML',
  'founder',
  'CEO',
  i18next.t('маркетинг'),
  'fintech',
  'startup'
];

const UserSearch = ({ searchResource, onSearch }) => {
  const { t } = useTranslation();
  const profile = useSelector(getProfileState);
  const [searchText, setSearchText] = useState('');

  const onTagSelectCallback = useCallback(tag => {
    setSearchText(tag);
    onSearch(tag);

  }, [onSearch, setSearchText]);

  return (
    <Card>
       <p>
        {t('Найдите нужного пользователя и укажите тему для встречи.')}
        {' '}{profile.has_subscription && t('У вас осталось приглашение на этой неделе',
          {
            count: profile.dating_count
          })}
      </p>
      <Box as="form" display="flex" flexDirection="row">
        <Box flex="1">
          <FormGroup
            value={searchText}
            onChange={setSearchText}
            placeholder={t('Поиск')}
          />
        </Box>
        <Button
          ml={16}
          type="submit"
          variant="square"
          disabled={searchText.trim().length === 0}
          onClick={(e) => {
            e.preventDefault();
            onSearch(searchText);
          }}
        >
          <ArrowRight/>
        </Button>

        <Spinner show={searchResource.isLoading()} overlay/>
      </Box>
      <Tags items={TAGS} onSelect={onTagSelectCallback}/>
    </Card>
  );
};

UserSearch.propTypes = {
  searchResource: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export { UserSearch };
