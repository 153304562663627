import { CustomStore } from './custom-store';
import { v4 as uuid } from 'uuid';

class ModalController extends CustomStore {
  /**
   * Shows a modal
   * @param {Object} params
   * @param {JSX.Element|String|function} params.body Modal body
   * @param {JSX.Element|String} params.title Modal header
   * @param {Function=} params.onExited Callback on modal closes
   * @param {Boolean=} params.showCloseButton=true Show's close button (True by default)
   */
  showModal({ body, title, onExited, showCloseButton }) {
    const id = uuid();

    const _onExited = () => {
      onExited && onExited();
      return (this.data = this.data.filter((d) => d.id !== id));
    };

    const onLeave = () => {
      const idx = this.data.findIndex((d) => d.id === id);
      this.data = [...this.data.slice(0, idx), { ...this.data[idx], show: false }, ...this.data.slice(idx + 1)];
    };

    this.data = [
      ...this.data,
      {
        showCloseButton,
        show: true,
        onLeave,
        onExited: _onExited,
        title,
        body: typeof body === 'function' ? body(onLeave) : body,
        id,
      },
    ];
  }
}

const modalController = new ModalController([]);

export { modalController };
