import styled from 'styled-components';
import React from 'react';
import { COLORS } from '../../constants';
import PropTypes from 'prop-types';

const Hint = styled(({ className, children }) => <div className={className}>{children}</div>)`
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: ${(p) => (p.color === 'red' ? COLORS.red : COLORS.black)};
`;

Hint.defaultProps = {
  color: 'black',
};

Hint.propTypes = {
  color: PropTypes.oneOf(['red', 'black']),
};

export { Hint };
