import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { patchProfileFields } from '../../common/api/profile-api';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Margin } from '../../common/components/Margin';
import { OuterContainer } from '../../common/components/OuterContainer';
import { actualizeProfile } from '../../common/helpers/actualize-profile';
import balloon from './assets/balloon.svg';

const StyledImage = styled.img`
  width: auto;
  height: 160px;
`;


const PaymentComplete = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.title = t('Uniter — Поздравляем!');
  }, []); //eslint-disable-line

  const handleOnReceiveCongratulation = async () => {
    try {
      const data = await patchProfileFields({ congratulatedPaid: true });
      if (data.code === 204) {
        await actualizeProfile(dispatch);
        history.push('/profile');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <OuterContainer>
        <Margin top={64}>
          <StyledImage src={balloon} height={125} />
        </Margin>
        <Margin top={24}>
          <Heading>{t('Поздравляем!')}</Heading>
        </Margin>
        <Margin top={8}>
          <p>
            <Trans t={t}>
              Модератор уже проверяет ваш профиль, и совсем скоро вы окажетесь в кругу лучших профессионалов из разных точек мира.
            </Trans>
          </p>
        </Margin>
        <div className="place-bottom place-bottom--how-to">
          <Button onClick={handleOnReceiveCongratulation}>{t('Перейти в профиль')}</Button>
        </div>
    </OuterContainer>
  );
};

export { PaymentComplete };
