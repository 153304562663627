/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import { Tooltip } from '../../Tooltip';
import { HelpIcon } from '../../FormGroup/form-group';

// eslint-disable-next-line react/display-name
const MultiSelectControl = (tooltipText) => (props) => (
  <components.Control {...props}>
    {props.children}
    {tooltipText ? <Tooltip text={tooltipText}>{(ref) => <HelpIcon forwardRef={ref} />}</Tooltip> : null}
  </components.Control>
);

export { MultiSelectControl };
