import { memo, useEffect } from 'react';
import { REGION_CI, REGION_RU, region } from '../../../config';
import inno from './assets/inno.ico';
import uniter from './assets/uniter.ico';

const resolveLogo = () => {
  switch (region) {
    case REGION_CI:
      return inno;
    case REGION_RU:
    default:
      return uniter;
  }
};

const Favico = memo(function Favico() {
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']");
    link.href = resolveLogo();
  }, []);

  return null;
});

export { Favico };
