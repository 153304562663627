import { COLORS, MOBILE_BREAKPOINT } from "./constants";

const colors = {
  black: COLORS.black,
  blue: COLORS.blue,
  red: COLORS.red,
  redBg: COLORS.redBg,
  redBg2: COLORS.redBg2,
  grey1: COLORS.grey1,
  grey2: COLORS.grey2,
  grey1Text:COLORS.grey1Text,
  grey2Text:COLORS.grey2Text,
  // greyBg: '#F6F7F8',
  greyBg: COLORS.greyBg,
  white: COLORS.white,
  green: COLORS.green,
  gold: COLORS.gold,
  darkBlue: COLORS.darkBlue,
  orangeGradient: COLORS.orangeGradient,
};

const breakpoints = [`${MOBILE_BREAKPOINT}px`, '772px'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];

export default {
  colors,
  breakpoints
}
