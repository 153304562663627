import { getCurrentProfile } from '../api/profile-api';
import { getInviteLink } from '../api/invites-api';
import { store } from '../../store/store';
import { setCurrentProfile } from "../../store/profile/actions";

export const actualizeProfile = async (dispatch = store.dispatch) => {
  try {
    const { profile, code } = await getCurrentProfile();

    let inviteLink = document.location.origin;

    try {
      const { url, code } = await getInviteLink();
      if (code === 200) inviteLink = url;
    } catch (e) {
      console.error(e);
    }

    dispatch(setCurrentProfile({ ...profile, inviteLink }));

    return code === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
};
