import { AVATAR_COLORS } from '../../constants';

const stringToHash = (str) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  if (hash < 0) {
    hash *= -1;
  }

  return hash % 6;
};

export const getColorByHash = (str) => {
  let hash = stringToHash(str);

  const keys = Object.keys(AVATAR_COLORS);

  return AVATAR_COLORS[keys[hash]];
};
