import { useEffect, useState } from 'react';

let cachedScripts = [];

const useScript = (url) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const onScriptLoad = () => {
    setLoaded(true);
    cachedScripts.push(url);
  };

  const onScriptError = () => {
    setError(true);
  };

  useEffect(() => {
    if (cachedScripts.includes(url)) {
      setLoaded(true);
    } else {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.addEventListener('load', onScriptLoad);
      };
    }
  }, [url]); // eslint-disable-line

  return [loaded, error];
};

export { useScript };
