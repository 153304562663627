import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { App } from './app/app.jsx';
import { initFbq } from './common/helpers/facebook-pixel';
import { initGa } from './common/helpers/google-analytics';
import { initYM } from './common/helpers/ya-metrica.js';
import { i18next } from './i18n/i18n';
import { store } from './store/store';

initGa();
initFbq();
initYM();

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
);
