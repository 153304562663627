import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getPaymentInvoice, getPaymentProducts, postPaymentInvoice } from '../../../common/api/payment-api';
import { makeRegistrationComplete } from '../../../common/api/registration-api';
import { Button } from '../../../common/components/Button';
import { Margin } from '../../../common/components/Margin';
import { Spinner } from '../../../common/components/Spinner';
import { CLOUDPAYMENTS_BUNDLE_LINK, COLORS } from '../../../common/constants';
import { modalController } from '../../../common/controllers/modal-controller';
import { CURRENCIES } from '../../../common/currencies-map';
import { authorize } from '../../../common/helpers/authorize';
import { fbqSendEvent } from '../../../common/helpers/facebook-pixel';
import { useScript } from '../../../common/hooks/use-script';
import { config } from '../../../config';
import { settings } from '../../../settings';
import { TOGGLE_PRELOADER } from '../../../store/action-types';
import { RegistrationPriorityPassModal } from './registration-priority-pass-modal';

export const renderButtonText = (price, oldPrice, sale, currency) => (
  <>
    Купить доступ за{' '}
    <StyledButtonBoldPrice>
      {price.replace(/([0-9]*)(.|,)([0-9]*)/, (match, p1) => p1)} {CURRENCIES[currency]}
    </StyledButtonBoldPrice>
    {sale && (
      <StyledOldPrice>
        {oldPrice.replace(/([0-9]*)(.|,)([0-9]*)/, (match, p1) => p1)} {CURRENCIES[currency]}
      </StyledOldPrice>
    )}
  </>
);

const StyledOldPrice = styled.span`
  color: ${COLORS.grey1};
  font-size: 0.9em;
  position: relative;
  margin-left: 8px;

  &:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 118%;
    transform: rotate(-6deg);
    left: -3px;
    top: 44%;
    background-color: ${COLORS.red};
  }
`;

export const StyledButtonTextWrapper = styled.span`
  color: inherit;
  width: 100%;
`;

const StyledButtonBoldPrice = styled.strong`
  font-weight: 700;
  color: inherit;
`;

const RegistrationPriorityPass = ({ allowGetProduct, firstName, lastName, email, setShowPaymentOverlay, invite }) => {
  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [product, setProduct] = useState();

  // Payment
  const [cloudPaymentsScriptLoaded] = useScript(CLOUDPAYMENTS_BUNDLE_LINK);

  const fetchPriorityPassProduct = async () => {
    try {
      const data = await getPaymentProducts({ email });
      const priority_pass = data?.products?.find((p) => p.name === settings.products.priorityPass);
      setProduct(priority_pass || null);

      return priority_pass;
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnSubmit = async () => {
    let _product = product;

    if (!_product?.sale) {
      await makeRegistrationComplete({
        email,
        invite: invite,
        ...(queries.room ? { room: queries.room } : {}),
      });

      _product = await fetchPriorityPassProduct();

      if (_product.sale) {
        modalController.showModal({
          title: 'Ура!',
          body: (onClose) => (
            <RegistrationPriorityPassModal onClose={onClose} onPayment={handleOnPayment(_product)} product={_product} />
          ),
          onExited: handleOnPayment(_product),
        });
      } else {
        await handleOnPayment(_product)();
        console.log('----1-----');
      }
    } else {
      console.log('------2------');
      await handleOnPayment(_product)();
    }
  };

  const handleOnPayment = (product) => async () => {
    const { invoice } = await postPaymentInvoice({ product: product.id, email });

    fbqSendEvent('InitiateCheckout');

    const tryFetchPaidStatus = async () => {
      try {
        const data = await getPaymentInvoice(invoice, { email });

        if (data.invoice.status === 'paid') {
          if (queries.room) {
            history.push({
              search: queryString.stringify({
                room: queries.room,
                loggedByInvite: 1,
              }),
            });
          }
          authorize(data?.invoice?.extra?.token);
        } else {
          setTimeout(tryFetchPaidStatus, 5000);
        }
      } catch (e) {
        setTimeout(tryFetchPaidStatus, 5000);
      }
    };

    const tryCharge = () => {
      setShowPaymentOverlay(true);
      const cp = new window.cp.CloudPayments();

      const receipt = {
        Items: [
          {
            label: 'Uniter Priority Pass — мгновенный доступ',
            price: Number(product.price),
            quantity: 1.00,
            amount: Number(product.price),
            method: 1,  // fullprepayment
            object: 4, // service
          }
        ],
        "email": email, //e-mail покупателя, если нужно отправить письмо с чеком
        "customerInfo": "", // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
        "isBso": false, //чек является бланком строгой отчётности
        "AgentSign": null, //признак агента, тег ОФД 1057
        "amounts":
          {
            "electronic": Number(product.price), // Сумма оплаты электронными деньгами
          }
      };

      cp.charge(
        {
          publicId: config.cloudpaymentsPublicId,
          description: t('Uniter Priority Pass — мгновенный доступ'),
          invoiceId: invoice,
          amount: Number(product.price),
          currency: product.currency,
          accountId: email,
          skin: 'modern',
          data: {
            ...(firstName ? { firstName } : {}),
            ...(lastName ? { lastName } : {}),
            cloudPayments: {
              CustomerReceipt: receipt,
            }
          },
        },
        handleOnSuccess,
        handleOnFailure,
        handleOnComplete,
      );
    };

    const handleOnComplete = async (paymentResult, options) => {
      fbqSendEvent('Purchase', { value: options.amount, currency: options.currency });

      try {
        window.ym(83383999, 'reachGoal', 'payment'); // yandex.metrika
      } catch (e) {
        //...
      }

      try {
        window.dataLayer.push({
          'transactionId': invoice,
          'transactionTotal': options.amount,
          'transactionProducts': [{
            'sku': 'PriorityPass',
            'name': 'Priority Pass - instant access',
            'price': options.amount,
            'quantity': 1
          }]
        })
      } catch (e) {
        // ...
      }
    };

    const handleOnSuccess = async () => {
      setShowPaymentOverlay(false);
      dispatch({ type: TOGGLE_PRELOADER, payload: true });
      tryFetchPaidStatus();
    };

    const handleOnFailure = () => {
      dispatch({ type: TOGGLE_PRELOADER, payload: false });
      setShowPaymentOverlay(false);
    };

    if (cloudPaymentsScriptLoaded) {
      tryCharge();
    } else {
      setTimeout(tryCharge, 1000);
    }
  };

  useEffect(() => {
    if (allowGetProduct) {
      fetchPriorityPassProduct();
    }
  }, [allowGetProduct, email]); //eslint-disable-line

  return (
    <>
      <Spinner show={product === undefined} overlay />
      {product && (
        <Margin bottom={32}>
          <Button onClick={handleOnSubmit}>
            <StyledButtonTextWrapper>
              {renderButtonText(product.price, product.original, product.sale, product.currency)}
            </StyledButtonTextWrapper>
          </Button>
        </Margin>
      )}
    </>
  );
};

RegistrationPriorityPass.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  setShowPaymentOverlay: PropTypes.func,
  allowGetProduct: PropTypes.bool,
  invite: PropTypes.string,
};

export { RegistrationPriorityPass };
