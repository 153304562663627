import React, { useCallback, useState } from 'react';
import { A } from '../../../common/components/A';
import styled from 'styled-components';
import { COLORS } from '../../../common/constants';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../store/profile/selectors';
import { copyToClipboard } from '../../../common/helpers/copy-to-clipboard';
import { Tooltip } from '../../../common/components/Tooltip';
import { useTranslation } from 'react-i18next';

const LinkIcon = (
  <svg
    viewBox="0 0 24 24"
    width="16"
    height="16"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
  </svg>
);

const ExtendedA = styled(A)`
  display: inline-flex;
  text-wrap: normal;
  align-items: flex-start;
  margin-top: 8px;
  color: ${COLORS.blue};
  vertical-align: middle;

  & svg {
    margin-top: 3px;
    flex-shrink: 0;
    margin-right: 4px;
    stroke: ${COLORS.blue};
  }
`;

const RecommendationsCopyLink = () => {
  const { t } = useTranslation();
  const { inviteLink } = useSelector(getProfileState);
  const [show, setShow] = useState(false);

  const handleOnCopy = useCallback(() => {
    copyToClipboard(inviteLink);
    setShow(true);
    setTimeout(() => setShow(false), 3000);
  }, [inviteLink]);

  return (
    <Tooltip text="Скопировано" show={show} showOnHover={false}>
      {(ref) => (
        <ExtendedA onClick={handleOnCopy} ref={ref}>
          {LinkIcon}
          {t('Скопировать реферальную ссылку')}
        </ExtendedA>
      )}
    </Tooltip>
  );
};

export { RecommendationsCopyLink };
