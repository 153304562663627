import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from '../../../app/routing';
import PropTypes from 'prop-types';

const PublicRoute = ({ isAuthorized, component: Component, ...rest }) => {
  if (isAuthorized === 'pending') {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => (isAuthorized ? <Redirect to={ROUTES.profile.path} /> : <Component {...props} />)}
    />
  );
};

PublicRoute.propTypes = {
  isAuthorized: PropTypes.bool,
  component: PropTypes.elementType,
};

export { PublicRoute };
