import axios from 'axios';
import { config } from '../../config';

export const apiInstance = axios.create({
  baseURL: config.apiBaseURL + 'v1',
});

export const setAuthHeaders = (token) => {
  apiInstance.defaults.headers['Auth'] = token;
};

export const removeAuthHeaders = () => {
  delete apiInstance.defaults.headers.Auth;
};
