import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading } from '../../common/components/Heading';
import { FormGroup } from '../../common/components/FormGroup';
import { Button } from '../../common/components/Button';
import { authenticationCompletion, authenticationRequest } from '../../common/api/auth-api';
import { LoginCountdown } from './components/login-countdown';
import { codeTypingValidator, codeValidator, emailValidator } from '../../common/validators/validators';
import { Margin } from '../../common/components/Margin';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Hint } from '../../common/components/Hint';
import { authorize } from '../../common/helpers/authorize';
import { useTranslation } from 'react-i18next';
import { TransitionListItem } from '../../common/components/TransitionListItem';
import { Spinner } from '../../common/components/Spinner';
import PropTypes from 'prop-types';
import { WideHeaderContainer } from '../../common/components/WideHeaderContainer';
import { useCountdown } from '../../common/hooks/use-countdown';
import { A } from '../../common/components/A';
import { EMPTY_LEAD, LEAD } from '../../common/constants';
import { REGISTRATION_STAGES } from '../../routes/RouteRegistration';

const StyledRegistrationLink = styled.div`
  margin: 64px auto 0;
`;

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const COUNTDOWN = 90;

const Login = ({ transition, registrationCallback }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);

  useEffect(() => {
    document.title = t('Uniter — Войти');
  }, []); //eslint-disable-line

  const [code, setCode] = useState('');
  const [email, setEmail] = useState(queries?.email || '');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [emailRef, setEmailRef] = useState();
  const [codeRef, setCodeRef] = useState();
  const [{ time, countdown }, setCountdown] = useCountdown();

  const emailValid = emailValidator.test(email);
  const codeValid = codeValidator.test(code);

  const handleCodeChange = (value) => {
    const trimmedValue = value.trim();
    if (codeTypingValidator.test(trimmedValue)) {
      setCode(trimmedValue);
    }
  };

  const handleEmailSubmit = async (e) => {
    if (e) e.preventDefault();
    if (countdown) {
      transition.pushStage(transition.stages.code);
      return;
    }
    if (emailValid) {
      setValidation(false);
      setLoading(true);

      try {
        const room = queries.room ? { room: queries.room } : {};
        const data = { email, ...room };
        const { code, message, status } = await authenticationRequest(data);

        setCountdown(COUNTDOWN);
        setLoading(false);

        if (message !== 'code-sent') {
          if (code === 404 && message === 'user-waiting') {
            switch (status) {
              case LEAD:
              case EMPTY_LEAD:
                room?.room
                  ? transition.pushStage(REGISTRATION_STAGES[1])
                  : history.replace({
                      pathname: '/registration',
                      search: queryString.stringify({ email, stage: REGISTRATION_STAGES[1] }),
                    });
                return;
              default:
                history.replace({
                  pathname: '/registration',
                  search: queryString.stringify({ email, stage: 'complete' }),
                });
                return;
            }
          } else if (code === 404 && message === 'user-not-found') {
            setMessage(t('Такого аккаунта не существует'));
            setCountdown(null);

            return;
          } else {
            setMessage(message);
          }
        }

        setMessage('');
        transition.pushStage(transition.stages.code);
        setLoading(false);
      } catch (e) {
        throw Error(e);
      }
    } else {
      setValidation(true);
    }
  };

  const handleCodeSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (codeValid) {
      setLoading(true);
      setValidation(false);

      try {
        const room = queries.room ? { room: queries.room } : {};
        const data = { email, code, ...room };
        const { token, code: responseCode, message } = await authenticationCompletion(data);

        if (message === 'user-waiting') {
          history.push({ pathname: '/registration', search: queryString.stringify({ email, stage: 'complete' }) });

          return;
        }

        if (responseCode === 200) {
          if (queries.room) {
            history.push({
              search: queryString.stringify({
                room: queries.room,
                loggedByInvite: 1,
              }),
            });
          }
          await authorize(token);
        } else if (message === 'invalid-code') {
          setMessage(t('Неправильный код'));
        } else {
          setMessage(message);
        }
      } catch (e) {
        throw Error(e);
      }

      setLoading(false);
    } else {
      setValidation(true);
    }
  };

  useEffect(() => {
    emailRef && emailRef.focus();
    codeRef && codeRef.focus();
  }, [emailRef, codeRef]);

  useEffect(() => {
    if (
      !transition.stages.complete && //todo не забыть убрать
      queries?.email &&
      emailValidator.test(queries.email || '')
    ) {
      handleEmailSubmit();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (code.length === 6) {
      handleCodeSubmit();
    }

    if (message && code.length < 6) {
      setMessage('');
    }
  }, [code]); //eslint-disable-line

  useEffect(() => {
    setMessage('');
  }, [email, code]);

  const handleOnRegistrationClick = () =>
    registrationCallback ? registrationCallback() : history.push('/registration');

  return (
    <>
      <Spinner show={loading} overlay />
      <TransitionListItem show={transition.stage === transition.stages.email} direction={transition.direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Введите E-mail')}</Heading>
        </WideHeaderContainer>
        <StyledForm autoComplete="on" onSubmit={handleEmailSubmit}>
          <FormGroup
            onChange={setEmail}
            value={email}
            placeholder="example@example.com"
            label="E-mail"
            type="email"
            validation={!emailValid && validation}
            autoComplete="username"
            spellcheck="false"
            forwardRef={setEmailRef}
            disabled={countdown}
            required
          />
          <FormGroup value="void" type="password" autoComplete="password" spellcheck="false" hidden />
          {message && <Hint color="red">{message}</Hint>}
          <div className="place-bottom">
            <Button type="submit">{t('Получить код')}</Button>
            <StyledRegistrationLink>
              <A onClick={handleOnRegistrationClick}>{t('Регистрация')}</A>
            </StyledRegistrationLink>
          </div>
        </StyledForm>
      </TransitionListItem>

      <TransitionListItem show={transition.stage === transition.stages.code} direction={transition.direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Введите код')}</Heading>
        </WideHeaderContainer>
        <Hint>{t('На почту {{email}} был отправлен код для входа', { email })}</Hint>
        <Margin top={16} fullWidth>
          <StyledForm onSubmit={handleCodeSubmit} autoComplete="on">
            <FormGroup
              onChange={handleCodeChange}
              value={code}
              placeholder="783465"
              label={t('Код')}
              disabled={loading}
              validation={!codeValid && validation}
              autoComplete="one-time-code"
              forwardRef={setCodeRef}
              // numeric keyboard
              pattern="[0-9]*"
              inputmode="numeric"
            />
          </StyledForm>
        </Margin>
        {message && <Hint color="red">{message}</Hint>}
        <LoginCountdown time={time} countdown={countdown} handleEmailSubmit={handleEmailSubmit} />
      </TransitionListItem>
    </>
  );
};

Login.propTypes = {
  transition: PropTypes.shape({
    stage: PropTypes.number,
    stages: PropTypes.shape({
      email: PropTypes.number,
      code: PropTypes.number,
    }),
    pushStage: PropTypes.func,
    direction: PropTypes.string,
  }),
  registrationCallback: PropTypes.func,
};

export { Login };
