import React, { useCallback, useEffect, useState } from 'react';
import { Heading } from '../../common/components/Heading';
import { getLastMeetingReview } from '../../common/api/meetings-api';
import styled from 'styled-components';
import { Unite } from '../../common/components/Unite';
import { Button } from '../../common/components/Button';
import { useHistory } from 'react-router-dom';
import { Margin } from '../../common/components/Margin';
import { OuterContainer } from '../../common/components/OuterContainer';
import { WideHeaderContainer } from '../../common/components/WideHeaderContainer';
import { Spinner } from '../../common/components/Spinner';
import congrats from './assets/congrats.svg';
import { useTranslation } from 'react-i18next';

const StyledBigText = styled.div`
  margin-top: 16px;
  font-family: Roboto Condensed, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
`;

const StyledImage = styled.div`
  width: 85px;
  height: 85px;
  background: url(${congrats}) center center no-repeat;
`;

const YouAreIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const pushToRecommendations = () => history.push('/recommendations');

  const [lastReview, setLastReview] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchLastReview = useCallback(async () => {
    try {
      const lastReview = await getLastMeetingReview();
      setLastReview(lastReview.meeting);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    document.title = t('Uniter — Оставьте отзыв');
    fetchLastReview();
  }, []); //eslint-disable-line

  const hasLastReview = Object.keys(lastReview).length !== 0;

  return (
    <OuterContainer>
      <Spinner overlay show={loading} />
      {!loading && (
        <>
          {!hasLastReview && (
            <Margin top={66}>
              <StyledImage />
            </Margin>
          )}
          <WideHeaderContainer>
            <Heading>{t('Ура, вы записаны на эту неделю!')}</Heading>
          </WideHeaderContainer>
          {hasLastReview && <StyledBigText>{t('Расскажите, как прошла предыдущая встреча')}</StyledBigText>}
          {hasLastReview ? (
            <Margin top={40}>
              <Unite id={lastReview.id} partner={lastReview.partner} isFromSettings={pushToRecommendations} />
            </Margin>
          ) : (
            <div className="place-bottom">
              <Button onClick={pushToRecommendations}>{t('Продолжить')}</Button>
            </div>
          )}
        </>
      )}
    </OuterContainer>
  );
};

export { YouAreIn };
