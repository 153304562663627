import React from "react";

const Coin = () => (
  <svg width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#C08A00"/>
    <circle cx="10" cy="10" r="7.27273" fill="#E3A400"/>
    <path
      d="M11.3636 6.36365V11.2603C11.3636 12.0003 10.7399 12.2727 10 12.2727C9.26008 12.2727 8.63637 12.0003 8.63637 11.2603V6.36365H6.36365V11.4463C6.36365 13.2593 7.94681 14.5455 10 14.5455C12.0377 14.5455 13.6364 13.2593 13.6364 11.4463V6.36365H11.3636Z"
      fill="#946A00"/>
  </svg>
);

export default Coin;
