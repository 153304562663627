export const COLORS = {
  black: '#131C25',
  blue: '#2669E3',
  red: '#FF6948',
  redBg: 'rgba(255,105,72,0.05)',
  redBg2: 'rgba(255,105,72,0.6)',
  grey1: '#98A1AB',
  grey2: '#E8EBED',
  grey1Text: '#8E9AA6',
  grey2Text: '#CCD2D9',
  // greyBg: '#F6F7F8',
  greyBg: '#f9f9f9',
  white: '#FFFFFF',
  green: '#27AE60',
  gold: '#ebaa37',
  orange: '#efad29',
  darkBlue: 'hsl(219,77%,33%)',
  orangeGradient: 'linear-gradient(90deg, #eeba54 0%, #f0c457 33%, #f0b94b 66%, #efad29 100%)',
};

export const AVATAR_COLORS = {
  blue: '#2669E3',
  green: '#24CF40',
  orange: '#EB9D06',
  turquoise: '#56CDCB',
  pink: '#E87691',
  purple: '#9F60EE',
};

export const MOBILE_BREAKPOINT = 672;

export const ROUTES_WITHOUT_SIDEBAR = ['/congratulations', '/networking/settings', '/registration', '/complete'];

// User statuses
export const EMPTY_LEAD = 'empty-lead';
export const LEAD = 'lead';
export const NOT_RELEVANT = 'not_relevant';
export const REGISTERED = 'registered';
export const PENDING = 'pending';
export const APPROVED = 'approved';
export const GHOST = 'ghost';

export const LS_AUTH_TOKEN_KEY = 'auth-token';
export const LS_INVITATION_KEY = 'invitation';

export const INTRO_MAX_LENGTH = 300;
export const INTRO_MIN_LENGTH = 30;

export const INVITE_CODE_PLACEHOLDER = '7qy7rg2o48im58oeyhzffa97';

export const CLOUDPAYMENTS_BUNDLE_LINK = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
