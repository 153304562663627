import { apiInstance } from './api-instance';

export const getInviteLink = async () => {
  const { data } = await apiInstance.get('invite/link');

  return data;
};

export const postAnInvitation = async (emailList) => {
  const { data } = await apiInstance.post('invite', emailList);

  return data;
};

export const getCurrentInvitationList = async () => {
  const { data } = await apiInstance.get('invite');

  return data;
};
