import React, { useState } from 'react';
import { Margin } from '../../../common/components/Margin';
import { Heading } from '../../../common/components/Heading';
import { FormGroup } from '../../../common/components/FormGroup';
import styled from 'styled-components';
import { COLORS } from '../../../common/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../store/profile/selectors';

const StyledCopyToClipboard = styled.div`
  color: ${COLORS.blue};
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-weight: 500;
`;

const InviteLink = () => {
  const { t } = useTranslation();
  const [linkRef, setLinkRef] = useState(null);
  const { inviteLink } = useSelector(getProfileState);

  const handleOnCopy = () => {
    linkRef.select();
    document.execCommand('copy');
  };

  return (
    <>
      <Heading level={2}>{t('Отправьте ссылку')}</Heading>
      <Margin top={16}>
        <FormGroup value={inviteLink} placeholder="example@gmail.com" forwardRef={(i) => setLinkRef(i)} />
      </Margin>
      <Margin top={16}>
        <StyledCopyToClipboard onClick={handleOnCopy}>{t('Скопировать ссылку')}</StyledCopyToClipboard>
      </Margin>
    </>
  );
};

export { InviteLink };
