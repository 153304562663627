import { config } from '../../config';

export const fpEvents = {
  completeRegistration: 'CompleteRegistration',
};

export const initFbq = () => {
  /*eslint-disable*/
  //prettier-ignore

  if (config.facebookPixelId === 'void') return;

  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');

  window.fbq('init', config.facebookPixelId);

  if (config.advertFacebookPixelId) {
    window.fbq('init', config.advertFacebookPixelId);
  }

  window.fbq('track', 'PageView');
};

/**
 *
 * @param event
 * @param {Object} meta
 */
export const fbqSendEvent = (event, meta) => window.fbq('track', event, meta);
