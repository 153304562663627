import Type from 'union-type';

const T = () => true;
const F = () => false;

const RemoteResource = Type({
  NotAsked: [],
  Error: [T],
  Loading: [],
  Loaded: [T],
});

RemoteResource.of = v => RemoteResource.Loaded(v);

RemoteResource.prototype.isLoaded = function isLoaded() {
  return this.case({ Loaded: T, _: F });
};

RemoteResource.prototype.isLoading = function isLoading() {
  return this.case({ Loading: T, _: F });
};

RemoteResource.prototype.isNotAsked = function isNotAsked() {
  return this.case({ NotAsked: T, _: F });
};

RemoteResource.prototype.isError = function isError() {
  return this.case({ Error: T, _: F });
};


export const isLoaded = remoteResource => remoteResource.isLoaded();
export const isLoading = remoteResource => remoteResource.isLoading();
export const isError = remoteResource => remoteResource.isError();
export const isNotAsked = remoteResource => remoteResource.isNotAsked();


export default RemoteResource;
