import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './global-style';
import { Routing } from './routing';
import { ScrollToTop } from '../common/components/ScrollToTop';
import { ModalsWrapper } from '../common/components/ModalsWrapper';
import { useSelector } from 'react-redux';
import { authorize } from '../common/helpers/authorize';
import { Spinner } from '../common/components/Spinner';
import { Favico } from '../common/components/Favico';
import { ThemeProvider } from "styled-components";
import theme from "../common/theme";

const App = () => {
  const [authorization, setAuthorization] = useState(true);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const showPreloader = useSelector((s) => s.common.showPreloader);
  const translationLoaded = useSelector((s) => s.common.translationLoaded);
  window.onload = () => setTimeout(() => setWindowLoaded(true), 500);

  useEffect(() => {
    (async () => {
      await authorize();
      setAuthorization(false);
    })();
  }, []);

  const showPreloaderProp = authorization || !translationLoaded || !windowLoaded || showPreloader;

  return (
    <ThemeProvider theme={theme}>
      <>
        <Favico/>
        <GlobalStyle/>
        <ModalsWrapper/>
        {translationLoaded && (
          <BrowserRouter>
            <Routing/>
            <ScrollToTop/>
          </BrowserRouter>
        )}
        <Spinner appOverlay logo show={showPreloaderProp}/>
      </>
    </ThemeProvider>
  );
};

export { App };
