import { modalController } from '../../common/controllers/modal-controller';
import { Feedback } from './feedback';
import { i18next } from '../../i18n/i18n';
import React from 'react';

export const callFeedbackModal = (e) => {
  if (e?.currentTarget) {
    e.currentTarget.blur();
  }

  modalController.showModal({
    body: <Feedback />,
    title: i18next.t('Обратная связь'),
  });
};
