import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const DURATION = 75;

const StyledPayOverlay = styled.div`
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  transition: opacity ${DURATION}ms ease-in-out 0ms;
`;

const paymentTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const PaymentOverlay = ({ show }) => {
  return createPortal(
    <Transition mountOnEnter unmountOnExit in={show} timeout={DURATION}>
      {(state) => <StyledPayOverlay style={paymentTransitionStyles[state]} />}
    </Transition>,
    document.getElementById('root'),
  );
};

PaymentOverlay.propTypes = {
  show: PropTypes.bool,
};

PaymentOverlay.Container = StyledPayOverlay;

export { PaymentOverlay };
