export const region = process.env.REACT_APP_PREDEFINED_REGION.trim().toLowerCase();
const env = process.env.REACT_APP_PREDEFINED_ENV.trim();
const envCustomHost = process.env.REACT_APP_HOST;
const envCustomLocale = process.env.REACT_APP_REGION;

// Environment and region definitions
const ENV_DEV = 'development';
const ENV_STAGE = 'stage';
const ENV_PRODUCTION = 'prod';
export const REGION_RU = 'ru';
export const REGION_CI = 'ci';

export const isDevelopment = () => process.env.NODE_ENV === ENV_DEV;

// Constants
const BASE_URL = 'https://uniter.ai/api/';
const RU_PROD_BASE_URL = BASE_URL;
const RU_STAGE_BASE_URL = 'https://stage.uniter.ai/api/';
const CI_BASE_URL = 'https://innovators.club/api/';
const LINKS = {
  [REGION_RU]: {
    [ENV_PRODUCTION]: RU_PROD_BASE_URL,
    [ENV_STAGE]: RU_STAGE_BASE_URL,
  },
  [REGION_CI]: {
    [ENV_PRODUCTION]: CI_BASE_URL,
    [ENV_STAGE]: RU_STAGE_BASE_URL,
  },
};

const resolveApiBaseUrl = () => {
  if (envCustomHost) {
    return envCustomHost;
  }

  if (LINKS[region]?.[env]) {
    return LINKS[region][env];
  }

  return BASE_URL;
};

const resolveLanguageCode = () => {
  if (envCustomLocale) {
    return envCustomLocale;
  }

  //prettier-ignore
  switch (region) {
    case REGION_RU:       return 'ru';
    case REGION_CI:       return 'en';
    default:              return 'ru';
  }
};

const resolveShowPriorityPassPayment = () => {
  //prettier-ignore
  switch (region) {
    case REGION_RU:       return false;
    case REGION_CI:       return false;
    default:              return false;
  }
};

const resolveGoogleAnalyticsId = () => {
  //prettier-ignore
  switch (env) {
    case ENV_PRODUCTION:  {
      switch (region) {
        case REGION_RU:   return 'GTM-PMJPVPFZ';
        default:          return 'void';
      }
    }
    case ENV_STAGE:       return 'UA-174910577-1';
    default:              return 'void';
  }
};

const resolveYaMetrikaId = () => {
  //prettier-ignore
  switch (env) {
    case ENV_PRODUCTION: {
      switch (region) {
        case REGION_RU: return '83383999';
        default: return 'void';
      }
    }
    case ENV_STAGE: return 'void';
    default: return 'void';
  }
};

const resolveFacebookPixelId = () => {
  //prettier-ignore
  switch (env) {
    case ENV_PRODUCTION:  return '648659489113281';
    case ENV_STAGE:       return 'void';
    default:              return 'void';
  }
};

const resolveCloudpaymentsPublicId = () => {
  //prettier-ignore
  switch (env) {
    case ENV_PRODUCTION:  return 'pk_f088090dd2786d92fc7f262bacc35';
    case ENV_STAGE:       return 'pk_1f676a8ccde14657aa2970e266f1b';
    default:              return 'pk_1f676a8ccde14657aa2970e266f1b';
  }
};

const resolveI18nextDebug = () => {
  //prettier-ignore
  switch (env) {
    case ENV_PRODUCTION:  return false;
    case ENV_STAGE:       return true;
    default:              return false;
  }
};

const resolveTelegramIntegration = () => {
  //prettier-ignore
  switch (region) {
    case REGION_RU:       return true;
    case REGION_CI:       return true;
    default:              return false;
  }
};

const resolveRecommendationFeatures = () => {
  //prettier-ignore
  switch (region) {
    case REGION_RU:       return true;
    default:              return false;
  }
};

const resolveUniterPlusFeature = () => {
  //prettier-ignore
  switch (region) {
    case REGION_RU:       return false;
    default:              return false;
  }
};

const resolveGodModeFeature = () => {
  //prettier-ignore
  switch (region) {
    case REGION_RU:       return true;
    default:              return false;
  }
};

const resolveAdvertFacebookPixelId = () => {
  //prettier-ignore
  switch (env) {
    case ENV_PRODUCTION:  return '543852310092989';
    default:              return null;
  }
};

const resolveDefaultInviteCode = () => {
    //prettier-ignore
    switch (region) {
      case REGION_CI:   return '64616b47eaa4b3aa4a81e7fc';
      default:          return null;
    }
};

export const config = {
  apiBaseURL: resolveApiBaseUrl(),
  i18nextDebug: resolveI18nextDebug(),
  languageCode: resolveLanguageCode(),
  facebookPixelId: resolveFacebookPixelId(),
  advertFacebookPixelId: resolveAdvertFacebookPixelId(),
  googleAnalyticsId: resolveGoogleAnalyticsId(),
  yaMetrikaId: resolveYaMetrikaId(),
  cloudpaymentsPublicId: resolveCloudpaymentsPublicId(),
  showPriorityPassPayment: resolveShowPriorityPassPayment(),
  defaultInviteCode: resolveDefaultInviteCode(),
  features: {
    telegramIntegration: resolveTelegramIntegration(),
    recommendations: resolveRecommendationFeatures(),
    uniterPlus: resolveUniterPlusFeature(),
    godMode: resolveGodModeFeature(),
  },
};
