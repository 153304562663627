import { config } from '../../config';

export const initGa = () => {
  /* eslint-disable */
  //prettier-ignore

  // Create the script element for configuring Google Analytics
  const gaScript = document.createElement('script');

  gaScript.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${config.googleAnalyticsId}');`

  // Append the scripts to the head of the document
  // document.head.appendChild(gtmScript);
  document.head.appendChild(gaScript);

  const noscript = document.createElement("noscript");
  noscript.textContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.googleAnalyticsId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.body.prepend(noscript);
};

/**
 * Sends page view event to Google Analytics
 */
export const gaSendPageView = () => {
  // window.ga('send', 'pageview');
};

/**
 * Sends event to Google Analytics
 *
 * @param {object} params
 * @param {string} params.eventType e.g 'registration'
 * @param {string} params.step e.g 'step0'
 * @param {function} [params.callback]
 */
export const gaSendEvent = ({ eventType, step, callback }) => {
  const hitCallback = { hitCallback: callback };

  window.dataLayer.push({'event': eventType, "event_category": step, eventCallback: callback ? hitCallback : undefined, eventTimeout: 1000});
};

/**
 * Set's user identifier to Google Analytics
 *
 * @param {string} userId
 */
export const gaSetUser = (userId) => {
  window.dataLayer.push({'userId': userId});
};
