import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../common/constants';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StyledLink = styled.div`
  color: ${COLORS.blue};
  font-size: 12px;
  line-height: 16px;
  margin-top: 24px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const StyledCountdown = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 24px;
  color: ${COLORS.grey1Text};
`;

const LoginCountdown = ({ time, countdown, handleEmailSubmit }) => {
  const { t } = useTranslation();

  return countdown === null ? (
    <StyledLink onClick={handleEmailSubmit}>{t('Отправить код еще раз')}</StyledLink>
  ) : (
    <StyledCountdown>{t('Отправить еще раз {{time}}', { time })}</StyledCountdown> //TODO reduce `t()` call on every rerender
  );
};

LoginCountdown.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string]),
  countdown: PropTypes.number,
  handleEmailSubmit: PropTypes.func,
};

export { LoginCountdown };
