import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getRoom } from '../../common/api/rooms-api';
import { useTranslation } from 'react-i18next';
import { COLORS, MOBILE_BREAKPOINT } from '../../common/constants';
import { Button } from '../../common/components/Button';
import Skeleton from 'react-loading-skeleton';
import { Margin } from '../../common/components/Margin';
import { useHistory } from 'react-router-dom';

const subscribersIcon = (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="8" r="5" stroke="#98A1AB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12 21L11.8685 20.5793C11.2031 18.4499 9.23098 17 7 17V17C4.76902 17 2.79691 18.4499 2.13147 20.5793L2 21"
      stroke="#98A1AB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3402 11.3889C15.0594 12.0792 14.6954 12.7268 14.2606 13.3193C15.1765 14.3503 16.5124 15 18 15C20.7614 15 23 12.7614 23 10C23 7.23858 20.7614 5 18 5C17.1584 5 16.3654 5.20791 15.6696 5.57517C15.8649 6.27495 15.9777 7.00912 15.997 7.76656C16.5281 7.28994 17.2302 7 18 7C19.6569 7 21 8.34315 21 10C21 11.6569 19.6569 13 18 13C16.8443 13 15.8412 12.3465 15.3402 11.3889Z"
      fill="#98A1AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2484 17.7944C15.5586 18.4243 15.7802 19.0965 15.8992 19.7986C16.465 19.296 17.2066 19 18 19C19.3611 19 20.5696 19.871 21 21.1623L21.0513 21.3162C21.226 21.8402 21.7923 22.1233 22.3162 21.9487C22.8402 21.774 23.1233 21.2077 22.9487 20.6838L22.8974 20.5298C22.1947 18.4218 20.222 17 18 17C17.0016 17 16.0535 17.2871 15.2484 17.7944Z"
      fill="#98A1AB"
    />
  </svg>
);

const roomIcon = (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60" cy="60" r="60" fill="#E4ECFB" />
    <rect x="45" y="35" width="30" height="50" rx="2" stroke="#2669E3" strokeWidth="2" />
    <circle cx="69" cy="65" r="1" fill="#2669E3" stroke="#2669E3" strokeWidth="2" />
  </svg>
);

const StyledContainer = styled.div`
  margin-top: ${window.innerWidth < MOBILE_BREAKPOINT ? 66 : 80}px;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

/**
 * @property image — url to the image
 */
const StyledPhoto = styled.div`
  width: 120px;
  height: 120px;
  background-image: url(${(p) => p.image});
  background-position: center;
  background-size: cover;
  border-radius: 100%;
`;
const StyledUserPhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;
const StyledUserPhotosItem = styled.div`
  border: 2px solid #fff;
  background-image: url("${(p) => p.image}");
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  &:not(:first-child) {
    margin-left: -8px;
  } 
`;
const StyledName = styled.div`
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  margin-top: 16px;
`;
const StyledDescription = styled.div`
  color: ${COLORS.grey1Text};

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const StyledSubscribersCount = styled.div`
  display: flex;
  flex-direction: row;

  & > svg {
    margin-right: 4px;
    margin-top: -1px;
  }
  & > span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: ${COLORS.grey1Text};
  }
`;

const RoomSkeleton = () => (
  <Margin top={window.innerWidth < MOBILE_BREAKPOINT ? 66 : 80}>
    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 'max-content', marginBottom: 16 }}>
      <Skeleton width={120} height={120} circle />
    </div>
    <Skeleton height={24} style={{ marginBottom: 16 }} />
    <Skeleton height={48} style={{ marginBottom: 16 }} />
    <Skeleton height={32} style={{ marginBottom: 16 }} />
    <Skeleton height={16} style={{ marginBottom: 16 }} />
    <Skeleton height={56} />
  </Margin>
);

const Room = ({ id, onAccept, room }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [_room, setRoom] = useState(room);

  const fetchRoom = async (id) => {
    const data = await getRoom(id);
    if (!data?.room) {
      history.push('/');
      return;
    }
    setRoom(data?.room);
  };

  useEffect(() => {
    id && fetchRoom(id);
  }, [id]); //eslint-disable-line

  if (!_room) {
    return <Skeleton />;
  }

  return (
    <>
      <StyledContainer>
        {_room?.pic ? <StyledPhoto image={_room.pic} /> : roomIcon}
        <StyledName>{_room.name}</StyledName>
        <StyledDescription>
          <span>{_room.description}</span>
        </StyledDescription>
        {_room?.subscribers?.pics?.length > 0 && (
          <StyledUserPhotosContainer>
            {_room.subscribers.pics
              .filter((pic) => typeof pic === 'string')
              .map((uri) => (
                <StyledUserPhotosItem key={uri.slice(10)} image={uri} />
              ))}
          </StyledUserPhotosContainer>
        )}
        <StyledSubscribersCount>
          {subscribersIcon}
          <span>
            {t('{{count}} участников', {
              count: _room.subscribers.count,
              defaultValue: '{{count}} участник',
              defaultValue_0: '{{count}} участник',
              defaultValue_1: '{{count}} участника',
              defaultValue_2: '{{count}} участников',
            })}
          </span>
        </StyledSubscribersCount>
      </StyledContainer>
      <Button onClick={onAccept}>{t('Присоединиться к комнате')}</Button>
    </>
  );
};

Room.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onAccept: PropTypes.func,
  room: PropTypes.shape({
    pic: PropTypes.string,
    subscribers: PropTypes.shape({
      count: PropTypes.number,
      pics: PropTypes.arrayOf(PropTypes.string),
    }),
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

Room.Avatar = StyledPhoto;
Room.Skeleton = RoomSkeleton;

export { Room };
