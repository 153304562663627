import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import { StarRating } from '../StarRating';
import { FormGroup } from '../FormGroup';
import { Button } from '../Button';
import { postMeetingReview } from '../../api/meetings-api';
import { Spinner } from '../Spinner';
import { useTranslation } from 'react-i18next';
import { Margin } from '../Margin';
import { Card } from '../Card';
import { UserCard } from '../UserCard';

const StyledContainer = styled.div`
  &.show-validation {
    & ${Card} {
      animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const FooterContainer = styled.div`
  ${FormGroup.Container} {
    margin-top: 24px;
  }

  ${Button.Container} {
    margin-top: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const AbortButton = styled.button`
  padding: 6px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background: ${(p) => (p.stateless ? 'none' : p.aborted ? COLORS.grey1 : COLORS.grey2)};
  color: ${(p) => (p.aborted ? COLORS.white : COLORS.grey1Text)};
  border-radius: 100px;
  transition: 0.15s;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  user-select: none;
`;

const StyledPassedComment = styled.div`
  width: 100%;
`;

const StyledAbortContainer = styled.div`
  margin: 16px -8px -8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin: 8px;
  }
`;

/**
 *
 * @param id
 * @param reviewedData
 * @param updated
 * @param partner
 * @param {Function} fetchCb
 * @param {Function} isFromSettings
 * @param isJustUserCard
 * @returns {JSX.Element}
 * @constructor
 */
const Unite = ({ id, reviewedData, updated, partner, fetchCb, isFromSettings, stateless }) => {
  const { t } = useTranslation();

  const [aborted, setAborted] = useState(false);
  const [score, setScore] = useState(reviewedData?.score || 0);
  const [teamComment, setTeamComment] = useState(reviewedData?.teamComment || '');
  const [personalComment, setPersonalComment] = useState(reviewedData?.personalComment || '');
  const [validation, setValidation] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleOnAbort = useCallback(() => {
    setAborted((s) => !s);
    setScore(0);
  }, []);

  const handleScoreChange = useCallback((score) => {
    setScore(score);
    setAborted(false);
  }, []);

  const handleMeetingReview = useCallback(async () => {
    const reviewValid = score > 0 || (aborted && teamComment.length > 1);

    if (isFromSettings) {
      if (reviewValid) {
        setValidation(false);
        setLoading(true);
        if (aborted) {
          await postMeetingReview(id, {
            result: aborted ? 'no-answer' : 'bad',
            team_comment: teamComment,
            score,
          });
        } else {
          await postMeetingReview(id, {
            result: 'good',
            personal_comment: personalComment,
            score,
          });
        }
        setLoading(false);
        isFromSettings();
        return;
      } else {
        setValidation(true);
        setShowValidation(true);
        return;
      }
    }

    if (aborted) {
      if (teamComment.length >= 1) {
        setValidation(false);
        setLoading(true);

        await postMeetingReview(id, {
          result: aborted ? 'no-answer' : 'bad',
          team_comment: teamComment,
          score,
        });
        setLoading(false);
        fetchCb();
      } else {
        setValidation(true);
      }
    } else {
      setLoading(true);
      await postMeetingReview(id, {
        result: 'good',
        personal_comment: personalComment,
        score,
      });
      setLoading(false);
      fetchCb();
    }
  }, [aborted, fetchCb, id, isFromSettings, personalComment, score, teamComment]);

  useEffect(() => {
    if (teamComment?.length > 1) {
      setValidation(false);
    }
  }, [teamComment]);

  useEffect(() => {
    if (showValidation) {
      setTimeout(() => setShowValidation(false), 1000);
    }
  }, [showValidation]);

  const showRating = !stateless && (updated ? score > 0 : true);
  const ratingStateless = !!updated;
  const showAborted = !stateless && (updated ? score === 0 : true);
  const abortedStateless = updated;
  const showTextarea = !stateless && !updated && (aborted || !!score);
  const showComment = false;
  const showButton = !stateless && !updated && !isFromSettings && (aborted || !!score);

  let {
    has_subscription: hasSubscription,
    social_networks: socialNetworks,
    first_name: firstName,
    last_name: lastName,
    pic,
    email,
    intro,
  } = partner;

  const { facebook, linkedin } = socialNetworks ?? {};
  const links = useMemo(
    () =>
      [
        facebook ? { icon: 'facebook', href: facebook } : null,
        linkedin ? { icon: 'linkedin', href: linkedin } : null,
        email ? { icon: 'emailFill', href: 'mailto:' + email } : null,
      ].filter((a) => a !== null),
    [email, facebook, linkedin],
  );

  return (
    <>
      <StyledContainer className={showValidation ? 'show-validation' : undefined}>
        <UserCard
          pic={pic}
          firstName={firstName}
          lastName={lastName}
          hasSubscription={hasSubscription}
          intro={intro}
          links={links}
          showFooterSeparator
        >
          <Spinner show={loading} overlay />
          <FooterContainer>
            {showRating && <StarRating rating={score} setRating={handleScoreChange} stateless={ratingStateless} />}

            {showComment && <StyledPassedComment>{teamComment || personalComment}</StyledPassedComment>}

            <StyledAbortContainer>
              {showAborted && (
                <AbortButton
                  aborted={updated ? false : aborted}
                  stateless={abortedStateless}
                  onClick={handleOnAbort}
                  disabled={updated}
                >
                  {t('Встреча не состоялась')}
                </AbortButton>
              )}

              {isFromSettings && <AbortButton onClick={isFromSettings}>{t('Мы перенесли встречу')}</AbortButton>}
            </StyledAbortContainer>

            {showTextarea && (
              <FormGroup
                as="textarea"
                backgroundColor={COLORS.white}
                placeholder={aborted ? t('Укажите причину') : t('Ваш отзыв')}
                value={aborted ? teamComment : personalComment}
                onChange={aborted ? setTeamComment : setPersonalComment}
                validation={aborted ? validation && teamComment.length < 10 : false}
              />
            )}

            {showButton && (
              <Button onClick={handleMeetingReview} disabled={validation}>
                {t('Готово')}
              </Button>
            )}
          </FooterContainer>
        </UserCard>
      </StyledContainer>
      {isFromSettings && (
        <div className="place-bottom">
          <Margin top={16}>
            <Button onClick={handleMeetingReview}>Продолжить</Button>
          </Margin>
        </div>
      )}
    </>
  );
};

Unite.defaultProps = {
  name: 'Ivan Sidorov',
};

Unite.propTypes = {
  partnerEmail: PropTypes.string,
  result: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  updated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fetchCb: PropTypes.func,
  reviewedData: PropTypes.shape({
    personalComment: PropTypes.string,
    teamComment: PropTypes.string,
    score: PropTypes.number,
  }),
  partner: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    pic: PropTypes.string,
    intro: PropTypes.string,
    has_subscription: PropTypes.bool,
    social_networks: PropTypes.shape({
      linkedin: PropTypes.string,
      facebook: PropTypes.string,
    }),
  }),
  stateless: PropTypes.bool,
  isFromSettings: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  hasSubscription: PropTypes.bool,
};

Unite.Container = StyledContainer;

export { Unite };
