import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '../../common/components/Container';
import { Header } from '../../common/components/Header';
import { useTranslation } from 'react-i18next';
import { getProfileRecommendations } from '../../common/api/dashobard-api';
import { RecommendationCard } from './components/recommendation-card';
import { Margin } from '../../common/components/Margin';
import { RecommendationOnboarding } from './components/recommendation-onboarding';
import { Button } from '../../common/components/Button';
import { InviteByEmail } from '../Invites/components/invite-by-email';
import { callFeedbackModal } from '../Feedback';
import { Card } from '../../common/components/Card';
import { RecommendationsCopyLink } from './components/recommendations-copy-link';

const Recommendations = () => {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchRecommendations = useCallback(async () => {
    const data = await getProfileRecommendations();

    setProfiles(data.users);
    setLoading(false);
  }, []);

  useEffect(() => {
    document.title = t('Uniter — Рекомендации');

    fetchRecommendations();
  }, [fetchRecommendations, t]);

  return (
    <Container>
      <RecommendationOnboarding />

      <Header>{t('Рекомендации')}</Header>

      <Card>
        <p>
          {t(
            'Пригласите в Uniter своих друзей — наши алгоритмы станут лучше, ' +
              'а вы будете получать более релевантные юнайты',
          )}
        </p>
        <InviteByEmail integrated />
        <RecommendationsCopyLink />
      </Card>

      {loading && (
        <>
          {RecommendationCard.Skeleton}
          {RecommendationCard.Skeleton}
          {RecommendationCard.Skeleton}
          {RecommendationCard.Skeleton}
          {RecommendationCard.Skeleton}
        </>
      )}

      {!loading &&
        profiles?.map((profile) => {
          return (
            <RecommendationCard
              key={profile.id}
              id={profile.id}
              firstName={profile.first_name}
              lastName={profile.last_name}
              intro={profile.intro}
              pic={profile.pic}
              hasSubscription={profile.has_subscription}
              stored={profile.stored}
              onStoredCallback={fetchRecommendations}
              city={profile.city}
            />
          );
        })}

      {profiles?.length === 0 && t('Мы еще не сформировали рекомендации для вас')}

      <Card>
        {t(
          'Рекомендации закончились, но ваша фантазия безгранична. ' +
            'Поделитесь с нами любыми идеями по улучшению платформы.',
        )}
        <Margin top={16}>
          <Button variant="light" onClick={callFeedbackModal}>
            {t('Обратная связь')}
          </Button>
        </Margin>
      </Card>
    </Container>
  );
};

export { Recommendations };
