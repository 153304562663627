/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS, MOBILE_BREAKPOINT, ROUTES_WITHOUT_SIDEBAR } from '../../constants';
import { SidebarMenu } from './components/sidebar-menu';
import { Logo } from '../Logo';
import { SidebarProfile } from './components/sidebar-profile';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_SIDEBAR } from '../../../store/action-types';
import { Message } from '../Message';
import { MessageContainer } from '../Message/message';
import { useLocation } from 'react-router-dom';
import { getMeetingsState } from '../../../store/selectors';
import { messageStateController } from '../../controllers/message-state-controller';
import { getProfileState } from '../../../store/profile/selectors';
import { ROUTES } from '../../../app/routing';

const StyledContainer = styled('aside')`
  position: fixed;
  left: 0;
  top: 0;
  width: 256px;
  padding: 8px;
  background-color: ${COLORS.greyBg};
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 1;

  transition: transform 0.35s ease-out;

  ${Logo} {
    margin-left: 16px;
    margin-top: 16px;
  }

  ${MessageContainer} {
    margin-top: 16px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    transform: translateX(${(p) => (p.opened ? '0' : '-900px')});
    justify-content: flex-start;
    background: #ffffff;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 6px 30px rgba(0, 0, 0, 0.12), 0 16px 24px rgba(0, 0, 0, 0.14);
    z-index: 5;
    width: 85%;
    padding: 0;

    ${MessageContainer} {
      background-color: ${COLORS.greyBg};
      margin: 24px 16px 0;
    }
  }
`;

const Sidebar = () => {
  const dispatch = useDispatch();
  const toggleSidebar = () => dispatch({ type: TOGGLE_SIDEBAR });
  const isSidebarOpened = useSelector((state) => state.common.isSidebarOpened);
  const { status, comment } = useSelector(getProfileState);
  const { united, meetings } = useSelector(getMeetingsState);
  const message = messageStateController({ status, united, meetings });
  const [ref, setRef] = useState(null);
  const { pathname } = useLocation();

  const onWindowClick = (e) => {
    if (e.target !== ref) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    if (isSidebarOpened) {
      setTimeout(() => window.addEventListener('click', onWindowClick), 450);
    }

    return () => window.removeEventListener('click', onWindowClick);
  }, [isSidebarOpened]); //eslint-disable-line

  if (ROUTES_WITHOUT_SIDEBAR.includes(pathname)) {
    return null;
  }

  return (
    <StyledContainer ref={(ref) => setRef(ref)} opened={isSidebarOpened}>
      <Logo to={ROUTES.unites.path} />
      <SidebarMenu />
      <SidebarProfile />
      {message && <Message text={message.text} title={message.title} link={message.link} />}
      {comment && <Message text={comment} />}
    </StyledContainer>
  );
};

export { Sidebar };
