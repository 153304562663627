export const calculatePosition = (target, reference, callback, offset = 16) => {
  const doc = document.documentElement;

  const save = target.style.display;

  if (save === 'none') {
    target.style.display = 'block';
  }

  const tooltipWidth = target.offsetWidth;
  const tooltipHeight = target.offsetHeight;
  const tooltipHalfWidth = tooltipWidth / 2;

  if (save === 'none') {
    target.style.display = save;
  }

  const refRect = reference.getBoundingClientRect();

  const center = doc.scrollLeft + refRect.left + refRect.width / 2;
  const outOfEdge = doc.offsetWidth - (center + tooltipHalfWidth) - offset;

  const top = doc.scrollTop + refRect.top - tooltipHeight - 12;
  const left = center - tooltipHalfWidth + (outOfEdge < 0 ? outOfEdge : 0);
  //eslint-disable-next-line
  const bottom = top + tooltipHeight + offset;

  callback({
    top: Math.ceil(top < offset ? offset : top),
    left: Math.ceil(left < offset ? offset : left),
    triangleOffset: center - left - tooltipHalfWidth,
  });
};
