import { apiInstance } from './api-instance';

export const getRoom = async (id) => {
  const { data } = await apiInstance.get(`/room/${id}`);

  return data;
};

export const getInRoom = async (id, invite) => {
  const { data } = await apiInstance.post(`/room/${id}`, { invite });

  return data;
};
