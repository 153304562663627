import React from "react";

export const icons = {
  facebook: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="facebook">
        <circle id="background" cx="12" cy="12" r="12" fill="#CCD2D9"/>
        <path
          id="icon"
          d="M16.1032 14.8552L16.6606 11.3379H13.3161V8.87586C13.3161 7.89103 13.6645 7.11724 15.1974 7.11724H16.8V3.88138C15.8942 3.74069 14.9187 3.6 14.0129 3.6C11.1561 3.6 9.13548 5.35862 9.13548 8.52414V11.3379H6V14.8552H9.13548V24C9.78 24 10.529 24 11.2258 24C11.9226 24 12.48 24 13.3161 24V14.8552H16.1032Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  twitter: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="twitter">
        <circle id="background" cx="12" cy="12" r="12" fill="#CCD2D9"/>
        <path
          id="icon"
          d="M20.2353 7.48534C19.6676 7.73686 19.0579 7.9068 18.4168 7.98362C19.0708 7.59206 19.5725 6.97074 19.809 6.23113C19.1972 6.59413 18.5195 6.85789 17.7982 6.99929C17.2204 6.38476 16.3979 6 15.4869 6C13.7385 6 12.3205 7.41804 12.3205 9.16781C12.3205 9.41525 12.349 9.6559 12.4021 9.88838C9.76925 9.75583 7.4369 8.49482 5.87474 6.57918C5.60283 7.04823 5.44648 7.59206 5.44648 8.17124C5.44648 9.26978 6.00594 10.2398 6.855 10.8075C6.33564 10.7905 5.84755 10.6484 5.42065 10.4111V10.4519C5.42065 11.9862 6.51171 13.2662 7.96101 13.5572C7.69454 13.6293 7.41514 13.6673 7.12691 13.6673C6.92298 13.6673 6.7238 13.6483 6.53074 13.6116C6.93385 14.8692 8.10309 15.7855 9.48917 15.81C8.40491 16.6597 7.03922 17.1662 5.55592 17.1662C5.30032 17.1662 5.04812 17.1512 4.8 17.122C6.20172 18.0214 7.86584 18.5448 9.65368 18.5448C15.4795 18.5448 18.6643 13.7197 18.6643 9.53489C18.6643 9.39894 18.6609 9.26162 18.6547 9.12566C19.2734 8.67836 19.8104 8.12162 20.2339 7.48738L20.2353 7.48534Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  telegram: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="telegram">
        <path
          id="background"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#CCD2D9"
        />
        <path
          id="icon"
          d="M4.09992 11.5964C8.39487 9.76182 11.2589 8.55233 12.6919 7.96797C16.7834 6.2995 17.6335 6.00967 18.1877 6.0001C18.3095 5.998 18.5821 6.02761 18.7586 6.16804C18.9076 6.28661 18.9486 6.44679 18.9683 6.55921C18.9879 6.67163 19.0123 6.92773 18.9929 7.12784C18.7712 9.41185 17.8118 14.9545 17.3237 17.5127C17.1172 18.5951 16.7105 18.958 16.3169 18.9936C15.4613 19.0707 14.8116 18.4392 13.9829 17.9067C12.6862 17.0733 11.9537 16.5545 10.695 15.7413C9.24043 14.8015 10.1834 14.285 11.0124 13.4409C11.2293 13.22 14.999 9.8583 15.0719 9.55334C15.0811 9.5152 15.0895 9.37304 15.0034 9.29797C14.9172 9.2229 14.7901 9.24857 14.6983 9.26899C14.5683 9.29792 12.4968 10.6403 8.48389 13.2961C7.89591 13.6919 7.36333 13.8848 6.88616 13.8747C6.36012 13.8635 5.34822 13.5831 4.59598 13.3433C3.67333 13.0493 2.94002 12.8938 3.00388 12.3944C3.03714 12.1343 3.40248 11.8683 4.09992 11.5964Z"
          fill="white"
        />
      </g>
    </svg>
  ),
};
