import { apiInstance } from './api-instance';

export const makeRegistration = async (data) => {
  const response = await apiInstance.post('/registration', data);

  return response?.data?.code;
};

export const makeRegistrationComplete = async (data) => {
  const response = await apiInstance.post('/registration/complete', data);

  return response.data;
};

export const makeRegistrationRequest = async (data) => {
  const response = await apiInstance.post('/registration/request', data);

  return response.data;
};
