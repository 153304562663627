import React from "react";
import Box from "../../../common/components/Box";
import Coin from "../../../common/components/Icons/coin";
import PropTypes from 'prop-types';

export const Balance = ({ amount = 0, ...props }) => (
  <Box bg="greyBg"
       fontWeight="bold"
       color="grey1Text"
       alignItems="center"
       padding="4px 6px"
       display="inline-flex"
       borderRadius="16px"
       {...props}>
    <Box mr="6px">{amount}</Box>
  <Coin/>
  </Box>
);
Balance.propTypes = {
  amount: PropTypes.number,
};
