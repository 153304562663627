import React, { useCallback, useMemo, useState } from 'react';
import { Spinner } from '../Spinner';
import { Margin } from '../Margin';
import { Range } from 'react-range';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { postProfilePic } from '../../api/profile-api';
import { actualizeProfile } from '../../helpers/actualize-profile';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { COLORS } from '../../constants';
import { Avatar, hasSubscriptionMixin } from '../Avatar/avatar';
import camera from './assets/camera.svg';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DEFAULT_SCALE = 1.5;

const AVATAR_IMAGE_TYPE = 'image/jpeg';
const AVATAR_IMAGE_QUALITY = 90;

const StyledTrack = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 3px;
  background: ${COLORS.greyBg};
  border: 1px solid ${COLORS.grey2Text};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledThumb = styled.div`
  width: 24px;
  height: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  background-color: ${COLORS.blue};
  border-radius: 3px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > div {
    background-color: ${COLORS.greyBg};
    width: 6px;
    height: 12px;
    border-radius: 2px;
  }
`;

const StyledContainer = styled.label`
  cursor: pointer;
  height: auto;
  position: relative;
  border-radius: 1000px;
  overflow: hidden;
  width: max-content;

  ${(p) => p.hasSubscription && hasSubscriptionMixin}

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    content: '';
    background: rgba(0, 0, 0, 0.6) url(${camera}) no-repeat center center;
    background-size: 18px 18px;
    width: 100%;
  }
`;

const EditAvatar = ({ onSave, hasSubscription, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const [uploadingImageFile, setUploadingImageFile] = useState();
  const [editor, setEditor] = useState();
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [modal, setModal] = useState(false);
  const setEditorRef = useCallback((editor) => setEditor(editor), []);
  const formData = useMemo(() => new FormData(), []);

  const handleOnChange = useCallback((e) => {
    setUploadingImageFile(e.target.files[0]);
    setModal(true);
  }, []);

  const handleOnSave = useCallback(async () => {
    const canvas = editor.getImageScaledToCanvas();
    const dataUrl = canvas.toDataURL(AVATAR_IMAGE_TYPE, AVATAR_IMAGE_QUALITY);

    canvas.toBlob(
      async (blob) => {
        setAvatarUploading(true);
        formData.append('file', blob, 'pic.jpeg');

        if (onSave) {
          onSave({ formData, base64: dataUrl });
        } else {
          try {
            await postProfilePic(formData);
            await actualizeProfile(dispatch);
          } catch (e) {
            throw Error(e);
          }
        }

        setModal(false);
        setTimeout(() => {
          setAvatarUploading(false);
          setUploadingImageFile(undefined);
          setScale(DEFAULT_SCALE);
        }, 230);
      },
      AVATAR_IMAGE_TYPE,
      AVATAR_IMAGE_QUALITY,
    );
  }, [dispatch, editor, formData, onSave]);

  const handleOnLeave = useCallback(() => {
    formData.delete('file');
    setUploadingImageFile(undefined);
    setScale(DEFAULT_SCALE);
    setModal(false);
  }, [formData]);

  const renderThumb = useCallback(
    ({ props }) => (
      <StyledThumb {...props}>
        <div />
      </StyledThumb>
    ),
    [],
  );

  const renderTrack = useCallback(({ props, children }) => <StyledTrack {...props}>{children}</StyledTrack>, []);

  return (
    <>
      <StyledContainer className="avatar" hasSubscription={hasSubscription}>
        <StyledInput type="file" value="" onChange={handleOnChange} accept="image/*" multiple={false} />
        <Avatar {...props} />
      </StyledContainer>

      <Modal title={t('Загрузка фотографии')} show={modal} onLeave={handleOnLeave}>
        <Spinner show={avatarUploading} />
        {!avatarUploading && (
          <>
            <AvatarEditor
              ref={setEditorRef}
              image={uploadingImageFile}
              width={250}
              height={250}
              border={20}
              borderRadius={500}
              scale={Number(scale)}
              style={{ borderRadius: 500 }}
              rotate={0}
            />
            <Margin top={32} fullWidth>
              <Range
                onChange={setScale}
                values={[scale]}
                renderThumb={renderThumb}
                renderTrack={renderTrack}
                min={1}
                max={4}
                step={0.01}
              />
            </Margin>
            <Margin top={48} fullWidth>
              <Button onClick={handleOnSave}>{t('Сохранить')}</Button>
            </Margin>
          </>
        )}
      </Modal>
    </>
  );
};

EditAvatar.propTypes = {
  onSave: PropTypes.func,
  hasSubscription: PropTypes.bool,
  ...Avatar.propTypes,
};

export { EditAvatar };
