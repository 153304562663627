import { apiInstance } from './api-instance';
import { config } from '../../config';

export const getProfileRecommendations = async () => {
  const response = await apiInstance({
    method: 'GET',
    baseURL: config.apiBaseURL + 'v2',
    url: 'dashboard/profiles',
  });

  return response.data;
};

export const postProfileRecommendation = async (userId) => {
  const response = await apiInstance({
    method: 'POST',
    baseURL: config.apiBaseURL + 'v2',
    url: 'dashboard/profiles',
    data: {
      users: [userId],
    },
  });

  return response.data;
};
