import { store } from '../../store/store';
import { clearLocalStorage } from './ls';
import { removeAuthHeaders } from '../api/api-instance';
import { SET_AUTHORIZED } from '../../store/action-types';
import { invalidateToken } from '../api/auth-api';
import { LS_AUTH_TOKEN_KEY } from "../constants";
import { setCurrentProfile } from "../../store/profile/actions";

export const unauthorize = async () => {
  const dispatch = store.dispatch;

  await invalidateToken();
  clearLocalStorage(LS_AUTH_TOKEN_KEY);
  removeAuthHeaders();
  dispatch(setCurrentProfile());
  dispatch({ type: SET_AUTHORIZED, payload: false });
};
