import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';

const mixin = css`
  color: ${COLORS.grey1Text};
  font-size: 12px;
  line-height: 18px;
`;

const TermsText = styled(({ className, as, children }) => {
  const Wrapper = as ?? 'div';

  return <Wrapper className={className}>{children}</Wrapper>;
})`
  ${mixin};
`;

TermsText.A = styled(({ className, children, ...props }) => (
  <a target="_blank" rel="noreferrer" className={className} {...props}>
    {children}
  </a>
))`
  ${mixin};
  text-decoration: none;
  font-weight: 500;
  color: inherit;
  line-height: inherit;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

export { TermsText };
