import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPaymentProducts, postPaymentInvoice } from '../../../common/api/payment-api';
import { getSubscription } from '../../../common/api/subscription-api';
import { A } from '../../../common/components/A';
import { Button } from '../../../common/components/Button';
import { Heading } from '../../../common/components/Heading';
import { Indicator } from '../../../common/components/Indicator';
import { Margin } from '../../../common/components/Margin';
import { TermsText } from '../../../common/components/TermsText';
import { CLOUDPAYMENTS_BUNDLE_LINK, COLORS } from '../../../common/constants';
import { ConfettiContext } from '../../../common/contexts/confetti-context/confetti-context';
import { modalController } from '../../../common/controllers/modal-controller';
import { formatDate } from '../../../common/helpers/format-date';
import { formatPrice } from '../../../common/helpers/format-price';
import { useScript } from '../../../common/hooks/use-script';
import { config } from '../../../config';
import { settings } from '../../../settings';

const confettiDuration = 5000;
const confettiColors = ['#eeba54', '#f0c457', '#f0b94b', '#efad29', '#042C8C', '#F40404'];

const ExtendedA = styled(A)`
  color: ${COLORS.grey1Text};
  font-size: 12px;
`;

const UniterPlusSubscriptionInfo = () => {
  const [info, setInfo] = useState(null);

  const fetchSubscriptionInfo = useCallback(async () => {
    const data = await getSubscription();

    setInfo(data);
  }, []);

  useEffect(() => {
    fetchSubscriptionInfo();
  }, [fetchSubscriptionInfo]);

  const active = info?.subscription?.active ?? false;
  const cancelled = info?.subscription?.cancelled ?? false;
  const nextBillTime = info?.subscription?.next;
  const formattedNextBillTime = useMemo(() => {
    try {
      return active ? formatDate(nextBillTime) : 'н/д';
    } catch (e) {
      console.error(e);
      return 'н/д';
    }
  }, [active, nextBillTime]);
  const formattedNextBillText = cancelled ? `Активна до: ${formattedNextBillTime}` : `Следующее списание: ${formattedNextBillTime}`;
  const activeText = active ? (cancelled ? 'Подписка заканчивается' : 'Подписка успешно оформлена') : 'Вы не подписаны';
  const preloader = info === null;

  const [paymentOverlay, setPaymentOverlay] = useState(false);
  const [cloudPaymentsScriptLoaded] = useScript(CLOUDPAYMENTS_BUNDLE_LINK);
  const [product, setProduct] = useState(null);
  const { showConfetti } = useContext(ConfettiContext);
  const { first_name: firstName, last_name: lastName, email } = useSelector((state) => state.profile);
  const { id, price, currency, sale, original: originalPrice, expiration } = product ?? {};

  const fetchProducts = useCallback(async () => {
    try {
      const data = await getPaymentProducts({ email });
      const priority_pass = data?.products?.find((p) => p.name === settings.products.uniterPlus1M);
      setProduct(priority_pass || null);
    } catch (e) {
      console.error(e);
    }
  }, [email]);

  useEffect(() => {
    if (cancelled) {
      fetchProducts();
    }
  }, [fetchProducts, cancelled]);

  const onPaymentSuccess = useCallback(() => {
    getSubscription().then((data) => setInfo(data)).then(() => setPaymentOverlay(false));

    showConfetti({
      style: { zIndex: 20 },
      colors: confettiColors,
      numberOfPieces: 200,
      gravity: 0.2,
      tweenDuration: confettiDuration,
    });

    modalController.showModal({
      title: 'Поздравляем!',
      // eslint-disable-next-line react/display-name
      body: (onLeave) => (
        <>
          <p style={{ textAlign: 'center' }}>
            Оплата успешно произведена и ваша подписка продлена.
          </p>
        </>
      ),
      showCloseButton: true,
    });
  }, [showConfetti]);


  const onPaymentFailure = useCallback(() => {
    setPaymentOverlay(false);
    modalController.showModal({
      title: 'Упс',
      // eslint-disable-next-line react/display-name
      body: (onLeave) => (
        <>
          <p style={{ textAlign: 'center' }}>
            К сожалению, во время оплаты произошла ошибка. Попробуйте еще раз или обратитесь в поддержку:{' '}
            <A href="mailto:hi@uniter.ai">hi@uniter.ai</A>
          </p>
          <Button onClick={onLeave}>OK</Button>
        </>
      ),
      showCloseButton: true,
    });
  }, []);

  const handleOnSubscriptionClick = useCallback(async () => {
    setPaymentOverlay(true);
    const { invoice } = await postPaymentInvoice({ product: id, email });
    const widget = new window.cp.CloudPayments();

    const receipt = {
      Items: [
        {
          label: "Ежемесячная подписка Uniter Plus",
          price: Number(price),
          quantity: 1.00,
          amount: Number(price),
          method: 1,  // fullprepayment
          object: 4, // service
        }
      ],
      "email": email, //e-mail покупателя, если нужно отправить письмо с чеком
      "customerInfo": "", // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
      "isBso": false, //чек является бланком строгой отчётности
      "AgentSign": null, //признак агента, тег ОФД 1057
      "amounts":
        {
          "electronic": Number(price), // Сумма оплаты электронными деньгами
        }
    };

    widget.charge(
      {
        publicId: config.cloudpaymentsPublicId,
        description: 'Ежемесячная подписка Uniter Plus',
        accountId: email,
        invoiceId: invoice,
        amount: Number(price),
        currency: currency,
        skin: 'modern',
        data: { firstName, lastName, cloudPayments: {
          CustomerReceipt: receipt,
          recurrent: { interval: 'Month', period: 1, customerReceipt: receipt } }
        },
      },
      onPaymentSuccess,
      onPaymentFailure,
    );
  }, [currency, email, firstName, id, lastName, onPaymentSuccess, onPaymentFailure, price]);

  return (
    <>
      <Heading level={2}>Ваша подписка</Heading>

      <Margin top={16} as="p">
        {preloader ? (
          <Skeleton />
        ) : (
          <>
            <Indicator color={active ? (cancelled ? 'orange' : 'green') : 'red'} /> {activeText}
          </>
        )}
      </Margin>
      <Margin top={16}>
        {preloader ? <Skeleton /> : active && <A href={settings.uniterPlusTelegramLink}>Доступ в чат</A>}
      </Margin>
      <Margin top={16} as={TermsText}>
        {preloader ? <Skeleton /> : formattedNextBillText}
      </Margin>
      {preloader ? (
        <ExtendedA>
          <Skeleton />
        </ExtendedA>
      ) : (
        active ? (
            cancelled ?
                <A onClick={(e) => {e.preventDefault(); handleOnSubscriptionClick()}} href="#">{`Продлить подписку за ${formatPrice(price, currency, 'ru-RU')} в месяц`}</A> :
                <ExtendedA href="https://my.cloudpayments.ru/ru/unsubscribe">Отменить подписку</ExtendedA>
            ) : null
      )}
    </>
  );
};

export { UniterPlusSubscriptionInfo };
