/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../common/components/Button';
import { renderButtonText, StyledButtonTextWrapper } from './registration-priority-pass';
import { TermsText } from '../../../common/components/TermsText';
import { useTranslation } from 'react-i18next';
import { Margin } from '../../../common/components/Margin';

const TIMER = 6;

const RegistrationPriorityPassModal = ({ onPayment, product, onClose }) => {
  const { t } = useTranslation();
  const [timer, setTimer] = useState(TIMER);
  const { price, sale, currency, original } = product;

  const process = useCallback(() => {
    onClose();
    onPayment();
  }, [onClose, onPayment]);

  useEffect(() => {
    setInterval(() => setTimer((state) => --state), 1000);
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      process();
    }
  }, [process, timer]);

  return (
    <>
      <p>Благодаря инвайт-коду вам доступна скидка!</p>
      <Button onClick={process}>
        <StyledButtonTextWrapper>{renderButtonText(price, original, sale, currency)}</StyledButtonTextWrapper>
      </Button>
      <Margin top={16}>
        <TermsText>
          {t('Переход к оплате произойдет через {{count}} секунды', {
            count: timer,

            defaultValue: 'Переход к оплате произойдет через {{count}} секунд',
            defaultValue_0: 'Переход к оплате произойдет через {{count}} секунду',
            defaultValue_1: 'Переход к оплате произойдет через {{count}} секунды',
            defaultValue_2: 'Переход к оплате произойдет через {{count}} секунд',
          })}
        </TermsText>
      </Margin>
    </>
  );
};

export { RegistrationPriorityPassModal };
