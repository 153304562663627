import React, { useRef } from 'react';
import { Registration } from '../../features/Registration';
import { useStage } from '../../common/hooks/use-stage';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { emailValidator } from '../../common/validators/validators';
import { Room } from '../../features/Room';
import { TransitionListItem } from '../../common/components/TransitionListItem';
import { Margin } from '../../common/components/Margin';
import { Button } from '../../common/components/Button';
import { Login } from '../../features/Login';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_STAGES } from './stages';

const RouteRegistration = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const stageQuery = REGISTRATION_STAGES[queries.stage];
  const emailQuery = queries.email;

  const emailValid = emailValidator.test(emailQuery || '');
  const initialStage = useRef(
    (queries?.room && REGISTRATION_STAGES.room) ||
      (stageQuery === REGISTRATION_STAGES.complete && emailValid
        ? REGISTRATION_STAGES.complete
        : REGISTRATION_STAGES[1]),
  ).current; // Inline `.current` is safe because we write it once

  const [{ stage, direction }, pushStage] = useStage({
    stages: REGISTRATION_STAGES,
    initialStage: initialStage,
  });

  const handleOnAccept = () => pushStage(REGISTRATION_STAGES.auth);
  const handleOnAuth = () => pushStage(REGISTRATION_STAGES.email);
  const handleOnSignUp = () => pushStage(REGISTRATION_STAGES[1]);

  return (
    <>
      <TransitionListItem show={stage === REGISTRATION_STAGES.room} direction={direction}>
        <Room id={queries.room} onAccept={handleOnAccept} />
      </TransitionListItem>

      <TransitionListItem show={stage === REGISTRATION_STAGES.auth} direction={direction}>
        <Margin bottom={32} top={80}>
          <Button type="submit" onClick={handleOnAuth}>
            {t('Войти')}
          </Button>
        </Margin>

        <Button onClick={handleOnSignUp}>{t('Зарегистрироваться')}</Button>
      </TransitionListItem>

      <Login
        registrationCallback={handleOnSignUp} // if user chose login by mistake
        transition={{ stage: stage, stages: REGISTRATION_STAGES, pushStage, direction }}
      />

      <Registration
        transition={{
          stages: REGISTRATION_STAGES,
          stage: stage,
          direction: direction,
          initialStage: initialStage,
          pushStage: pushStage,
        }}
      />
    </>
  );
};

export { RouteRegistration };
