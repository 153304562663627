import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { config } from '../config';
import { SET_LNGS_LOADED } from '../store/action-types';
import { store } from '../store/store';

const translations = { ru: require('./translations/ru.po'), fr: require('./translations/fr.po'), en: require('./translations/en.po') };

const dispatchLngsLoaded = () => store.dispatch({ type: SET_LNGS_LOADED, payload: true });

i18next
  .use(initReactI18next)
  .init({
    resources: { [config.languageCode]: { translation: translations[config.languageCode] } },
    lng: config.languageCode,
    fallbackLng: config.languageCode,
    supportedLngs: [config.languageCode],
    load: 'currentOnly',
    contextSeparator: false,
    keySeparator: false,
    nsSeparator: false,
    debug: config.i18nextDebug,
    react: {
      wait: true,
      useSuspense: false,
    },
  })
  .then(dispatchLngsLoaded);

export { i18next, dispatchLngsLoaded };
