import { apiInstance } from './api-instance';
import { config } from '../../config';

export const getMeetingsList = async () => {
  const response = await apiInstance.get('meeting');

  return response.data;
};

export const postMeetingReview = async (id, data) => {
  const response = await apiInstance.post(`meeting/${id}`, data);

  return response.data;
};

export const getLastMeetingReview = async () => {
  const response = await apiInstance.get(`meeting/review`);

  return response.data;
};

export const getMeetingUnite = async () => {
  const response = await apiInstance.get(`meeting/unite`);

  return response.data;
};

export const getNextMeeting = async () => {
  // TODO figure out how to implement both v1 & v2 api into one handy instance
  const response = await apiInstance({
    method: 'GET',
    baseURL: config.apiBaseURL + 'v2',
    url: 'meeting/next',
  });

  return response.data;
};
