import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import Skeleton from 'react-loading-skeleton';

const StyledContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const RelativeContainer = styled.label`
  position: relative;
`;

const StyledSlider = styled.div`
  display: block;
  width: 44px;
  height: 25px;
  border-radius: 100px;
  position: relative;
  background: ${COLORS.grey1};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.15s ease-out;

  ${(p) => p.checked && `background-color: ${COLORS.blue};`}

  &:before {
    transition: 0.15s ease-out;
    content: '';
    background: ${(p) => (p.disabled ? COLORS.grey2Text : COLORS.white)};
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 100px;
    left: 3px;
    top: 3px;
    ${(p) => p.checked && `left: 22px;`}
  }
`;

const StyledInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;

  &:checked + .switcher__slider {
    background: ${COLORS.blue};
  }

  &:checked + .switcher__slider:before {
    left: 22px;
  }
`;

const Switcher = ({ onChange, checked, label, disabled, loading }) => {
  const handleOnChange = () => onChange((p) => !p);

  if (loading) {
    return <Skeleton wrapper={StyledContainer} />;
  }

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <RelativeContainer>
        <StyledInput onChange={handleOnChange} type="checkbox" disabled={disabled} />
        <StyledSlider disabled={disabled} checked={checked} />
      </RelativeContainer>
    </StyledContainer>
  );
};

Switcher.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Switcher.Container = StyledContainer;

export { Switcher, StyledContainer as SwitcherContainer };
