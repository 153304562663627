import React, { useCallback, useMemo, useState } from 'react';
import { postProfileRecommendation } from '../../../common/api/dashobard-api';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';
import PropTypes from 'prop-types';
import { UserCard } from '../../../common/components/UserCard';

const RecommendationCard = ({
  id,
  pic,
  firstName,
  lastName,
  intro,
  stored,
  hasSubscription,
  onStoredCallback,
  city,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleOnClick = useCallback(async () => {
    setLoading(true);

    await postProfileRecommendation(id);
    await onStoredCallback();

    setLoading(false);
  }, [id, onStoredCallback]);

  const buttonText = useMemo(() => {
    if (stored) {
      return t('Мы будем знакомить вас с похожими людьми');
    } else if (loading) {
      return t('Сохранение…');
    } else {
      return t('Знакомить меня с такими людьми, как {{ firstName }}', { firstName });
    }
  }, [firstName, loading, stored, t]);

  return (
    <UserCard
      intro={intro}
      firstName={firstName}
      lastName={lastName}
      hasSubscription={hasSubscription}
      city={city}
      pic={pic}
    >
      <Button variant="light" onClick={handleOnClick} disabled={stored || loading}>
        {buttonText}
      </Button>
    </UserCard>
  );
};

RecommendationCard.propTypes = {
  id: PropTypes.string,
  pic: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  intro: PropTypes.string,
  stored: PropTypes.bool,
  onStoredCallback: PropTypes.func,
  city: PropTypes.string,
  hasSubscription: PropTypes.bool,
};

export { RecommendationCard };
