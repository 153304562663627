import React from 'react';
import { icons } from './icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Icon = styled(({ size, name, className }) => {
  if (!icons.hasOwnProperty.call(icons, name)) {
    throw new Error(`The icon '${name}' doesn't seems to be`);
  }

  if (typeof size !== 'number') {
    throw new Error('Provide size as number type');
  }

  const rects = icons[name].rects;
  const overrideProps = icons[name].props;

  return (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={size}
      height={size}
      className={className}
      {...overrideProps}
    >
      {rects}
    </svg>
  );
})`
  display: inline-block;
  vertical-align: middle;
`;

Icon.defaultProps = {
  size: 16,
};

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOf(['linkedin', 'facebook', 'emailFill']),
};

export { Icon };
