import React from 'react';
import { COLORS } from '../../constants';
import styled from 'styled-components';

const Tip = styled((props) => <div className={props.className}>{props.children}</div>)`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.grey1Text};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { Tip };
