import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';
import { COLORS } from '../../constants';
import PropTypes from 'prop-types';
import { formatDay } from './helpers';
import { DAYS } from './constants';
import { useTranslation } from 'react-i18next';

const StyledTable = styled.table`
  border-collapse: unset;
`;

const StyledTh = styled.th`
  border-spacing: 0;
  padding: 0;
  font-size: 12px;
  color: ${COLORS.grey1Text};
  font-weight: normal;
`;

const StyledTd = styled.td`
  border-spacing: 0;
  font-size: 12px;
  color: ${COLORS.grey1Text};
  padding: 10px;
  vertical-align: middle;
`;

const Schedule = ({ schedule, setSchedule }) => {
  const { t } = useTranslation();
  const handleOnChange = (value, formattedDate) => {
    const idx = schedule.findIndex((g) => g === formattedDate);

    const removeDay = (schedule) => [...schedule.slice(0, idx), ...schedule.slice(idx + 1)];

    setSchedule((sh) => (value ? [...sh, formattedDate] : removeDay(sh)));
  };

  const dayMapper = (time) => (d) => {
    const formattedDate = formatDay(d, time);
    const wednesdayMorning = d === 3 && time === 9;
    const wednesdayMorningChecked = schedule.find((d) => d === formattedDate);

    if (wednesdayMorning && wednesdayMorningChecked) {
      handleOnChange(false, formattedDate);
    }

    const onChange = (value) => handleOnChange(value, formattedDate);

    return (
      <StyledTd key={d}>
        <Checkbox disabled={wednesdayMorning} onChange={onChange} checked={schedule.includes(formattedDate)} />
      </StyledTd>
    );
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTh />
          <StyledTh>{t('Ср')}</StyledTh>
          <StyledTh>{t('Чт')}</StyledTh>
          <StyledTh>{t('Пт')}</StyledTh>
          <StyledTh>{t('Сб')}</StyledTh>
          <StyledTh>{t('Вс')}</StyledTh>
        </tr>
      </thead>
      <tbody>
        <tr>
          <StyledTd>{t('День')}</StyledTd>
          {DAYS.map(dayMapper(9))}
        </tr>
        <tr>
          <StyledTd>{t('Вечер')}</StyledTd>
          {DAYS.map(dayMapper(18))}
        </tr>
      </tbody>
    </StyledTable>
  );
};

Schedule.propTypes = {
  schedule: PropTypes.array.isRequired,
  setSchedule: PropTypes.func.isRequired,
};

Schedule.Container = StyledTable;

export { Schedule };
