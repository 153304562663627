import { apiInstance } from './api-instance';
import { config } from '../../config';

export const getFaces = async () => {
  const response = await apiInstance({
    method: 'GET',
    baseURL: config.apiBaseURL + 'v2',
    url: 'subscription/faces',
  });

  return response.data;
};

export const getSubscription = async () => {
  const response = await apiInstance({
    method: 'GET',
    baseURL: config.apiBaseURL + 'v2',
    url: 'subscription',
  });

  return response.data;
};
