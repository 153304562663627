import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormGroup } from '../../common/components/FormGroup';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common/components/Button';
import { COLORS } from '../../common/constants';
import { share } from './helpers';
import { SOCIALS } from './constants';
import { icons } from './icons';
import { i18next } from '../../i18n/i18n';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../store/profile/selectors';

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledSocialButton = styled.button`
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 100px;
  width: 32px;
  height: 32px;

  & svg {
    display: block;
    width: 32px;
    height: 32px;

    & #background {
      transition: 0.15s ease-in-out;
      fill: ${(p) => (p.active ? COLORS.blue : COLORS.grey1)};
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
`;

const controlButtonText = (socialNetwork) => {
  switch (socialNetwork) {
    case SOCIALS.twitter:
      return i18next.t('Отправить твит');
    case SOCIALS.facebook:
      return i18next.t('Поделиться в Facebook');
    case SOCIALS.telegram:
      return i18next.t('Отправить в Telegram');
    default:
      return i18next.t('Поделиться');
  }
};

const Share = ({ fromInvites }) => {
  const { t } = useTranslation();
  const { inviteLink } = useSelector(getProfileState);
  const [value, setValue] = useState(t('Uniter познакомил меня с прекрасным человеком. Попробуйте и вы!'));
  const [socialNetwork, setSocialNetwork] = useState(SOCIALS.telegram);

  const handleOnShare = () => share(socialNetwork, { url: inviteLink, text: value });

  return (
    <StyledContainer>
      {!fromInvites && <p>{t('Отправьте друзьям свой инвайт-код, чтобы они тоже могли попробовать!')}</p>}
      <StyledButtonsContainer>
        <StyledSocialButton
          active={socialNetwork === SOCIALS.telegram}
          onClick={() => setSocialNetwork(SOCIALS.telegram)}
          aria-label={'Telegram'}
        >
          {icons.telegram}
        </StyledSocialButton>
        <StyledSocialButton
          active={socialNetwork === SOCIALS.twitter}
          onClick={() => setSocialNetwork(SOCIALS.twitter)}
          aria-label={'Twitter'}
        >
          {icons.twitter}
        </StyledSocialButton>
        <StyledSocialButton
          active={socialNetwork === SOCIALS.facebook}
          onClick={() => setSocialNetwork(SOCIALS.facebook)}
          aria-label={'Facebook'}
        >
          {icons.facebook}
        </StyledSocialButton>
      </StyledButtonsContainer>
      <FormGroup value={value} onChange={setValue} label={t('Комментарий')} as="textarea" />
      <Button onClick={handleOnShare}>{controlButtonText(socialNetwork)}</Button>
    </StyledContainer>
  );
};

Share.propTypes = {
  inviteLink: PropTypes.string,
  fromInvites: PropTypes.bool,
};

export { Share };
