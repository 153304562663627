import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Logo } from '../Logo';

const ANIMATION_DURATION = 200;

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  position: relative;
  animation: ${rotate} 1s infinite linear;
`;

const StyledOval = styled.div`
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI5IDI5QzI5IDI1LjMyMyAyOC4yNzU4IDIxLjY4MiAyNi44Njg2IDE4LjI4NDlDMjUuNDYxNSAxNC44ODc3IDIzLjM5OSAxMS44MDExIDIwLjc5OSA5LjIwMTAxQzE4LjE5ODkgNi42MDA5NyAxNS4xMTIzIDQuNTM4NSAxMS43MTUxIDMuMTMxMzdDOC4zMTgwMiAxLjcyNDI0IDQuNjc3MDEgMSAxIDEiIHN0cm9rZT0iIzI2NjlFMyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
`;

const transition = (state) => {
  //prettier-ignore
  switch (state) {
    case 'entering': return css`opacity: 0;`;
    case 'entered': return css`opacity: 1;`;
    case 'exiting': return css`opacity: 0;`;
    case 'exited': return css`opacity: 0;`;
    default: return;
  }
};

const StyledOverlay = styled.div`
  position: ${(p) => (p.overlay ? 'absolute' : 'fixed')};
  width: 100%;
  height: 100%;
  max-height: 100vh;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${(p) => (p.overlay ? `rgba(256,256,256,0.6)` : '#fff')};
  z-index: ${(p) => (p.overlay ? 19 : 20)};
  transition: opacity ${ANIMATION_DURATION}ms ease-in 0ms;

  ${Logo} {
    margin-bottom: 50px;
  }

  ${StyledSpinner} {
    position: unset;
  }

  ${(p) => transition(p.state)}
`;

const Spinner = ({ size, appOverlay, overlay, show, logo }) => {
  return (
    <Transition in={show} timeout={{ enter: 0, exit: ANIMATION_DURATION }} unmountOnExit mountOnEnter>
      {(state) =>
        appOverlay || overlay ? (
          <StyledOverlay overlay={overlay} state={state}>
            {logo && <Logo />}
            <StyledSpinner size={size}>
              <StyledOval />
            </StyledSpinner>
          </StyledOverlay>
        ) : (
          <StyledSpinner state={state} size={size}>
            <StyledOval />
          </StyledSpinner>
        )
      }
    </Transition>
  );
};

Spinner.defaultProps = {
  size: '56',
  show: undefined,
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['56', '28', '14']),
  appOverlay: PropTypes.bool,
  show: PropTypes.bool,
  logo: PropTypes.bool,
  overlay: PropTypes.bool,
};

export { Spinner };
