import React, { useCallback, useMemo, useState } from 'react';
import { Popup } from '../../../common/components/Popup';
import { Heading } from '../../../common/components/Heading';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';
import { delayedShowController } from '../../../common/controllers/delayed-show-controller';
import { Margin } from '../../../common/components/Margin';
import { Emoji } from '../../../common/components/Emoji';
import { COLORS } from '../../../common/constants';
import styled from 'styled-components';

const RecommendationParagraph = styled((props) => <p className={props.className}>{props.children}</p>)`
  margin-top: 24px;
  margin-bottom: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: ${(p) => p.icon} no-repeat center;
    background-size: 20px 20px;
    left: -64px;
    width: 48px;
    height: 48px;
    background-color: ${COLORS.greyBg};
    border-radius: 100%;

    @media screen and (max-width: ${900}px) {
      position: unset;
      display: block;
      margin-bottom: 8px;
    }
  }
`;

const RecommendationOnboarding = () => {
  const { t } = useTranslation();
  const memoizedShow = useMemo(() => delayedShowController('show-recommendation-onboarding'), []);
  const [show, setShow] = useState(!!memoizedShow);

  const handleOnClick = useCallback(() => {
    setShow(false);
    memoizedShow(false);
  }, [memoizedShow]);

  if (!show) {
    return null;
  }

  return (
    <Popup>
      <Margin top={24} bottom={24}>
        <Heading>
          Привет, это раздел с рекомендациями <Emoji name="waving-hand" />
        </Heading>
      </Margin>
      <RecommendationParagraph icon={Emoji.getEmojiUrl('game-die')}>
        Каждую неделю здесь будут появляться люди, которые потенциально могут быть вам интересны
      </RecommendationParagraph>
      <RecommendationParagraph icon={Emoji.getEmojiUrl('high-voltage')}>
        Выбирайте наиболее релевантные профили, чтобы обучить искусственный интеллект Polina AI подбирать вам лучшие
        юнайты каждую неделю
      </RecommendationParagraph>
      <RecommendationParagraph icon={Emoji.getEmojiUrl('star')}>
        Чем больше вы выберете людей, тем лучше система будет понимать ваши предпочтения для нетворкинга
      </RecommendationParagraph>
      <div className="place-bottom">
        <Button onClick={handleOnClick}>{t('ОК')}</Button>
      </div>
    </Popup>
  );
};

export { RecommendationOnboarding };
