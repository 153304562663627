import { config } from "../../config";

export const makeUsersSearchRequest = (text = '', limit = 20) => ({
  method: 'POST',
  baseURL: config.apiBaseURL + 'v2',
  url: 'dating/search',
  data: { text, limit }
});

export const makeDatingEmailRequest = (subject = '', id = '') => ({
  method: 'POST',
  baseURL: config.apiBaseURL + 'v2',
  url: 'dating',
  data: { subject, user_id: id }
});
