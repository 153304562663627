import React, { useEffect, useState } from 'react';
import { modalController } from '../../controllers/modal-controller';
import { Modal } from '../Modal';

const ModalsWrapper = () => {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    const id = modalController.watch((data) => {
      setModals(data);
    });

    return () => modalController.unwatch(id);
  }, []);

  return modals.map((md) => {
    return (
      <Modal
        key={md.id}
        show={md.show}
        title={md.title}
        onLeave={md.onLeave}
        onExited={md.onExited}
        showCloseButton={md.showCloseButton}
      >
        {md.body}
      </Modal>
    );
  });
};

export { ModalsWrapper };
