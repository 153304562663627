import React from 'react';
import styled from 'styled-components';
import { ROUTES } from '../../../app/routing';
import { Badge } from '../Badge';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants';

export const UPlusIcon = (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="white">
    <path d="M5.5 0V5.38636C5.5 6.20028 4.81392 6.5 4 6.5C3.18608 6.5 2.5 6.20028 2.5 5.38636V0H0V5.59091C0 7.58523 1.74148 9 4 9C6.24148 9 8 7.58523 8 5.59091V0H5.5Z" />
    <rect x="12" y="1" width="2" height="6" />
    <rect x="10" y="3" width="6" height="2" />
  </svg>
);

const PlusBadge = styled(({ className, dontLink, sm }) => {
  return (
    <Badge to={dontLink ? undefined : ROUTES.uniterPlus.path} className={className} sm={sm}>
      {sm ? UPlusIcon : 'Plus'}
    </Badge>
  );
})`
  background: ${COLORS.orangeGradient};
`;

PlusBadge.defaultProps = {
  sm: false,
  dontLink: false,
};

PlusBadge.propTypes = {
  dontLink: PropTypes.bool,
};

export { PlusBadge };
