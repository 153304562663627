import { enUS, fr, ru } from 'date-fns/locale';

const dateFnsPossibleLocales = { ru, fr };

export const resolveDateFnsPossibleLocale = (locale) => {
  return dateFnsPossibleLocales[locale] ?? enUS;
};

/**
 * Returns available meetings number based on subscription setting
 * @param {boolean} hasSubscription
 * @return {number}
 */
const maxMeetingsNumber = (hasSubscription) => (hasSubscription ? 4 : 2);

const unlimitedPriorityTags = (hasSubscription) => hasSubscription;

export const settings = {
  maxMeetingsNumber,
  unlimitedPriorityTags,
  products: {
    priorityPass: 'priority_pass',
    uniterPlus1M: 'sub_1m',
  },
  uniterPlusTelegramLink: 'https://t.me/joinchat/Ak9UFkhOpIEkKQaWFJbQ7g',
};
