import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const routesShouldRedirect = ['/networking/settings'];

export const useRoutingController = () => {
  const location = useLocation();
  const history = useHistory();
  const isAuthorized = useSelector((state) => state.common.isAuthorized);
  const [initialRoute] = useState(location.pathname);

  useEffect(() => {
    if (isAuthorized && routesShouldRedirect.includes(initialRoute)) {
      history.push(initialRoute);
    }
  }, [isAuthorized]); //eslint-disable-line
};
