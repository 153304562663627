import { apiInstance } from './api-instance';

export const getCurrentMeetingSettings = async () => {
  const { data } = await apiInstance.get('/meeting/settings');
  return data;
};

export const requestMeeting = async (settings) => {
  const { data } = await apiInstance.post('/meeting/settings', settings);
  return data;
};

export const getSettingsEnums = async () => {
  const { data } = await apiInstance.get('/meeting/settings/enums');
  return data;
};

export const postMeetingUnite = async () => {
  const { data } = await apiInstance.post(`/meeting/unite`);
  return data;
};
