import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { authenticationCompletion } from '../../common/api/auth-api';
import { writeToken } from '../../common/helpers/ls-token';
import { setAuthHeaders } from '../../common/api/api-instance';
import { SET_AUTHORIZED } from '../../store/action-types';
import { useDispatch } from 'react-redux';
import { actualizeProfile } from "../../common/helpers/actualize-profile";

const AuthFromEmail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queries = location.search;
  const { email, code } = queryString.parse(queries);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { token, code: responseCode } = await authenticationCompletion({ email, code });

        if (responseCode === 200) {
          writeToken(token);
          setAuthHeaders(token);
          await actualizeProfile(dispatch);
          dispatch({ type: SET_AUTHORIZED, payload: true });
        } else {
          setFailed(true);
        }
      } catch (e) {
        setFailed(true);
        throw Error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, code]);

  return failed ? <Redirect to="login" /> : null;
};

export { AuthFromEmail };
