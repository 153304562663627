import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { COLORS } from '../../constants';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  ${Button.Container} {
    max-width: max-content;
    padding: 12px;
    line-height: 0;

    &:disabled {
      & svg {
        stroke: ${COLORS.grey2Text} !important;
      }
    }
  }
`;

//extra code from FormGroup todo clean
const StyledInput = styled.input`
  margin-left: 16px;
  margin-right: 16px;
  font-size: 16px;
  font-weight: 400;
  padding: 16px;
  flex: 1;
  background: ${COLORS.greyBg};
  border: none;
  max-width: 100px;
  text-align: center;
  border-radius: 4px;
  border-bottom: 2px solid ${COLORS.greyBg};
  transition: 0.15s;

  &:focus {
    border-radius: 4px 4px 0 0;
    border-color: ${(p) => (p.theme.validation ? COLORS.red : COLORS.blue)};
  }
`;

const NumberInput = ({ value, max, min, onChange, label, inputDisabled }) => {
  const handleOnClick = (_value) => () => onChange(_value + value);
  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledContainer>
        <Button onClick={handleOnClick(-1)} disabled={value <= min}>
          <svg width="12" height="12" viewBox="0 0 12 12" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6H11" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </Button>
        <StyledInput
          value={value}
          onChange={handleOnChange}
          disabled={inputDisabled}
          min={min}
          max={max}
          pattern="[0-9]*"
          inputmode="numeric"
        />
        <Button onClick={handleOnClick(1)} disabled={value >= max}>
          <svg width="12" height="12" viewBox="0 0 12 12" stroke="white" fill="none">
            <path d="M1 6H11" strokeWidth="2" strokeLinecap="round" />
            <path d="M6 1L6 11" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </Button>
      </StyledContainer>
    </>
  );
};

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  label: PropTypes.string,
  inputDisabled: PropTypes.bool,
};

NumberInput.defaultProps = {
  inputDisabled: false,
  max: null,
  min: null,
};

export { NumberInput };
