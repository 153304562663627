import { SOCIALS } from './constants';
import { objectToGetParams } from '../../common/helpers/object-to-get-params';

const NEW_WINDOW_HEIGHT = 400;
const NEW_WINDOW_WIDTH = 550;

/**
 * Window.open() method's config
 * @type {{centerscreen: string, chrome: string, resizable: string, windowPosition: string, toolbar: string, menubar: string, top: number, scrollbars: string, left: number, width: number, directories: string, location: string, height: number, status: string}}
 */
const CONFIG = {
  top: window.outerHeight / 2 - NEW_WINDOW_HEIGHT / 2,
  left: window.outerWidth / 2 - NEW_WINDOW_WIDTH / 2,
  height: NEW_WINDOW_HEIGHT,
  width: NEW_WINDOW_WIDTH,
  windowPosition: 'windowCenter',
  location: 'no',
  toolbar: 'no',
  status: 'no',
  directories: 'no',
  menubar: 'no',
  scrollbars: 'yes',
  resizable: 'no',
  centerscreen: 'yes',
  chrome: 'yes',
};

/**
 *
 * @param url — url to open
 * @param extraOptions — additional params for Window.open method
 */
const openShareDialog = (url, extraOptions = {}) => {
  const config = {
    ...CONFIG,
    ...extraOptions,
  };

  window.open(
    url,
    '',
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', '),
  );
};

/**
 *
 * @param {SOCIALS|String} socialNetwork — Twitter, Facebook or Telegram
 * @param {String} url - invite link
 * @param {String} text - user text
 */
const share = (socialNetwork, { url, text }) => {
  switch (socialNetwork) {
    case SOCIALS.twitter:
      openShareDialog(
        'https://twitter.com/share' +
          objectToGetParams({
            url,
            text,
          }),
      );
      break;
    case SOCIALS.facebook:
      openShareDialog(
        'https://www.facebook.com/sharer/sharer.php' +
          objectToGetParams({
            u: url,
            quote: text,
          }),
      );
      break;
    case SOCIALS.telegram:
      openShareDialog(
        'https://t.me/share' +
          objectToGetParams({
            url,
            text,
          }),
      );
      break;
    default:
      return;
  }
};

export { openShareDialog, share };
