import React, { useEffect, useState } from 'react';
import { Margin } from '../../../common/components/Margin';
import { Heading } from '../../../common/components/Heading';
import { getCurrentInvitationList } from '../../../common/api/invites-api';
import { useTranslation } from 'react-i18next';
import { UserCard } from '../../../common/components/UserCard';

const InvitationsList = () => {
  const { t } = useTranslation();
  const [invitationList, setInvitationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const invitationsLength = invitationList.filter((it) => it.accepted).length;

  const fetchInvitations = async () => {
    try {
      const { invitations } = await getCurrentInvitationList();
      setInvitationList(invitations);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.title = t('Пригласить друга');

    fetchInvitations();
  }, []); //eslint-disable-line

  if (invitationsLength === 0) {
    return null;
  }

  return (
    <>
      <Heading level={2}>{t('Вы пригласили')}</Heading>

      {loading && (
        <>
          <UserCard.Skeleton />
          <UserCard.Skeleton />
          <UserCard.Skeleton />
        </>
      )}

      {invitationsLength > 0 ? (
        invitationList.map((it, idx) => {
          if (!it.accepted) {
            return null;
          }

          return (
            <Margin key={it?.email} top={idx ? 16 : 24}>
              <UserCard
                firstName={it.first_name}
                lastName={it.last_name}
                hasSubscription={it?.has_subscription}
                intro={it.intro}
                pic={it.pic}
                links={[{ href: 'mailto:' + it.email, icon: 'emailFill' }]}
              />
            </Margin>
          );
        })
      ) : (
        <Margin top={16}>{t('Вы пока еще никого не пригласили')}</Margin>
      )}
    </>
  );
};

export { InvitationsList };
