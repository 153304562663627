import { SET_CURRENT_PROFILE, REDUCE_DATING_COUNT } from './action-types';

const defaultState = {};

//TODO: отрефакторить редюсер — добавить actions для более гибкого управления (связано с unauthorize.js)

export const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_PROFILE:
      return {
        ...state,
        ...payload,
        social_networks: payload?.social_networks || {},
      };
    case REDUCE_DATING_COUNT:
      return {
        ...state,
        ...(isNaN(parseInt(state.dating_count)) ? {} : {
          dating_count: state.dating_count - 1 <= 0 ? 0 : state.dating_count - 1
        })
      };
    default:
      return state;
  }
};
