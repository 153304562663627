import React from 'react';
import { Header } from '../../common/components/Header';
import { PlusBadge } from '../../common/components/PlusBadge';
import { MOBILE_BREAKPOINT } from '../../common/constants';
import { UniterPlusPrivileges } from './components/uniter-plus-privileges';
import { Container } from '../../common/components/Container';
import { UniterPlusUsers } from './components/uniter-plus-users';
import styled from 'styled-components';
import { UniterPlusSubscription } from './components/uniter-plus-subscription';
import { useSelector } from 'react-redux';
import { UniterPlusSubscriptionInfo } from './components/uniter-plus-subscription-info';
import { Margin } from '../../common/components/Margin';
import { ConfettiProvider } from '../../common/contexts/confetti-context/confetti-provider';
import { TermsText } from '../../common/components/TermsText';
import { Trans, useTranslation } from 'react-i18next';
import { getHasSubscription } from '../../store/selectors';

const ExtendedContainer = styled(Container)`
  width: 100%;
  max-width: 100%;
  padding-left: 256px;
  padding-right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    & > * {
      max-width: 400px;
      width: 100%;
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    & > *:not(${Header.Container}) {
      max-width: 400px;
      width: 100%;
    }

    align-items: flex-start;
  }
`;

const UniterPlus = () => {
  const { t } = useTranslation();
  const hasSubscription = useSelector(getHasSubscription);
  const ContainerConditionalComponent = hasSubscription ? Container : ExtendedContainer;

  return (
    <ConfettiProvider>
      <ContainerConditionalComponent>
        <Header>
          Платный аккаунт
          <PlusBadge sm={window.innerWidth < 340} dontLink />
        </Header>
        {hasSubscription ? (
          <UniterPlusSubscriptionInfo />
        ) : (
          <p>
            Открывает доступ ко всем возможностям Uniter. Покупая этот аккаунт, вы инвестируете в улучшение качества
            людей, которые вас окружают.
          </p>
        )}

        <UniterPlusPrivileges />
        <UniterPlusSubscription />
        {!hasSubscription && <UniterPlusUsers />}

        {!hasSubscription && (
          <Margin top={64}>
            <TermsText>
              <Trans t={t}>
                Нажимая «подписаться», вы соглашаетесь с <TermsText.A href="/offer">условиями оплаты</TermsText.A>. По
                любым вопросам обращайтесь в нашу службу поддержки:&nbsp;
                <TermsText.A href="mailto:hi@uniter.ai">hi@uniter.ai</TermsText.A>
              </Trans>
            </TermsText>
          </Margin>
        )}
      </ContainerConditionalComponent>
    </ConfettiProvider>
  );
};

export { UniterPlus };
