import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { COLORS, MOBILE_BREAKPOINT } from '../../constants';
import { Heading } from '../Heading';
import { useDispatch } from 'react-redux';
import { TOGGLE_SIDEBAR } from '../../../store/action-types';

const StyledMenuButton = styled.div`
  width: 18px;
  height: 12px;
  display: none;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  left: 16px;
  top: 22px;

  > * {
    background-color: ${COLORS.grey1};
    width: 100%;
    height: 2px;
    display: block;
  }

  transition: margin 0.15s;
`;

const pulled = css`
  position: fixed !important;
  padding: 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14);
  height: 56px !important;

  background-color: ${COLORS.white};
  transition: all 0.15s ease 0s;

  ${Heading.Container} {
    margin: 0 0 0 37px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  ${StyledMenuButton} {
    margin: 0;
  }
`;

const StyledContainer = styled.div`
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  margin-bottom: 32px;

  h1 {
    transition: 0.2s ease 0s;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    padding: 16px 16px 0;
    margin-bottom: 0;

    ${StyledMenuButton} {
      display: flex;
    }

    h1 {
      margin-top: 48px;
    }

    ${(p) => p.pulled && pulled}
  }
`;

const StyledGap = styled.div`
  height: 130px;
  width: 100%;
  display: none;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
  }
`;

// eslint-disable-next-line react/prop-types
const Header = ({ children }) => {
  const dispatch = useDispatch();
  const toggleSidebar = () => dispatch({ type: TOGGLE_SIDEBAR });
  const [pulled, setPulled] = useState(false);

  const onScroll = () => {
    if (window.pageYOffset >= 20 && window.innerWidth <= MOBILE_BREAKPOINT) {
      setPulled(true);
    } else {
      setPulled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <StyledGap />
      <StyledContainer pulled={pulled}>
        <StyledMenuButton onClick={toggleSidebar}>
          <div />
          <div />
          <div />
        </StyledMenuButton>
        <Heading>{children}</Heading>
      </StyledContainer>
    </>
  );
};

Header.Container = StyledContainer;

export { Header };
