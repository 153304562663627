import React, { useCallback, useMemo, useState } from 'react';
import { Heading } from '../../../common/components/Heading';
import { RelativeContainer } from '../../../common/components/RelativeContainer';
import { FormGroup } from '../../../common/components/FormGroup';
import { emailValidator } from '../../../common/validators/validators';
import { Button } from '../../../common/components/Button';
import { Spinner } from '../../../common/components/Spinner';
import { postAnInvitation } from '../../../common/api/invites-api';
import { useTranslation } from 'react-i18next';
import { i18next } from '../../../i18n/i18n';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../../common/constants';
import ArrowRight from "../../../common/components/Icons/arrow-right";

const CheckIcon = (
  <svg
    viewBox="0 0 24 24"
    width="16"
    height="16"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

const messageController = (message) => {
  switch (message) {
    case 'invitations-sent':
      return i18next.t('Приглашение успешно отправлено');
    default:
      return null;
  }
};

const StyledIntegratedContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${FormGroup.Container} {
    flex: 1;
  }

  ${Button.Container} {
    margin-left: 16px;
  }
`;

const StyledRegularContainer = styled.div`
  margin-top: 16px;

  ${FormGroup.Container} {
    margin-bottom: 8px;
  }
`;

const StyledMessage = styled.div`
  margin-top: 8px;
  color: ${COLORS.green};
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    stroke: ${COLORS.green};
  }
`;

const InviteByEmail = ({ integrated }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOnEmailChange = useCallback((value) => setEmail(value.trim().toLowerCase()), []);

  const handleOnInvite = useCallback(
    async (e) => {
      e.preventDefault();
      if (emailValidator.test(email)) {
        setValidation(false);
        setLoading(true);
        try {
          const { message } = await postAnInvitation({ emails: [email] });
          setMessage(messageController(message));
          setTimeout(() => {
            setMessage(null);
          }, 5000);
          setEmail('');
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      } else {
        setValidation(true);
      }
    },
    [email],
  );

  const Container = useMemo(() => (integrated ? StyledIntegratedContainer : StyledRegularContainer), [integrated]);

  return (
    <RelativeContainer as="form">
      {!integrated && <Heading level={2}>{t('Пригласите по email')}</Heading>}
      <Container>
        <FormGroup
          value={email}
          validation={validation && !emailValidator.test(email)}
          onChange={handleOnEmailChange}
          placeholder="example@gmail.com"
        />
        <Button
          disabled={!emailValidator.test(email) || loading}
          onClick={handleOnInvite}
          variant={integrated ? 'square' : undefined}
          type="submit"
        >
          {integrated ? <ArrowRight/> : t('Отправить приглашение')}
        </Button>
      </Container>
      {message && (
        <StyledMessage>
          {CheckIcon}
          {message}
        </StyledMessage>
      )}
      <Spinner show={loading} overlay />
    </RelativeContainer>
  );
};

InviteByEmail.propTypes = {
  integrated: PropTypes.bool,
};

InviteByEmail.defaultProps = {
  integrated: false,
};

export { InviteByEmail };
