import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { COLORS, MOBILE_BREAKPOINT } from '../../constants';
import Skeleton from 'react-loading-skeleton';

const styles = {
  1: css`
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 32px;
    }
  `,
  2: css`
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
  `,
};

const StyledHeading = styled.h2`
  font-family: Roboto Condensed, sans-serif;
  font-style: normal;
  color: ${COLORS.black};
  width: auto;
  vertical-align: middle;
  & > * {
    vertical-align: middle;
  }

  ${(p) => styles[p.level] ?? styles[1]}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(p) =>
    p.hasRowComponent &&
    css`
      & > * {
        margin-right: 16px;
      }
    `}
`;

const Heading = ({ level, children, loading, rowComponents }) => {
  return (
    <StyledContainer hasRowComponent={!!rowComponents}>
      <StyledHeading as={'h' + level} level={level}>
        {loading ? <Skeleton /> : children}
      </StyledHeading>
      {rowComponents}
    </StyledContainer>
  );
};

Heading.defaultProps = {
  rowComponents: null,
  level: 1,
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2]),
  loading: PropTypes.bool,
  children: PropTypes.any,
  rowComponents: PropTypes.element,
};

Heading.Container = StyledHeading;

export { Heading };
