import { emailValidator } from '../../../common/validators/validators';

export const testEmail = (email) => (emailValidator.test(email) && email) || '';

export const isFormValid = (form) =>
  form.email &&
  form.firstName &&
  form.lastName &&
  form.intro &&
  form.professionalInterests &&
  form.city &&
  form.linkedInValid &&
  form.facebookValid;
