import { format, parseISO } from 'date-fns';

export const formatDate = (date) => {
  if (typeof date !== 'string') {
    throw new Error(`Please, provide a valid ISO date-time string. You provided: ${typeof date}, ${date}`);
  }

  const parsedDate = parseISO(date.split('.')[0]);

  return format(parsedDate, 'dd.MM.yyyy');
};
