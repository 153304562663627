import React from 'react';
import { Share } from '../../Share';
import { Margin } from '../../../common/components/Margin';
import { Heading } from '../../../common/components/Heading';
import { useTranslation } from 'react-i18next';

const InviteShareLink = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading level={2}>{t('Или расскажите в соц-сетях')}</Heading>
      <Margin top={16}>
        <Share fromInvites />
      </Margin>
    </>
  );
};

export { InviteShareLink };
