import React, { useCallback, useEffect } from 'react';
import { Container } from '../../common/components/Container';
import { Header } from '../../common/components/Header';
import { useTranslation } from 'react-i18next';
import { UserCard as CommonUserCard } from '../../common/components/UserCard';
import { UserCard } from './components/user-card';
import { useRemoteResource } from '../../common/hooks/use-remote-resource';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileState } from '../../store/profile/selectors';
import { UserSearch } from './components/user-search';
import { makeUsersSearchRequest } from '../../common/api/dating-api';
import { reduceDatingCount } from '../../store/profile/actions';
import { Balance } from '../../common/components/Balance';

const UsersSearch = () => {
  const { t } = useTranslation();
  const profile = useSelector(getProfileState);
  const dispatch = useDispatch();
  const [searchResource, fetchSearchResource] = useRemoteResource();

  const searchUnitesCallback = useCallback(
    text => {
      fetchSearchResource(makeUsersSearchRequest(text));
    },
    [fetchSearchResource]
  );

  const onInvitationSentCallback = useCallback(() => {
    dispatch(reduceDatingCount());
  }, [dispatch]);

  useEffect(() => {
    document.title = t('Uniter — Поиск');
  }, [t]);

  return (
    <Container position="relative">
      {profile.has_subscription && <Balance
        transform="translateY(-50%)"
        right={["16px", "16px", "66px"]}
        top="32px"
        position="absolute"
        amount={profile.dating_count}/> }

      <Header>{t('Режим бога')}</Header>

      <UserSearch
        onSearch={searchUnitesCallback}
        searchResource={searchResource}
      />

      {searchResource.case({
        _: () => null,
        Loading: () => (
          <>
            <CommonUserCard.Skeleton />
            <CommonUserCard.Skeleton />
            <CommonUserCard.Skeleton />
          </>
        ),
        Loaded: ({ users = [] }) =>
          users.length
            ? users.map(user => (
                <UserCard
                  key={user.id}
                  id={user.id}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  intro={user.intro}
                  pic={user.pic}
                  availableDatingCount={profile.dating_count}
                  allowToSendInvite={profile.has_subscription}
                  onInvitationSent={onInvitationSentCallback}
                  hasSubscription={user.has_subscription}
                  city={user.city}
                  alreadySend={user.already_send}
                />
              ))
            : t(
                'К сожалению, мы не смогли найти пользователей по вашему запросу'
              )
      })}
    </Container>
  );
};

export { UsersSearch };
