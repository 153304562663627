import React from 'react';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Margin } from '../../common/components/Margin';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../store/profile/selectors';
import { Indicator } from '../../common/components/Indicator';
import { A } from '../../common/components/A';
import { config } from '../../config';
import styled from 'styled-components';

export const TelegramIcon = (
  <svg
    style={{ marginRight: 8 }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="telegram">
      <path
        id="icon"
        d="M4.09992 11.5964C8.39487 9.76182 11.2589 8.55233 12.6919 7.96797C16.7834 6.2995 17.6335 6.00967 18.1877 6.0001C18.3095 5.998 18.5821 6.02761 18.7586 6.16804C18.9076 6.28661 18.9486 6.44679 18.9683 6.55921C18.9879 6.67163 19.0123 6.92773 18.9929 7.12784C18.7712 9.41185 17.8118 14.9545 17.3237 17.5127C17.1172 18.5951 16.7105 18.958 16.3169 18.9936C15.4613 19.0707 14.8116 18.4392 13.9829 17.9067C12.6862 17.0733 11.9537 16.5545 10.695 15.7413C9.24043 14.8015 10.1834 14.285 11.0124 13.4409C11.2293 13.22 14.999 9.8583 15.0719 9.55334C15.0811 9.5152 15.0895 9.37304 15.0034 9.29797C14.9172 9.2229 14.7901 9.24857 14.6983 9.26899C14.5683 9.29792 12.4968 10.6403 8.48389 13.2961C7.89591 13.6919 7.36333 13.8848 6.88616 13.8747C6.36012 13.8635 5.34822 13.5831 4.59598 13.3433C3.67333 13.0493 2.94002 12.8938 3.00388 12.3944C3.03714 12.1343 3.40248 11.8683 4.09992 11.5964Z"
        fill="white"
      />
    </g>
  </svg>
);

const StyledIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  ${Indicator} {
    margin-right: 8px;
  }
`;

const TelegramIntegration = () => {
  const { t } = useTranslation();
  const { integrations } = useSelector(getProfileState);

  const linked = integrations?.telegram?.linked;
  const url = integrations?.telegram?.url;

  if (!url || !config.features.telegramIntegration) {
    return null;
  }

  return (
    <>
      <Heading level={2}>{t('Интеграция с Telegram')}</Heading>
      {!linked && (
        <Margin top={12}>
          <p>{t('Позволит быстро входить в личный кабинет и получать уведомления привычным способом')}</p>
        </Margin>
      )}
      <StyledIndicatorContainer>
        <Indicator color={linked ? 'green' : 'red'} />
        {linked ? t('Вы подключили Telegram') : t('Вы еще не подключили Telegram')}
      </StyledIndicatorContainer>
      {!linked && (
        <Margin top={24}>
          <A href={url} target="_blank">
            <Button>
              {TelegramIcon}
              {linked ? t('Отключить') : t('Подключить')}
            </Button>
          </A>
        </Margin>
      )}
    </>
  );
};

export { TelegramIntegration };
