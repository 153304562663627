/* eslint-disable react/prop-types */
import { components } from 'react-select';
import React from 'react';
import styled from 'styled-components';

const defaultPic =
  "data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23E4ECFB'/%3E%3Crect x='10' y='6' width='12' height='20' rx='2' stroke='%232669E3' stroke-width='2'/%3E%3Ccircle cx='19' cy='18' r='1' fill='%232669E3'/%3E%3C/svg%3E%0A";

const StyledPic = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 16px;
  background: url("${(p) => p.url}") center no-repeat;
  background-size: cover;
`;

export const OptionWithPic = (props) => (
  <components.Option {...props}>
    <StyledPic url={props.data?.pic || defaultPic} />
    {props.children}
  </components.Option>
);
