import { APPROVED, GHOST, PENDING, REGISTERED } from '../constants';
import { i18next } from '../../i18n/i18n';

/**
 *
 * @param {Object} params
 * @param {REGISTERED|PENDING|APPROVED|GHOST|String} params.status user status
 * @param {Boolean} params.united indicates are user 'are you in'
 * @param {Array<String>}params.meetings meeting dates
 * @returns {{link: string, text: string}|null|{text: string}}
 */
export const messageStateController = ({ status, united, meetings }) => {
  const hasMeetings = meetings?.length;

  try {
    switch (status) {
      case REGISTERED:
        return { text: i18next.t('Модератор проверяет ваш профиль') };
      case PENDING:
        return { text: i18next.t('Вам нужно обновить ваш профиль') };
      case GHOST:
        //  return { text: i18next.t('Ваш аккаунт заморожен по причине игнорирования юнайтов') };
        return { text: '' };
      case APPROVED:
        if (hasMeetings === 1) {
          return {
            text: i18next.t(`Ваш юнайт — {{firstName}} {{lastName}}`, {
              firstName: meetings[0].partner.first_name,
              lastName: meetings[0].partner.last_name,
            }),
            link: '/unites',
          };
        } else if (hasMeetings > 1) {
          return {
            text: i18next.t('Алгоритм подобрал вам новые юнайты'),
            link: '/unites',
          };
        }

        switch (united) {
          case true:
            return { text: i18next.t('Алгоритм подбирает вам новый юнайт') };
          case false:
            return { text: i18next.t('Подтвердите свое участие на этой неделе'), link: '/networking/settings' };

          default:
            return null;
        }
      default:
        return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};
