import wavingHand from './assets/waving-hand.png';
import gameDie from './assets/game-die.png';
import highVoltage from './assets/high-voltage.png';
import star from './assets/star.png';
import parachute from './assets/parachute.png';

export const EMOJI_ASSETS = {
  'high-voltage': highVoltage,
  'waving-hand': wavingHand,
  'game-die': gameDie,
  star: star,
  parachute: parachute,
};
