import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ isAuthorized, isAccessible = true, component: Component, ...rest }) => {
  if (isAuthorized === 'pending') {
    return null;
  }

  return <Route {...rest} render={(props) => {
    if (!isAuthorized) {
      return <Redirect to="/login"/>;
    }
    if (!isAccessible) {
      return <Redirect to="/profile"/>;
    }
    return <Component {...props} />;
  }}/>
};

PrivateRoute.defaultProps = {
  sidebar: true,
};

PrivateRoute.propTypes = {
  sidebar: PropTypes.bool,
  isAccessible: PropTypes.bool,
  component: PropTypes.func,
};

export { PrivateRoute };
