import { createGlobalStyle, css } from 'styled-components';
import { COLORS } from '../common/constants';

const reset = css`
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */

  /*
    prettier-ignore
   */
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, 
  h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, 
  del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, 
  sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, 
  label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, 
  aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary, time, mark, audio, video, input, 
  textarea, button {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  textarea,
  input {
    vertical-align: top;
  }

  html,
  button,
  input,
  select,
  textarea {
    color: rgb(34, 34, 34);
  }

  button,
  input {
    line-height: normal;
  }
`;

const customStyles = css`
  /* Own resetting and styling */

  :focus {
    outline: 0;
  }

  button {
    border: none;
    font: inherit;

    &:hover {
      cursor: pointer;
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    font-family: Roboto, 'sans-serif';
    color: ${COLORS.black};
    font-size: 16px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
  }

  p {
    margin: 0 0 16px;
  }

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.grey2};
    appearance: none;
    border: none;
  }

  .r-s--is-disabled {
    * {
      cursor: not-allowed !important;
    }
  }

  /*
    prettier-ignore
   */
  div, p, span, small, strong, b, s, i, h1, h2, h3, h4, h5, h6 {
    color: ${COLORS.black};
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${customStyles};
`;
