import { apiInstance } from './api-instance';

/**
 * Authentication email submitting
 * @param data
 * @returns {Promise<{code: Number, message: String|undefined, status: String|undefined}>}
 */
export const authenticationRequest = async (data) => {
  const response = await apiInstance.post('/auth', data);
  return response.data;
};

/**
 * Authentication code submitting
 * @param data
 * @returns {Promise<{code: Number, message: String|undefined, token: String}>}
 */
export const authenticationCompletion = async (data) => {
  const response = await apiInstance.post('/auth/complete', data);
  return response.data;
};

/**
 * Token validation
 * @returns {Promise<{valid: Boolean, code: Number}>}
 */
export const validateToken = async () => {
  const response = await apiInstance.get('/auth');
  return response.data;
};

/**
 * Token invalidation
 */
export const invalidateToken = async () => {
  const response = apiInstance.delete('/auth');
  return response.data;
};
