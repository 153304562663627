import { apiInstance } from './api-instance';

export const getPaymentProducts = async (params) => {
  const response = await apiInstance.get('payment/products', { params });

  return response?.data;
};

export const postPaymentInvoice = async (data) => {
  const response = await apiInstance.post('payment/invoice', data);

  return response.data;
};

export const getPaymentInvoice = async (invoice_id, params) => {
  const { data } = await apiInstance.get(`payment/invoice/${invoice_id}`, { params });

  return data;
};
