import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const active = css`
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAzMCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjA0ODkgMS45MjcwNUMxNC4zNDgzIDEuMDA1NzQgMTUuNjUxNyAxLjAwNTc0IDE1Ljk1MTEgMS45MjcwNUwxOC4zNjc3IDkuMzY0NzVDMTguNTAxNiA5Ljc3Njc3IDE4Ljg4NTUgMTAuMDU1NyAxOS4zMTg4IDEwLjA1NTdIMjcuMTM5MkMyOC4xMDc5IDEwLjA1NTcgMjguNTEwNyAxMS4yOTUzIDI3LjcyNyAxMS44NjQ3TDIxLjQwMDEgMTYuNDYxNUMyMS4wNDk2IDE2LjcxNjEgMjAuOTAzIDE3LjE2NzUgMjEuMDM2OSAxNy41Nzk1TDIzLjQ1MzUgMjUuMDE3MkMyMy43NTI5IDI1LjkzODUgMjIuNjk4NCAyNi43MDQ3IDIxLjkxNDcgMjYuMTM1M0wxNS41ODc4IDIxLjUzODVDMTUuMjM3MyAyMS4yODM5IDE0Ljc2MjcgMjEuMjgzOSAxNC40MTIyIDIxLjUzODVMOC4wODUzMyAyNi4xMzUzQzcuMzAxNjIgMjYuNzA0NyA2LjI0NzE0IDI1LjkzODUgNi41NDY0OSAyNS4wMTcyTDguOTYzMTUgMTcuNTc5NUM5LjA5NzAyIDE3LjE2NzUgOC45NTAzNiAxNi43MTYxIDguNTk5ODcgMTYuNDYxNUwyLjI3Mjk5IDExLjg2NDdDMS40ODkyOCAxMS4yOTUzIDEuODkyMDYgMTAuMDU1NyAyLjg2MDc4IDEwLjA1NTdIMTAuNjgxMkMxMS4xMTQ1IDEwLjA1NTcgMTEuNDk4NCA5Ljc3Njc3IDExLjYzMjMgOS4zNjQ3NUwxNC4wNDg5IDEuOTI3MDVaIiBmaWxsPSIjRjJDMjE2IiBzdHJva2U9IiNGMkMyMTYiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
`;

const Star = styled.button`
  background-color: unset;
  box-sizing: content-box;
  width: 56px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: ${(p) => (p.stateless ? 'default' : 'pointer')};
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAzMCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjA0ODkgMS45MjcwNUMxNC4zNDgzIDEuMDA1NzQgMTUuNjUxNyAxLjAwNTc0IDE1Ljk1MTEgMS45MjcwNUwxOC4zNjc3IDkuMzY0NzVDMTguNTAxNiA5Ljc3Njc3IDE4Ljg4NTUgMTAuMDU1NyAxOS4zMTg4IDEwLjA1NTdIMjcuMTM5MkMyOC4xMDc5IDEwLjA1NTcgMjguNTEwNyAxMS4yOTUzIDI3LjcyNyAxMS44NjQ3TDIxLjQwMDEgMTYuNDYxNUMyMS4wNDk2IDE2LjcxNjEgMjAuOTAzIDE3LjE2NzUgMjEuMDM2OSAxNy41Nzk1TDIzLjQ1MzUgMjUuMDE3MkMyMy43NTI5IDI1LjkzODUgMjIuNjk4NCAyNi43MDQ3IDIxLjkxNDcgMjYuMTM1M0wxNS41ODc4IDIxLjUzODVDMTUuMjM3MyAyMS4yODM5IDE0Ljc2MjcgMjEuMjgzOSAxNC40MTIyIDIxLjUzODVMOC4wODUzMyAyNi4xMzUzQzcuMzAxNjIgMjYuNzA0NyA2LjI0NzE0IDI1LjkzODUgNi41NDY0OSAyNS4wMTcyTDguOTYzMTUgMTcuNTc5NUM5LjA5NzAyIDE3LjE2NzUgOC45NTAzNiAxNi43MTYxIDguNTk5ODcgMTYuNDYxNUwyLjI3Mjk5IDExLjg2NDdDMS40ODkyOCAxMS4yOTUzIDEuODkyMDYgMTAuMDU1NyAyLjg2MDc4IDEwLjA1NTdIMTAuNjgxMkMxMS4xMTQ1IDEwLjA1NTcgMTEuNDk4NCA5Ljc3Njc3IDExLjYzMjMgOS4zNjQ3NEwxNC4wNDg5IDEuOTI3MDVaIiBzdHJva2U9IiM5OEExQUIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
  ${(p) => p.active && active}
`;

const StarRating = ({ rating, setRating, stateless }) => {
  const stars = [1, 2, 3, 4, 5];
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleSetHoveredRating = (value) => (stateless ? null : setHoveredRating(value));
  const handleSetRating = (value) => (stateless ? null : setRating(value));

  return (
    <StyledContainer onMouseLeave={() => handleSetHoveredRating(0)}>
      {stars.map((s, idx) => {
        const handleOnMouseOver = () => handleSetHoveredRating(s);
        const handleOnClick = () => handleSetRating(s);

        return (
          <Star
            stateless={stateless}
            key={idx}
            active={hoveredRating ? s <= hoveredRating : s <= rating}
            onMouseOver={handleOnMouseOver}
            onClick={handleOnClick}
          />
        );
      })}
    </StyledContainer>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number,
  setRating: PropTypes.func,
  stateless: PropTypes.bool,
};

StarRating.Container = StyledContainer;

export { StarRating, StyledContainer as StarRatingContainer };
