import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../constants';

export const InfoIcon = styled((props) => {
  return (
    <svg className={props.className} width="18" height="18" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" stroke="#98A1AB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="11" y="10" width="2" height="7" rx="1" fill="#98A1AB" />
      <circle cx="12" cy="7" r="1" fill="#98A1AB" />
    </svg>
  );
})`
  margin-right: 8px;
`;

const StyledInfoHint = styled.div`
  color: ${COLORS.grey1};
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  width: auto;

  & > {
    align-self: flex-start;
  }

  & > span {
    line-height: 24px;
    color: inherit;
  }

  & > svg {
    margin-top: 3px;
    margin-bottom: 3px;
    flex-shrink: 0;
  }
`;

const InfoHint = ({ text, forwardRef, showIcon }) => {
  return (
    <StyledInfoHint ref={forwardRef}>
      {showIcon && <InfoIcon />}
      <span>{text}</span>
    </StyledInfoHint>
  );
};

InfoHint.defaultProps = {
  showIcon: true,
};

InfoHint.propTypes = {
  showIcon: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  forwardRef: PropTypes.func,
};

export { InfoHint };
