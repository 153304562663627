import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Avatar } from '../../Avatar/avatar';
import { COLORS, MOBILE_BREAKPOINT } from '../../../constants';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../store/profile/selectors';
import { getHasSubscription } from '../../../../store/selectors';
import { ROUTES } from '../../../../app/routing';

const StyledProfileName = styled.div`
  font-weight: 500;
  margin-left: 16px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 32px;
  }
`;

const StyledProfile = styled(NavLink).attrs({ activeClassName: 'active' })`
  margin-top: 13px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 6px;
  padding: 16px;
  transition: 0.15s;
  background: none;
  text-decoration: none;

  ${StyledProfileName} {
    color: ${COLORS.grey1Text};
  }

  &:hover {
    transform: translateY(-1px);
    cursor: pointer;
  }

  &.active {
    background-color: ${COLORS.white};

    ${StyledProfileName} {
      color: ${COLORS.black};
    }
  }

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    bottom: 12px;
    left: 8px;
    right: 8px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0;
    &.active {
      background-color: ${COLORS.greyBg};
      border-radius: 0;
    }
  }
`;

const SidebarProfile = () => {
  const { first_name, last_name, pic } = useSelector(getProfileState);
  const hasSubscription = useSelector(getHasSubscription);

  return (
    <StyledProfile to={ROUTES.profile.path}>
      <Avatar
        src={pic}
        size="sm"
        firstName={first_name}
        lastName={last_name}
        hasSubscription={hasSubscription}
        dontPlusLink
      />
      <StyledProfileName>{`${first_name} ${last_name}`}</StyledProfileName>
    </StyledProfile>
  );
};

export { SidebarProfile };
