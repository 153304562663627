import { apiInstance } from './api-instance';

export const getCurrentProfile = async () => {
  const { data } = await apiInstance.get('profile');
  return data;
};

export const updateProfileFields = async (fields) => {
  const { data } = await apiInstance.put('profile', fields);
  return data;
};

export const patchProfileFields = async (fields) => {
  const { data } = await apiInstance.patch('profile', fields);
  return data;
};

export const getProfileEnums = async () => {
  const { data } = await apiInstance.get('profile/enums');
  return data;
};

export const postProfilePic = async (formData) => {
  const { data } = await apiInstance.post('profile/pic', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};
