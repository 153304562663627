import React from "react";

export const ENUMS = {
  frequentedPlaces: [
    { value: 'Москва', label: 'Москва' },
    { value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
    { value: 'Нижний Новгород', label: 'Нижний Новгород' },
  ],
  professionalInterests: [
    { value: 'IT', label: 'IT' },
    { value: 'финтех', label: 'финтех' },
    { value: 'образование', label: 'образование' },
    { value: 'медицина', label: 'медицина' },
    { value: 'инвестиции', label: 'инвестиции' },
    { value: 'искусство', label: 'искусство' },
    { value: 'ритейл', label: 'ритейл' },
    { value: 'недвижимость', label: 'недвижимость' },
    { value: 'реклама', label: 'реклама' },
    { value: 'юриспруденция, налоги', label: 'юриспруденция, налоги' },
  ],
};

export const STAGES = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  complete: 5,
};

export const ICONS = {
  menBLue: (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#E4ECFB"/>
      <circle
        cx="21.0002"
        cy="16"
        r="5"
        stroke="#2669E3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1773 28.281C15.9732 25.7341 18.3319 24 21.0003 24C23.6686 24 26.0274 25.7341 26.8233 28.281L26.9547 28.7017C27.1195 29.2289 26.8257 29.7897 26.2985 29.9545C25.7714 30.1192 25.2105 29.8254 25.0458 29.2983L24.9143 28.8776C24.3793 27.1656 22.7939 26 21.0003 26C19.2067 26 17.6212 27.1656 17.0862 28.8776L16.9547 29.2983C16.79 29.8254 16.2291 30.1192 15.702 29.9545C15.1748 29.7897 14.8811 29.2289 15.0458 28.7017L15.1773 28.281Z"
        fill="#2669E3"
      />
    </svg>
  ),
  menRed: (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#FFECE8"/>
      <circle
        cx="21.0002"
        cy="16"
        r="5"
        stroke="#FF6948"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1773 28.281C15.9732 25.7341 18.3319 24 21.0003 24C23.6686 24 26.0274 25.7341 26.8233 28.281L26.9547 28.7017C27.1195 29.2289 26.8257 29.7897 26.2985 29.9545C25.7714 30.1192 25.2105 29.8254 25.0458 29.2983L24.9143 28.8776C24.3793 27.1656 22.7939 26 21.0003 26C19.2067 26 17.6212 27.1656 17.0862 28.8776L16.9547 29.2983C16.79 29.8254 16.2291 30.1192 15.702 29.9545C15.1748 29.7897 14.8811 29.2289 15.0458 28.7017L15.1773 28.281Z"
        fill="#FF6948"
      />
    </svg>
  ),
  menPink: (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#F3E4FB"/>
      <circle
        cx="21.0002"
        cy="16"
        r="5"
        stroke="#A026E3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1773 28.281C15.9732 25.7341 18.3319 24 21.0003 24C23.6686 24 26.0274 25.7341 26.8233 28.281L26.9547 28.7017C27.1195 29.2289 26.8257 29.7897 26.2985 29.9545C25.7714 30.1192 25.2105 29.8254 25.0458 29.2983L24.9143 28.8776C24.3793 27.1656 22.7939 26 21.0003 26C19.2067 26 17.6212 27.1656 17.0862 28.8776L16.9547 29.2983C16.79 29.8254 16.2291 30.1192 15.702 29.9545C15.1748 29.7897 14.8811 29.2289 15.0458 28.7017L15.1773 28.281Z"
        fill="#A026E3"
      />
    </svg>
  ),
};
