import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledMargin = styled.div`
  display: block;
  width: ${(p) => (p.fullWidth ? '100%' : 'unset')};

  margin: ${(props) => props.margin.top} ${(props) => props.margin.right} ${(props) => props.margin.bottom}
    ${(props) => props.margin.left};

  ${(props) =>
    props.align &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `}
`;

const converter = (property) => {
  if (!property) {
    return;
  }

  if (typeof property === 'string') {
    if (property === 'auto') return 'auto';
    if (property.includes('px') || property.includes('%')) {
      return property;
    } else if (!isNaN(property)) {
      return property + 'px';
    }
  }

  if (typeof property === 'number') {
    return property + 'px';
  }

  throw new Error('Invalid margin parameter');
};

const Margin = ({ all, top, right, bottom, left, horizontal, vertical, align, children, fullWidth, as }) => {
  let margins = {
    top: converter(top) || converter(vertical) || converter(all) || '0',
    right: converter(right) || converter(horizontal) || converter(all) || '0',
    bottom: converter(bottom) || converter(vertical) || converter(all) || '0',
    left: converter(left) || converter(horizontal) || converter(all) || '0',
  };

  return (
    <StyledMargin margin={margins} align={align} fullWidth={fullWidth} as={as}>
      {children}
    </StyledMargin>
  );
};

Margin.Container = StyledMargin;

Margin.propTypes = {
  all: PropTypes.any,
  top: PropTypes.any,
  right: PropTypes.any,
  bottom: PropTypes.any,
  left: PropTypes.any,
  horizontal: PropTypes.any,
  vertical: PropTypes.any,
  align: PropTypes.any,
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
};

export { Margin };
