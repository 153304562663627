import { APPROVED, GHOST, PENDING, REGISTERED } from './constants';

export const profileEditingPermissions = (status) => {
  switch (status) {
    case PENDING:
      return true;
    case APPROVED:
      return true;
    default:
      return false;
  }
};

export const invitesPermissions = (status) => {
  switch (status) {
    case APPROVED:
      return true;
    case GHOST:
      return true;
    case REGISTERED:
      return true;
    default:
      return false;
  }
};

export const unitesPermissions = (status) => {
  switch (status) {
    case APPROVED:
      return true;
    default:
      return false;
  }
};

export const recommendationsPermissions = (status) => {
  switch (status) {
    case APPROVED:
      return true;
    default:
      return false;
  }
};
