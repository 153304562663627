import { useCallback, useEffect, useRef, useState } from 'react';

const applyZero = (time) => (time < 10 ? '0' + time : time);

const formatTime = (countdown) => {
  if (countdown < 0) {
    return null;
  }

  const hours = Math.floor(countdown / 60 / 60);
  const hoursLeftInSeconds = hours * 3600;
  const minutes = Math.floor((countdown - hoursLeftInSeconds) / 60);
  const minutesLeftInSeconds = hoursLeftInSeconds + minutes * 60;
  const seconds = countdown - minutesLeftInSeconds;

  return [hours || null, hours ? applyZero(minutes) : minutes, applyZero(seconds)]
    .filter((e) => e !== null)
    .join(':');
};

/**
 * Countdown hook
 *
 * @param onCountdown Callback function on countdown end
 * @param countdown If provided, set's countdown at initial mount
 * @returns {[{countdown: Number, time: (string|null)}, function(*=): (undefined)]}
 */
export const useCountdown = (onCountdown, countdown = null) => {
  const [_countdown, setCountdown] = useState(countdown);
  const interval = useRef(null);

  const makeCountdown = useCallback(
    (time) => {
      if (time === null) {
        setCountdown(null);
        return;
      }
      setCountdown(time || countdown);
      interval.current = setInterval(() => {
        setCountdown((p) => {
          if (p <= 0) {
            clearInterval(interval.current);
            setCountdown(null);
            if (onCountdown) {
              onCountdown(false);
            }
          }
          return p - 1;
        });
      }, 1000);
    },
    [countdown, onCountdown],
  );

  useEffect(() => {
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (countdown) {
      makeCountdown(countdown);
    }
  }, []); //eslint-disable-line

  const time = formatTime(_countdown);

  return [{ time, countdown: _countdown }, makeCountdown];
};
