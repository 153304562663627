import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants';
import { calculatePosition } from './utils';
import { Transition } from 'react-transition-group';

const domNode = document.getElementById('tooltip');
const DURATION = 250;

const openAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const closeAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-8px);
  }
`;

const StyledTooltip = styled((props) => (
  <div style={props.style} ref={props.forwardRef} className={props.className}>
    {props.children}
  </div>
))`
  box-shadow: 0 8px 16px -4px rgba(51, 51, 51, 0.2);
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  max-width: calc(100% - 32px);

  animation: ${(p) => p.animation} ${DURATION}ms forwards;
  background-color: ${COLORS.darkBlue};
  border: 1px solid ${COLORS.darkBlue};

  &::after {
    content: '';
    position: absolute;
    bottom: -12px;
    height: 12px;
    width: 100%;
  }
`;

const StyledTooltipText = styled.div`
  background: ${COLORS.darkBlue};
  color: ${COLORS.white};
  position: relative;
  padding: 12px 16px;
  z-index: 4;
  border-radius: 10px;
  max-width: 320px;
`;

const StyledTriangle = styled.div`
  background: ${COLORS.darkBlue};
  border: 1px solid ${COLORS.darkBlue};
  border-radius: 2px;
  transform: rotate(45deg);
  z-index: 3;
  position: absolute;
  width: 12px;
  height: 12px;
  left: calc(50% - 6px);
  bottom: -5px;
`;

const transitionStyles = {
  entering: openAnimation,
  entered: openAnimation,
  exiting: closeAnimation,
  exited: closeAnimation,
};

const defaultPosition = { left: 0, top: 0, triangleOffset: 0 };

/**
 *
 * @param {Object} params params
 * @param {Function} params.children callback with ref
 * @param {String|JSX} params.text tooltip texts
 * @returns {JSX.Element}
 */
const Tooltip = ({ children, text, showOnHover, show }) => {
  if (showOnHover === false && !(typeof show === 'boolean')) {
    throw new Error(
      `If 'showOnHover' prop presented as false, 'show' prop 
      should be controller (true/false). '${show}' presented`,
    );
  }

  const reference = useRef(null);
  const tooltip = useRef(null);

  const [position, setPosition] = useState(defaultPosition);
  const [_show, setShow] = useState(false);

  const onOut = useCallback((e) => {
    if (tooltip.current.contains(e.relatedTarget)) {
      tooltip.current.addEventListener('mouseout', onOut);
    } else {
      tooltip.current.removeEventListener('mouseout', onOut);
      setShow(false);
    }
  }, []);

  const onHover = useCallback((e) => {
    e.stopPropagation();

    if (reference.current === e.target || reference.current.contains(e.target)) {
      setShow(true);
      calculatePosition(tooltip.current, reference.current, setPosition);
    } else {
      setShow(false);
    }
  }, []);

  const resetState = useCallback(() => setPosition(defaultPosition), []);

  useEffect(() => {
    if (!showOnHover) {
      setShow(show);
    }
  }, [_show, show, showOnHover]);

  useEffect(() => {
    if (!showOnHover && _show) {
      calculatePosition(tooltip.current, reference.current, setPosition);
    }
  }, [_show, showOnHover]);

  useEffect(() => {
    const targetRef = reference.current;

    if (showOnHover) {
      if (targetRef) {
        targetRef.addEventListener('mousedown', onHover); // avoid mousedown/touchend bubbling if ReactSelect contains
        targetRef.addEventListener('touchend', onHover); // this tooltip target component

        targetRef.addEventListener('mouseover', onHover);
        targetRef.addEventListener('mouseout', onOut);
        window.addEventListener('touchstart', onHover);
      }

      return () => {
        if (targetRef) {
          targetRef.removeEventListener('mousedown', onHover);
          targetRef.removeEventListener('touchend', onHover);

          targetRef.removeEventListener('mouseover', onHover);
          targetRef.removeEventListener('mouseout', onOut);
          window.removeEventListener('touchstart', onHover);
        }
      };
    }
  }, [onOut, reference, showOnHover, onHover, tooltip]);

  return (
    <>
      {children(reference)}
      {ReactDOM.createPortal(
        <Transition in={_show} timeout={DURATION} unmountOnExit mountOnEnter onExited={resetState}>
          {(state) => (
            <StyledTooltip
              animation={transitionStyles[state]}
              style={{ left: position.left, top: position.top }}
              forwardRef={tooltip}
              role="tooltip"
            >
              <StyledTriangle style={{ marginLeft: position.triangleOffset }} />
              <StyledTooltipText>{text}</StyledTooltipText>
            </StyledTooltip>
          )}
        </Transition>,
        domNode,
      )}
    </>
  );
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.func,
  showOnHover: PropTypes.bool,
  show: PropTypes.bool,
};

Tooltip.defaultProps = {
  showOnHover: true,
};

export { Tooltip };
