import React, { useEffect, useState } from 'react';
import { Room } from '../../features/Room';
import { OuterContainer } from '../../common/components/OuterContainer';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { TransitionListItem } from '../../common/components/TransitionListItem';
import { WideHeaderContainer } from '../../common/components/WideHeaderContainer';
import { Heading } from '../../common/components/Heading';
import { Margin } from '../../common/components/Margin';
import { Button } from '../../common/components/Button';
import { getInRoom, getRoom } from '../../common/api/rooms-api';
import { Spinner } from '../../common/components/Spinner';
import { useStage } from '../../common/hooks/use-stage';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../store/profile/selectors';
import { Br } from '../../common/components/Br';
import { JOIN_ROOM_STAGES } from './stages';

const RouteJoinRoom = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const { rooms: userRooms } = useSelector(getProfileState);
  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState(null);
  const { loggedByInvite } = queries;
  const userAlreadyInRoom = !!userRooms.find((ur) => ur.id === queries.room);
  const shouldLeave = loggedByInvite ? !queries.room : !(queries.room && queries.invite) || userAlreadyInRoom;

  const initialStage = loggedByInvite ? JOIN_ROOM_STAGES.accepted : JOIN_ROOM_STAGES.room;
  const [{ stage, direction }, setStage] = useStage({
    stages: JOIN_ROOM_STAGES,
    initialStage: initialStage,
  });

  const fetchRoom = async (id) => {
    const data = await getRoom(id);
    setRoom(data?.room);
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      const { code } = await getInRoom(queries.room, queries.invite);
      setLoading(false);
      if (code === 200) {
        setStage(JOIN_ROOM_STAGES.accepted);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    !shouldLeave && fetchRoom(queries.room);
  }, []); //eslint-disable-line

  const leave = () => history.push('/profile');

  if (shouldLeave) {
    leave();
  }

  if (!room) {
    return (
      <OuterContainer>
        <Room.Skeleton />
      </OuterContainer>
    );
  }

  const roomName = room?.name;

  return (
    <OuterContainer>
      <Spinner overlay show={loading} />

      <TransitionListItem show={stage === JOIN_ROOM_STAGES.room} direction={direction}>
        <Room room={room} onAccept={handleOnSubmit} />
      </TransitionListItem>

      <TransitionListItem show={stage === JOIN_ROOM_STAGES.accepted} direction={direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Вы в комнате!')}</Heading>
        </WideHeaderContainer>

        <Margin bottom={40}>
          <Room.Avatar image={room?.pic} />
        </Margin>

        <Trans t={t} parent="p">
          Вы присоединились к сообществу <Br />
          <strong>{{ roomName }}</strong>
          <Br /> и теперь вам доступны люди, которые находятся в нем
        </Trans>

        <Margin top={32}>
          <Button onClick={leave}>{t('Продолжить')}</Button>
        </Margin>
      </TransitionListItem>
    </OuterContainer>
  );
};

export { RouteJoinRoom };
