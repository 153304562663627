import { addDays, format, getDay, set } from "date-fns";

export const formatDay = (dayOfWeek, time) => {
  const currentDate = new Date();
  const currentDay = getDay(currentDate);
  const daysToAdd = dayOfWeek - currentDay;

  const thisDay = set(addDays(new Date(), daysToAdd), {
    hours: time,
    minutes: 0,
    seconds: 0,
  });

  return format(thisDay, "yyyy-MM-dd'T'HH:mm:ss");
};