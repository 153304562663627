import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../constants';

import PropTypes from 'prop-types';
import Box from "../Box";

const Container = styled(Box).attrs({
  as: 'main',
})`
  max-width: 772px;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 64px 66px 80px 322px;

  @media screen and (max-width: ${772}px) {
    padding: 64px 16px 64px 272px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 16px 64px;
  }
`;

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export { Container };
