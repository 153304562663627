import axios from "axios";
import { useEffect, useRef, useState } from 'react';
import RemoteResource from "../remote-resource";
import { apiInstance } from '../api/api-instance';

export const useRemoteResource = () => {
  const [remoteResourceConfig, setRemoteResourceConfig] = useState(null);
  const [remoteResource, setRemoteResource] = useState(RemoteResource.NotAsked);
  const mountedRef = useRef(null);
  useEffect(() => {
    mountedRef.current = true;
    return () => mountedRef.current = false;
  }, []);

  useEffect(() => {
    setRemoteResource(RemoteResource.NotAsked);
  }, [remoteResourceConfig]);

  useEffect(() => {
    const fetchData = async () => {

      setRemoteResource(RemoteResource.Loading);

      try {
        const response = await apiInstance({
          ...remoteResourceConfig
        });
        if (mountedRef.current) {
          setRemoteResource(RemoteResource.Loaded(response.data));
        }
      } catch (err) {
        if (mountedRef.current) {
          if (axios.isCancel(err)) {
            setRemoteResource(RemoteResource.NotAsked);
          } else {
            setRemoteResource(RemoteResource.Error(err));
          }
        }
      }
    };
    if (remoteResourceConfig !== null) {
      fetchData();
    }
  }, [remoteResourceConfig]);


  return [remoteResource, setRemoteResourceConfig]
};
