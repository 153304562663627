import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import { getColorByHash } from './utils';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../app/routing';

const UserIcon = (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
    <circle cx="12" cy="7" r="4" />
  </svg>
);

export const hasSubscriptionMixin = css`
  border: 2px solid #f6f7f8;
  box-shadow: 0 0 0 2px #f0b94b;
`;

const StyledAvatar = styled((props) => {
  const Component = props.forwardAs ?? 'div';

  return (
    <Component className={props.className} to={props.to ?? undefined} title={props.title}>
      {props.children}
    </Component>
  );
})`
  position: relative;
  overflow: hidden;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  background-color: ${(p) => p.avatarColor};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(p) => p.image});
  text-decoration: none;
  flex-shrink: 0;

  & svg {
    width: ${(p) => p.size / 2}px;
    height: ${(p) => p.size / 2}px;
    stroke: ${COLORS.white};
  }

  ${(p) => p.hasSubscription && hasSubscriptionMixin}

  .skeleton {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

const StyledName = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(p) => p.size}px;
  line-height: ${(p) => p.size}px;
  text-align: center;
  color: ${COLORS.white};
  height: auto;
`;

/**
 *
 * @param {'sm'|'lg'|'xl'|number} size `sm` (26px), `lg` (66px), `xl` (96px) or number of pixels
 * @param {string} src image path
 * @param {string} firstName first name of person
 * @param {string} lastName last name of person
 * @param {boolean} hasSubscription render gold frame around avatar
 * @param {boolean} dontPlusLink dont link to Plus page has subscription (false by default)
 * @return {JSX.Element} Avatar component with image/person abbreviation/icon
 *
 * @example
 * <Avatar src="example.com/pic.jpg" firstName="Paul" lastName="Johnson" hasSubscription={true} />
 */
const Avatar = ({ size, src, firstName, lastName, hasSubscription, dontPlusLink }) => {
  const sizeNum = typeof size === 'string' ? (size === 'sm' ? '26' : size === 'lg' ? '66' : '96') : size;

  const [imageBinary, setImageBinary] = useState(null);

  const makeImageBinary = useCallback(async (src) => {
    const response = await axios.get(src, { responseType: 'arraybuffer' });
    setImageBinary('data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64'));
  }, []);

  useEffect(() => {
    if (src) {
      if (typeof src === 'string' && src.includes('base64')) {
        setImageBinary(src);
      } else {
        makeImageBinary(src);
      }
    }
  }, [makeImageBinary, src]);

  const showSkeleton = src && !imageBinary;

  const fullName = [firstName, lastName].join(' ');
  const avatarColor = useMemo(() => getColorByHash(fullName || ''), [fullName]);
  const name = (firstName?.[0]?.toUpperCase() ?? '') + (lastName?.[0]?.toUpperCase() ?? '') || null;

  return (
    <StyledAvatar
      forwardAs={hasSubscription && !dontPlusLink ? Link : undefined}
      to={hasSubscription && !dontPlusLink ? ROUTES.uniterPlus.path : undefined}
      className="avatar"
      hasSubscription={hasSubscription}
      size={sizeNum}
      image={imageBinary}
      avatarColor={avatarColor}
      title={fullName}
    >
      {showSkeleton && <Skeleton className="skeleton" duration={0.8} circle />}
      {!src && (name ? <StyledName size={sizeNum / 2.2}>{name}</StyledName> : UserIcon)}
    </StyledAvatar>
  );
};

Avatar.defaultProps = {
  size: 'lg',
  hasSubscription: false,
  dontPlusLink: false,
};

Avatar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.oneOf(['sm', 'lg', 'xl']), PropTypes.number]),
  src: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  hasSubscription: PropTypes.bool,
  dontPlusLink: PropTypes.bool,
};

Avatar.Container = StyledAvatar;

export { Avatar };
