import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS, MOBILE_BREAKPOINT } from '../../constants';
import { Link, useLocation } from 'react-router-dom';
import { retrieveSlashes } from '../../helpers/trim-retrieve-slashes';

const StyledTitle = styled.div`
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
`;

const StyledText = styled.div`
  margin-top: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

const StyledContainer = styled(({ component, to, className, children }) => {
  // Avoiding unexpected props trowing
  const Component = component ?? 'div';
  const props = component ? { to } : {};

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
})`
  display: ${(p) => (p.fromProfile ? 'none' : 'block')};
  border-radius: 6px;
  background-color: ${(p) => (p.fromProfile ? COLORS.greyBg : COLORS.white)};
  padding: 16px 24px 24px;
  text-align: center;
  width: 100%;
  cursor: ${(p) => (p.isLink ? 'pointer' : 'default')};
  transition: 0.15s ease-out;
  text-decoration: none;

  &:hover {
    transform: ${(p) => (p.isLink ? 'translateY(-2px)' : 'unset')};

    ${StyledText} {
      text-decoration: ${(p) => (p.isLink ? 'underline' : 'unset')};
    }
  }

  ${StyledText} {
    color: ${(p) => (p.isLink ? COLORS.blue : 'inherit')};
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: ${(p) => (p.fromProfile ? 'block' : 'none')};

    ${StyledTitle} {
      font-size: 16px;
      line-height: 24px;
    }

    ${StyledText} {
      font-size: 12px;
      line-height: 16px;
      color: ${(p) => (p.isLink ? COLORS.blue : '#71777c')};
    }
  }
`;

const Message = ({ text, title, link, fromProfile }) => {
  const location = useLocation();

  if (link && location.pathname.includes(retrieveSlashes(link))) {
    link = null;
  }

  return (
    <StyledContainer component={link ? Link : null} fromProfile={fromProfile ?? undefined} isLink={link} to={link}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {text && <StyledText>{text}</StyledText>}
    </StyledContainer>
  );
};

Message.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  fromProfile: PropTypes.bool,
};

Message.Container = StyledContainer;

export { Message, StyledContainer as MessageContainer };
