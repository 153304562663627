export const trimSlashes = (link) => {
  if (typeof link === 'string') {
    return link.replace(/^\/|\/$/gim, '');
  } else {
    throw new Error('First argument should be a string');
  }
};
export const retrieveSlashes = (link) => {
  if (typeof link === 'string') {
    return link[0] === '/' ? link : '/' + link;
  } else {
    throw new Error('First argument should be a string');
  }
};
