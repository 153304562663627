import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPaymentProducts, postPaymentInvoice } from '../../../common/api/payment-api';
import { A } from '../../../common/components/A';
import { Button } from '../../../common/components/Button';
import { Margin } from '../../../common/components/Margin';
import { PaymentOverlay } from '../../../common/components/PaymentOverlay';
import { CLOUDPAYMENTS_BUNDLE_LINK, COLORS, MOBILE_BREAKPOINT } from '../../../common/constants';
import { ConfettiContext } from '../../../common/contexts/confetti-context/confetti-context';
import { modalController } from '../../../common/controllers/modal-controller';
import { actualizeProfile } from '../../../common/helpers/actualize-profile';
import { formatPrice } from '../../../common/helpers/format-price';
import { useScript } from '../../../common/hooks/use-script';
import { config } from '../../../config';
import { settings } from '../../../settings';
import { getHasSubscription } from '../../../store/selectors';
import { TelegramIcon } from '../../TelegramIntegration/telegram-integration';
import { UniterPlusSubscriptionCountdown } from './uniter-plus-subscription-countdown';

const confettiDuration = 5000;
const confettiColors = ['#eeba54', '#f0c457', '#f0b94b', '#efad29', '#042C8C', '#F40404'];

const SubscriptionButton = styled(Button)`
  position: relative;
  margin: -28px auto 32px;
  z-index: 2;
  max-width: 320px !important;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 40px;
  }

  @media (max-width: 432px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const DiscountContainer = styled.div`
  text-align: center;
  font-family: Roboto Condensed, sans-serif;
  margin: -28px auto 32px;
  padding: 16px 16px;
  z-index: 2;
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 0 2px #f0b94b;
  border: 2px solid #f6f7f8;
`;

const OriginalPrice = styled.div`
  font-family: Roboto Condensed, sans-serif;
  text-decoration: line-through;
  color: ${COLORS.grey1Text};
`;

const CurrentPrice = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 32px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const UniterPlusSubscription = () => {
  const hasSubscription = useSelector(getHasSubscription);
  const [paymentOverlay, setPaymentOverlay] = useState(false);
  const [cloudPaymentsScriptLoaded] = useScript(CLOUDPAYMENTS_BUNDLE_LINK);
  const [product, setProduct] = useState(null);
  const { showConfetti } = useContext(ConfettiContext);
  const { first_name: firstName, last_name: lastName, email } = useSelector((state) => state.profile);
  const { id, price, currency, sale, original: originalPrice, expiration } = product ?? {};

  const onPaymentSuccess = useCallback(() => {
    actualizeProfile().then(() => setPaymentOverlay(false));

    showConfetti({
      style: { zIndex: 20 },
      colors: confettiColors,
      numberOfPieces: 200,
      gravity: 0.2,
      tweenDuration: confettiDuration,
    });

    modalController.showModal({
      title: 'Поздравляем!',
      // eslint-disable-next-line react/display-name
      body: (onLeave) => (
        <>
          <p style={{ textAlign: 'center' }}>
            Оплата успешно произведена, теперь вы пользователь Uniter Plus! Будем рады видеть вас в закрытом чате.
          </p>

          <Button href={settings.uniterPlusTelegramLink} target="_blank" rel="noreferrer" onClick={onLeave} as="a">
            {TelegramIcon} Получить доступ в чат
          </Button>
        </>
      ),
      showCloseButton: true,
    });
  }, [showConfetti]);

  const onPaymentFailure = useCallback(() => {
    setPaymentOverlay(false);
    modalController.showModal({
      title: 'Упс',
      // eslint-disable-next-line react/display-name
      body: (onLeave) => (
        <>
          <p style={{ textAlign: 'center' }}>
            К сожалению, во время оплаты произошла ошибка. Попробуйте еще раз или обратитесь в поддержку:{' '}
            <A href="mailto:hi@uniter.ai">hi@uniter.ai</A>
          </p>
          <Button onClick={onLeave}>OK</Button>
        </>
      ),
      showCloseButton: true,
    });
  }, []);

  const handleOnSubscriptionClick = useCallback(async () => {
    setPaymentOverlay(true);
    const { invoice } = await postPaymentInvoice({ product: id, email });
    const widget = new window.cp.CloudPayments();
    const receipt = {
      Items: [
        {
          label: "Ежемесячная подписка Uniter Plus",
          price: Number(price),
          quantity: 1.00,
          amount: Number(price),
          method: 1,  // fullprepayment
          object: 4, // service
        }
      ],
      "email": email, //e-mail покупателя, если нужно отправить письмо с чеком
      "customerInfo": "", // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
      "isBso": false, //чек является бланком строгой отчётности
      "AgentSign": null, //признак агента, тег ОФД 1057
      "amounts":
        {
          "electronic": Number(price), // Сумма оплаты электронными деньгами
        }
    };
    widget.charge(
      {
        publicId: config.cloudpaymentsPublicId,
        description: 'Ежемесячная подписка Uniter Plus',
        accountId: email,
        invoiceId: invoice,
        amount: Number(price),
        currency: currency,
        skin: 'modern',
        data: { firstName, lastName, cloudPayments: {
          CustomerReceipt: receipt,
          recurrent: { interval: 'Month', period: 1, customerReceipt: receipt  } }
        },
      },
      onPaymentSuccess,
      onPaymentFailure,
    );
  }, [currency, email, firstName, id, lastName, onPaymentSuccess, onPaymentFailure, price]);

  const fetchProducts = useCallback(async () => {
    try {
      const data = await getPaymentProducts({ email });
      const priority_pass = data?.products?.find((p) => p.name === settings.products.uniterPlus1M);
      setProduct(priority_pass || null);
    } catch (e) {
      console.error(e);
    }
  }, [email]);

  useEffect(() => {
    if (!hasSubscription) {
      fetchProducts();
    }
  }, [fetchProducts, hasSubscription]);

  if (hasSubscription) {
    return null;
  }

  return (
    <React.Fragment>
      <PaymentOverlay show={paymentOverlay} />
      {sale ? (
        <DiscountContainer>
          <OriginalPrice>{formatPrice(originalPrice, currency, 'ru-RU')}</OriginalPrice>
          <CurrentPrice>{formatPrice(price, currency, 'ru-RU')} в месяц</CurrentPrice>
          <Margin bottom={18}>
            До конца предложения осталось:{' '}
            <UniterPlusSubscriptionCountdown sale={sale} expiration={expiration} onCountdown={fetchProducts} />
          </Margin>
          <Button variant="plus" onClick={handleOnSubscriptionClick}>
            {`Купить за ${formatPrice(price, currency, 'ru-RU')} в месяц`}
          </Button>
        </DiscountContainer>
      ) : (
        <SubscriptionButton
          disabled={!cloudPaymentsScriptLoaded || !product}
          onClick={handleOnSubscriptionClick}
          variant="plus"
        >
          {product && `Купить за ${formatPrice(price, currency, 'ru-RU')} в месяц`}
        </SubscriptionButton>
      )}
    </React.Fragment>
  );
};

export { UniterPlusSubscription };
