import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';
import Creatable from 'react-select/creatable';
import Skeleton from 'react-loading-skeleton';
import { COLORS } from '../../constants';
import { MultiValueRemove } from './components/multi-value-remove';
import { OptionWithPic } from './components/option-with-pic';
import { useTranslation } from 'react-i18next';
import { MultiSelectControl } from './components/multi-select-control';

const createOptionCommaRegex = /[,;]/;

const MultiSelect = styled(
  ({
    options,
    placeholder,
    onChange,
    value,
    className,
    isMulti,
    isCreatable,
    disabled,
    loading,
    validation,
    optionsHasPic,
    hideSelectedOptions,
    noOptionsMessage,
    tooltipText,
  }) => {
    const { t } = useTranslation();

    const handleOnChange = useCallback(
      (value) => (disabled ? undefined : isMulti ? onChange(Array.isArray(value) ? value : []) : onChange(value)),
      [disabled, isMulti, onChange],
    );

    // todo make this feature optional
    const handleOnCreateOption = useCallback(
      (option) => {
        if (isMulti) {
          if (createOptionCommaRegex.test(option)) {
            let options = option
              .split(createOptionCommaRegex)
              .map((o) => o.trim())
              .filter((v) => v !== '')
              .map((v) => ({ value: v, label: v }));

            onChange(
              value
                .concat(options)
                // avoiding duplicate values
                .filter((option, index, self) => index === self.findIndex((o) => o.value === option.value)),
            );
          } else {
            onChange(value.concat({ value: option, label: option }));
          }
        } else {
          onChange({ value: option, label: option });
        }
      },
      [isMulti, onChange, value],
    );

    const components = useMemo(
      () => ({
        MultiValueRemove,
        IndicatorsContainer: () => null,
        ...(optionsHasPic ? { Option: OptionWithPic } : {}),
        ...(tooltipText ? { Control: MultiSelectControl(tooltipText) } : {}),
      }),
      [optionsHasPic, tooltipText],
    );

    const formatCreateLabel = useCallback((value) => value, []);

    const _noOptionsMessage = useCallback(() => noOptionsMessage ?? t('Создайте свой вариант'), [noOptionsMessage, t]);

    if (loading) {
      return <Skeleton height={100} />;
    }

    const Component = isCreatable ? Creatable : Select;

    return (
      <Component
        onChange={handleOnChange}
        isDisabled={disabled}
        noOptionsMessage={_noOptionsMessage}
        classNamePrefix={'r-s'}
        hideSelectedOptions={hideSelectedOptions}
        placeholder={placeholder}
        validation={validation}
        components={components}
        className={className}
        options={options}
        isMulti={isMulti}
        value={value}
        onCreateOption={handleOnCreateOption}
        formatCreateLabel={formatCreateLabel}
      />
    );
  },
)`
  .r-s__control {
    background: ${(p) => (p.validation ? COLORS.redBg : COLORS.greyBg)};
    border: none;
    border-radius: 6px;
    padding: 26px 16px 12px;
    box-shadow: none;
    border-bottom: 2px solid ${(p) => (p.validation ? COLORS.red : COLORS.greyBg)};
    cursor: inherit;
    align-items: start;
    
    .r-s__placeholder {
      color: ${(p) => (p.validation ? COLORS.redBg2 : COLORS.grey1Text)};
    }
    .r-s__value-container {
      padding: 0;
      line-height: 24px;
      height: 24px;
      
      &.r-s__value-container--is-multi {
        min-height: 36px;
        height: unset;
        line-height: unset;
      }
      
      .r-s__placeholder {
         color: ${(p) => (p.validation ? COLORS.redBg2 : COLORS.grey2Text)};
      }
      
      & > div:last-child {
        padding: 0;
        margin: 0;
      }

      .r-s__multi-value {
        background: ${COLORS.blue};
        border-radius: 100px;
        padding: 4px 32px 4px 12px;
        position: relative;

        &__remove {
          position: absolute;
          right: 4px;
          top: 8px;
          cursor: pointer;
          &:hover {
            background-color: unset;
            color: unset;
          }
        }

        &__label {
          font-size: 16px;
          line-height: 24px;
          padding: 0;
          color: ${COLORS.white};
        }
      }
      
      .r-s__single-value {
        color: ${COLORS.black};
        margin: 0;
      }
      
      .r-s__input {
        margin: 0;
        padding: 0;
      }
      
      .r-s__single-value--is-disabled {
        color: ${COLORS.grey1Text};
      }
    }
    
    &:hover {
      border-color: ${(p) => (p.validation ? COLORS.red : COLORS.greyBg)};
    }
    
    &--is-focused {
      border: none;
      border-bottom: 2px solid ${(p) => (p.validation ? COLORS.red : COLORS.blue)} !important;
      border-radius: 6px 6px 0 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    }
    

    &:before {
      content: "${(p) => p.label}";
      position: absolute;
      left: 16px;
      top: 8px;
      font-size: 12px;
      line-height: 16px;
      color: ${(p) => (p.validation ? COLORS.red : COLORS.grey1Text)};
    }
  }
  
  .r-s__menu {
    margin-top: 0;
    z-index: 3;
    padding: 0;
    border: 0;
    background: ${COLORS.white};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 6px 6px;
    overflow: auto;

    .r-s__menu-list {
      padding: 0;
      .r-s__option {
        cursor: pointer;
        padding: 12px 16px;
        display:flex;
        flex-direction: row;
        align-items: center;
        color: ${COLORS.black};
        
        &--is-selected {
          background: ${COLORS.white};
        }
        
        &--is-focused,
        :hover {
          background: ${COLORS.greyBg};
        }
        :last-child {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }
  
  pointer-events: unset !important;
`;

MultiSelect.defaultProps = {
  placeholder: 'placeholder',
  label: 'label',
  isMulti: true,
  options: [],
  isCreatable: false,
  hideSelectedOptions: true,
};

MultiSelect.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  isCreatable: PropTypes.bool,
  validation: PropTypes.bool,
  isDisabled: PropTypes.bool,
  optionsHasPic: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
};

export { MultiSelect };
