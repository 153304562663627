import React from 'react';
import styled from 'styled-components';
import { Badge } from '../../../common/components/Badge';
import { useSelector } from 'react-redux';
import { getHasSubscription } from '../../../store/selectors';
import { Heading } from '../../../common/components/Heading';
import { Margin } from '../../../common/components/Margin';

const StyledPrivilegesContainer = styled.div`
  background: #e4ecfb;
  padding: 24px 24px 32px;
  z-index: 1;
  width: 100% !important;
  max-width: 100% !important;
  overflow: auto;
  display: flex;

  @media screen and (max-width: ${772}px) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px) !important;
    max-width: calc(100% + 32px) !important;
  }
`;

const StyledPrivilegesGrid = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  justify-content: center;
`;

const StyledPrivilege = styled.div`
  padding-left: 32px;
  color: white;
  flex-basis: 50%;
`;

const StyledPrivilegeHeader = styled.h3`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 12px;
  font-family: Roboto Condensed, sans-serif;
  font-style: normal;
  position: relative;
  vertical-align: middle;

  &:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' stroke='%232669E3' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 8px;
    margin-left: -32px;
    position: relative;
    top: 5px;
  }
`;

const privilegesList = [
  {
    title: 'Закрытый клуб',
    text: 'Получите доступ в закрытый чат, запрашивайте выходы на нужных людей',
  },
  {
    title: 'Больше контактов',
    text: 'До 4-х контактов в еженедельных встречах',
  },
  {
    title: 'Золотой профиль',
    text: 'Оживите обложку профиля приятным золотым цветом',
  },
  {
    title: 'Режим бога',
    text: 'Получите доступ к списку всех пользователей и их навыкам',
  },
  {
    title: 'Расширенный поиск',
    text: 'Приоритетные и стоп теги помогут точечно находить нужных людей каждую неделю',
  },
  {
    title: 'Новые функции',
    text: 'Приоритет в очереди юнайтов, более релевантные юнайты среди всех, кто записался на неделю',
  },
];

const UniterPlusPrivileges = () => {
  const hasSubscription = useSelector(getHasSubscription);
  const Container = hasSubscription ? React.Fragment : StyledPrivilegesContainer;
  const Grid = hasSubscription ? React.Fragment : StyledPrivilegesGrid;

  return (
    <>
      {hasSubscription && (
        <Margin top={16} bottom={16}>
          <Heading level={2}>Преимущества подписки</Heading>
        </Margin>
      )}
      <Container>
        <Grid>
          {privilegesList.map((o) => (
            <StyledPrivilege key={o.text}>
              <StyledPrivilegeHeader>
                {o.title}
                {o.badge && <Badge>{o.badge}</Badge>}
              </StyledPrivilegeHeader>
              <p>{o.text}</p>
            </StyledPrivilege>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export { UniterPlusPrivileges };
