import styled from 'styled-components';
import { color, compose, system, border, typography, layout, background, flexbox, space, position, grid } from 'styled-system';


const boxProps = compose(
  space,
  position,
  grid,
  color,
  background,
  flexbox,
  typography,
  layout,
  border,
   system({
    transform: true,
  })
);

const Box = styled('div')(
  {
    boxSizing: 'border-box',
    overflow: 'visible',
    '-webkit-overflow-scrolling': 'touch'
  },
  boxProps
);
Box.displayName = 'Box';

export default Box;
