export const socialNetworkChangeController = (name, value) => (s) => {
  for (let key in s) {
    if (!s[key]) {
      s[key] = '';
    }
  }

  return {
    ...s,
    [name]: value,
  };
};
