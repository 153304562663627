import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import Skeleton from 'react-loading-skeleton';

const StyledContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  height: 24px;
  width: 100%;
`;

const StyledCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledLabel = styled.div`
  user-select: none;
  margin-left: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => (p.disabled ? COLORS.grey1Text : COLORS.black)};
`;

const checkedStyle = css`
  background-color: ${COLORS.blue};
  border-color: ${COLORS.blue};
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUgMy4xNDI4Nkw0Ljc3MjczIDZMMTAuNSAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
`;

const StyledIcon = styled.div`
  transition: 0.15s;
  width: 22px;
  height: 22px;
  border: 2px solid ${(p) => (p.disabled ? COLORS.greyBg : COLORS.grey2Text)};
  box-sizing: border-box;
  border-radius: 4px;

  ${(p) => p.checked && checkedStyle}
`;

const Checkbox = ({ checked, onChange, label, disabled, loading }) => {
  const handleOnChange = (e) => onChange(disabled ? checked : e.target.checked);

  if (loading) {
    return <Skeleton wrapper={StyledContainer} />;
  }

  return (
    <StyledContainer disabled={disabled}>
      <StyledCheckbox checked={checked} onChange={handleOnChange} type="checkbox" disabled={disabled} />
      <StyledIcon checked={checked} disabled={disabled} />
      {label && <StyledLabel disabled={disabled}>{label}</StyledLabel>}
    </StyledContainer>
  );
};

Checkbox.defaultProps = {
  label: '',
  checked: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Checkbox.Container = StyledContainer;

export { Checkbox };
