import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants';

const Badge = styled(({ className, to, children }) => {
  const Component = to ? Link : 'span';

  return (
    <Component to={to ?? undefined} className={className}>
      {children}
    </Component>
  );
})`
  background: ${COLORS.blue};
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  padding: 4px 9px;
  height: 22px;
  color: white;
  margin-left: 6px;
  position: relative;
  top: -0.05rem;
  font-weight: ${(p) => (p.sm ? 900 : 500)};
  text-decoration: none;
  font-family: Roboto, 'sans-serif';
  display: inline-block;
  vertical-align: middle;
`;

Badge.defaultProps = {
  sm: false,
  to: null,
};

Badge.propTypes = {
  to: PropTypes.string,
};

export { Badge };
