import { parse, format, isSameMonth } from 'date-fns';
import { i18next } from '../../i18n/i18n';
import { config } from '../../config';
import { resolveDateFnsPossibleLocale } from '../../settings';

const generateRandomValue = () => {
  const mathRandom = Math.random();
  return Math.floor(mathRandom * 10);
};

export const getRandomPriorityTag = (tags) => {
  const tagsLength = tags.length - 1;

  return tags[generateRandomValue() % tagsLength];
};

export const formatDatesInterval = (dates) => {
  const [startDate, endDate] = dates;
  const parsedStartDate = parse(startDate, 'yyyy-MM-dd', new Date());
  const parsedEndDate = parse(endDate, 'yyyy-MM-dd', new Date());
  const sameMonth = isSameMonth(parsedStartDate, parsedEndDate);
  const options = { locale: resolveDateFnsPossibleLocale(config.languageCode) };

  return {
    startDate: format(parsedStartDate, sameMonth ? 'd' : 'd MMMM', options),
    endDate: format(parsedEndDate, 'd MMMM', options),
  };
};

export const formatWeekHintText = (dates) => {
  return dates[0] && dates[1]
    ? i18next.t('Встреча пройдет с {{startDate}} по {{endDate}}', formatDatesInterval(dates))
    : i18next.t('Встреча пройдет в один из выбранных временных слотов');
};
