import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import { OuterContainer } from '../OuterContainer';
import { Logo } from '../Logo';
import { useTranslation } from 'react-i18next';

const closeIcon = (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6L18 18M18 6L6 18" stroke="#131C25" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const StyledPopupContainer = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;
  z-index: 1000;
  overflow: auto;
  padding-bottom: 32px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledCloseButtonContainer = styled.div`
  display: flex;
  color: ${COLORS.grey1};
  font-size: 12px;
`;

const StyledCloseButton = styled.button`
  flex-shrink: 0;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background-color: ${COLORS.greyBg};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;

  & svg > * {
    stroke: ${COLORS.blue};
  }
`;

const Popup = ({ children, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => document.body.removeAttribute('style');
  }, []);

  const header = useMemo(
    () => (
      <StyledHeaderContainer>
        <Logo />
        <StyledCloseButtonContainer>
          {onClose && t('Больше не показывать')}
          {onClose && <StyledCloseButton onClick={onClose ?? undefined}>{closeIcon}</StyledCloseButton>}
        </StyledCloseButtonContainer>
      </StyledHeaderContainer>
    ),
    [onClose, t],
  );

  return (
    <StyledPopupContainer>
      <OuterContainer header={header}>{children}</OuterContainer>
    </StyledPopupContainer>
  );
};

Popup.defaultProps = {
  showLogo: true,
};

Popup.propTypes = {
  showLogo: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export { Popup };
