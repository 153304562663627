import { useCallback } from 'react';
import { navigateToRoute } from '../helpers/routes';
import { useHistory, useLocation} from 'react-router-dom';

export const useRouteNavigateCallback = ({ to, replace = false }) => {
  const history = useHistory();
  const location = useLocation();
  return useCallback((e) => {
    e.preventDefault();
    navigateToRoute({ to, location, history, replace })
  }, [to, replace, history, location]);
};
