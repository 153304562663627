import { v4 as uuid } from 'uuid';

export class CustomStore {
  constructor(initialData) {
    this.handlers = [];
    this._data = initialData;
  }

  get data() {
    return this._data;
  }

  set data(t) {
    this._data = t;
    this.handlers.forEach(h => {
      h.handler(this._data);
    });
  }

  watch(callback) {
    let id = uuid();
    this.handlers.push({ id, handler: callback });
    return id;
  }

  unwatch(id) {
    const idx = this.handlers.findIndex(h => h.id === id);
    this.handlers = [...this.handlers.slice(0, idx), ...this.handlers.slice(idx + 1)];
  }
}
