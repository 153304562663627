import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { EMOJI_ASSETS } from './assets';
import PropTypes from 'prop-types';

const StyledEmoji = styled((props) => {
  return <img className={props.className} src={props.src} alt={props.name} ref={props.forwardRef} />;
})`
  display: inline;
  position: relative;
  height: ${(props) => props.height}px;
  top: -3px;
`;

const Emoji = (props) => {
  const ref = useRef(null);
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    if (ref.current) {
      try {
        const regex = /^([0-9]+)(em|px|%)$/gi;
        const computedStyle = window.getComputedStyle(ref.current.parentNode);
        const fontSize = Number(computedStyle.fontSize.replace(regex, '$1'));
        if (!isNaN(fontSize)) {
          setFontSize(fontSize);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return <StyledEmoji height={fontSize} src={EMOJI_ASSETS[props.name]} alt={`emoji-${props.name}`} forwardRef={ref} />;
};

/**
 *
 * @param {('game-die'|'high-voltage'|'star'|'waving-hand')} name name of Emoji
 * @param {boolean} withUrl — default true, returns string with `url()`
 * @return {string}
 */
Emoji.getEmojiUrl = (name, withUrl = true) => {
  if (withUrl) {
    return `url(${EMOJI_ASSETS[name]})`;
  } else {
    return EMOJI_ASSETS[name];
  }
};

Emoji.propTypes = {
  name: PropTypes.oneOf(['game-die', 'lightning-bolt', 'star', 'waving-hand', 'parachute']),
};

export { Emoji };
