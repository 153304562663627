import React, { useState } from 'react';
import styled from 'styled-components';
import { FormGroup } from '../../common/components/FormGroup';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common/components/Button';
import { ValidationText } from '../../common/components/ValidationText';
import { postFeedback } from '../../common/api/feedback-api';
import { Spinner } from '../../common/components/Spinner';
import image from './focused_working.svg';

const FEEDBACK_MIN_LENGTH = 10;
const FEEDBACK_MAX_LENGTH = 5000;

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 24px;
  }
`;
const StyledImage = styled.img`
  height: 125px;
`;
const StyledText = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px;
  text-align: center;
  width: 100%;
`;

const Feedback = () => {
  const { t } = useTranslation();
  const [feedbackText, setFeedbackText] = useState('');
  const [validation, setValidation] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const feedbackTextLength = feedbackText.length;
  const isValid = feedbackTextLength >= FEEDBACK_MIN_LENGTH && feedbackTextLength <= FEEDBACK_MAX_LENGTH;

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (isValid) {
      setValidation(false);
      setLoading(true);
      try {
        const data = await postFeedback(feedbackText);
        setLoading(false);
        if (data.code >= 200 && data.code < 300) {
          setSuccess(true);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setValidation(true);
    }
  };

  const validationProp = (validation && !isValid) || feedbackTextLength > FEEDBACK_MAX_LENGTH;

  return (
    <StyledContainer>
      <Spinner show={loading} overlay />
      {success && (
        <>
          <StyledImage src={image} />
          <StyledText>{t('Спасибо за обращение! Мы постараемся ответить вам в течение 24 часов.')}</StyledText>
        </>
      )}
      {!success && (
        <StyledForm onSubmit={handleOnSubmit}>
          <FormGroup
            validation={validationProp}
            as="textarea"
            value={feedbackText}
            onChange={setFeedbackText}
            label={t('Пожалуйста, напишите нам')}
            scroll={true}
          />
          <ValidationText validation={validationProp}>
            {t(
              'От {{feedbackMinLength}} до {{feedbackMaxLength}} символов ({{feedbackLength}} / {{feedbackMaxLength}})',
              {
                feedbackMinLength: FEEDBACK_MIN_LENGTH,
                feedbackMaxLength: FEEDBACK_MAX_LENGTH,
                feedbackLength: feedbackTextLength,
              },
            )}
          </ValidationText>
          <Button type="submit">{t('Отправить')}</Button>
        </StyledForm>
      )}
    </StyledContainer>
  );
};

export { Feedback };
