import { config } from '../../config';

export const formatPrice = (price, currency = 'RUB', locale = config.languageCode) => {
  price = Number(price);

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(price);
};
