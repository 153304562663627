import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Heading } from '../../common/components/Heading';
import styled from 'styled-components';
import { TransitionListItem } from '../../common/components/TransitionListItem';
import balloon from '../Congratulations/assets/balloon.svg';

import { Button } from '../../common/components/Button';
import { OuterContainer } from '../../common/components/OuterContainer';
import Box from '../../common/components/Box';
import { useRouteNavigateCallback } from '../../common/hooks/use-navigate';
import { ROUTES } from '../../app/routing';

const StyledImage = styled.img`
  width: auto;
  height: 160px;
`;

const UnitesConfirmation = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('Готово');
  }, []); //eslint-disable-line

  const navigateToUnitesCallback = useRouteNavigateCallback({
    to: ROUTES.unites.path,
    replace: true
  });

  return (
    <OuterContainer>
      <TransitionListItem show direction="down">
        <Box mt="64px">
          <StyledImage src={balloon} />
        </Box>
        <Box mt="24px">
          <Heading>{t('Готово')}!</Heading>
        </Box>
        <Box mt="8px">
          <p>
            <Trans t={t} i18nKey="Мы познакомили вас в почте или Telegram">
              Мы познакомили вас в почте или Telegram. <br/><br/>
              Если в почте – нажмите кнопку “ответить всем” и напишите письмо. Иногда юнайт попадает в папку спам. <br/>
              Если в Telegram — наш бот уже отправил контакты, осталось только написать 😉
            </Trans>
            </p>
        </Box>
        <div className="place-bottom place-bottom--how-to">
          <Button onClick={navigateToUnitesCallback}>{t('Продолжить')}</Button>
        </div>
      </TransitionListItem>
    </OuterContainer>
  );
};

export { UnitesConfirmation };
