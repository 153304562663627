import React, { useCallback, useEffect, useState } from 'react';
import { Heading } from '../../../common/components/Heading';
import { Margin } from '../../../common/components/Margin';
import { PlusBadge } from '../../../common/components/PlusBadge';
import { getFaces } from '../../../common/api/subscription-api';
import { shuffleArray } from '../../../common/helpers/shuffle-array';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

const StyledUsersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 8px;

  > span {
    line-height: 0;
  }
`;

const StyledUser = styled((props) => {
  return props.src !== null ? (
    <div className={props.className} title={props.title} />
  ) : (
    <Skeleton height={0} className={props.className} title={props?.title ?? undefined} />
  );
})`
  border-radius: 6px;
  padding-bottom: 100%;
  overflow: hidden;
  background: url("${({ src }) => src}");
  background-size: cover;
`;

const UniterPlusUsers = () => {
  const [users, setUsers] = useState([...Array(12).keys()].map(() => ({ pic: null })));

  const fetchFaces = useCallback(async () => {
    const response = await getFaces();
    const faces = shuffleArray(response?.faces.filter((f) => f.pic !== null));

    setUsers(faces);
  }, []);

  useEffect(() => {
    fetchFaces();
  }, [fetchFaces]);

  return (
    <>
      <Margin top={32}>
        <Heading level={2}>
          Присоединяйтесь к сообществу
          <PlusBadge sm dontLink />
        </Heading>
      </Margin>
      <Margin as="p" top={16} bottom={16}>
        Десятки людей уже пользуются преимуществами Uniter+. Спасибо, мы постараемся оправдать доверие.
      </Margin>
      <StyledUsersContainer>
        {users.map((u, idx) => (
          <StyledUser key={u + idx} src={u.pic} title={`${u?.first_name} ${u?.last_name}`} />
        ))}
      </StyledUsersContainer>
    </>
  );
};

export { UniterPlusUsers };
