import { getMeetingUnite, getNextMeeting } from '../api/meetings-api';
import { SET_MEETINGS } from '../../store/action-types';

export const actualizeMeetings = async (dispatch) => {
  try {
    const { united, week, code: uniteResponseCode } = await getMeetingUnite();
    const { meetings, code: meetingResponseCode } = await getNextMeeting();
    dispatch({ type: SET_MEETINGS, payload: { united, week, meetings } });

    return uniteResponseCode === 200 && meetingResponseCode === 200;
  } catch (e) {
    console.error(e);
  }
};
