import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getProfileEnums } from '../../common/api/profile-api';
import { makeRegistration, makeRegistrationComplete, makeRegistrationRequest } from '../../common/api/registration-api';
import { Br } from '../../common/components/Br';
import { Button } from '../../common/components/Button';
import { EditAvatar } from '../../common/components/EditAvatar';
import { Emoji } from '../../common/components/Emoji';
import { FormGroup } from '../../common/components/FormGroup';
import { Heading } from '../../common/components/Heading';
import { Hint } from '../../common/components/Hint';
import { Margin } from '../../common/components/Margin';
import { MultiSelect } from '../../common/components/MultiSelect';
import { PaymentOverlay } from '../../common/components/PaymentOverlay';
import { Spinner } from '../../common/components/Spinner';
import { TermsText } from '../../common/components/TermsText';
import { Tip } from '../../common/components/Tip';
import { TransitionListItem } from '../../common/components/TransitionListItem';
import { ValidationText } from '../../common/components/ValidationText';
import { WideHeaderContainer } from '../../common/components/WideHeaderContainer';
import {
  COLORS,
  EMPTY_LEAD,
  INTRO_MAX_LENGTH,
  INTRO_MIN_LENGTH,
  INVITE_CODE_PLACEHOLDER,
  LEAD,
  LS_INVITATION_KEY,
  REGISTERED,
} from '../../common/constants';
import { authorize } from '../../common/helpers/authorize';
import { fbqSendEvent, fpEvents } from '../../common/helpers/facebook-pixel';
import { gaSendEvent, gaSetUser } from '../../common/helpers/google-analytics';
import { readLocalStorage } from '../../common/helpers/ls';
import { facebookLinkValidator, linkedInLinkValidator } from '../../common/validators/validators';
import { config } from '../../config';
import { RegistrationPriorityPass } from './components/registration-priority-pass';
import { ENUMS, ICONS } from './constants';
import { isFormValid, testEmail } from './helpers';

const StyledIntroText = styled.div`
  background-color: ${COLORS.greyBg};
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
  margin-left: 12px;
`;

const StyledIntroTextContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & svg {
    flex-shrink: 0;
    width: 42px;
    height: 42px;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .avatar {
    margin: 0 auto;
  }
`;

const Registration = ({ transition: { stage, stages, direction, pushStage } }) => { //initialStage
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queries = queryString.parse(location.search);

  const LSInvitation = JSON.parse(readLocalStorage(LS_INVITATION_KEY));
  const initialEmail = testEmail(queries.email) || testEmail(LSInvitation?.email);
  const initialInvite = queries.invite || LSInvitation?.invite || config.defaultInviteCode || '';

  const [makeRegistrationCodeStatus, setMakeRegistrationCodeStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentInputRef, setCurrentInputRef] = useState();
  const [message, setMessage] = useState('');
  const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);
  const [validation, setValidation] = useState(0);

  const [enums, setEnums] = useState(ENUMS);
  const [email, setEmail] = useState(initialEmail);
  const [firstStageInvite, setFirstStageInvite] = useState(initialInvite);
  const [completeStageInvite, setCompleteStageInvite] = useState(initialInvite);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [intro, setIntro] = useState('');
  const [professionalInterests, setProfessionalInterests] = useState([]);
  const [city, setCity] = useState(null);
  const [socialNetworks, setSocialNetworks] = useState({ linkedin: '', facebook: '' });
  const [avatarSrc, setAvatarSrc] = useState(null);
  const linkedin = socialNetworks?.linkedin || '';
  const facebook = socialNetworks?.facebook || '';
  const formValid = {
    email: testEmail(email),
    firstName: firstName.length > 1,
    lastName: lastName.length > 1,
    intro: intro.length >= INTRO_MIN_LENGTH && intro.length <= INTRO_MAX_LENGTH,
    professionalInterests: professionalInterests.length > 0,
    city: !!city,
    linkedInValid: linkedin.length === 0 || linkedInLinkValidator.test(linkedin),
    facebookValid: facebook.length === 0 || facebookLinkValidator.test(facebook),
  };

  const onFirstStageSubmit = async (e) => {
    e.preventDefault();
    const valid = formValid.email && formValid.firstName && formValid.lastName;
    if (valid) {
      setValidation(0);
      const { status, url } = await makeRegistrationRequest({ email });

      gaSetUser(email);
      gaSendEvent({
        eventType: 'registration',
        step: 'step1',
      });

      switch (status) {
        case EMPTY_LEAD:
          pushStage(stages[2]);
          setMessage(null);
          return;
        case LEAD:
          history.push(url);
          setMessage(null);
          return;
        case REGISTERED:
          setMessage(t('Пользователь с такой почтой уже существует'));
          return;
        default:
          setMessage(null);
      }
    } else {
      setValidation(1);
    }
  };

  const onSecondStageSubmit = async (e) => {
    e.preventDefault();
    if (formValid.intro) {
      setValidation(0);
      pushStage(stages[3]);
    } else {
      setValidation(2);
    }
  };

  const onThirdStageSubmit = async (e) => {
    e.preventDefault();
    if (formValid.professionalInterests && formValid.city) {
      setValidation(0);
      pushStage(stages[4]);
    } else {
      setValidation(3);
    }
  };

  const onFourthStageSubmit = async (e) => {
    e.preventDefault();
    const valid = isFormValid(formValid);
    if (valid) {
      setValidation(0);

      setLoading(true);
      try {
        const code = await makeRegistration({
          email,
          first_name: firstName,
          last_name: lastName,
          frequented_places: [city.value],
          professional_interests: professionalInterests.map((pi) => pi.value),
          intro_text: intro,
          pic: avatarSrc,
          social_networks: {
            facebook: socialNetworks.facebook || null,
            linkedin: socialNetworks.linkedin || null,
          },
        });

        fbqSendEvent(fpEvents.completeRegistration, { content_name: email });

        try {
          window.ym(83383999, 'reachGoal', 'registration'); // yandex.metrika
        } catch (e) {
          //...
        }

        if (code === 201 && firstStageInvite) {
          try {
            const { auth_token } = await makeRegistrationComplete({
              email,
              invite: firstStageInvite,
              ...(queries.room ? { room: queries.room } : {}),
            });

            if (queries.room) {
              history.push({ search: queryString.stringify({ room: queries.room, loggedByInvite: 1 }) });
            }

            const isAuthorized = await authorize(auth_token);

            if (isAuthorized) {
              return;
            }
          } catch (e) {
            console.error(e);
          }
        }

        setMakeRegistrationCodeStatus(code);

        pushStage(stages.complete);

        setLoading(false);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else {
      setValidation(4);
    }
  };
  const onFifthStageSubmit = async (e) => {
    e.preventDefault();
    if (config.showPriorityPassPayment) return;

    if (completeStageInvite) {
      setValidation(0);
      if (stage === stages.complete || makeRegistrationCodeStatus === 201) {
        setLoading(true);
        try {
          //prettier-ignore
          const { auth_token, message, url, code } = await makeRegistrationComplete({ email, invite: completeStageInvite, });

          if (message === 'invitation-not-valid') {
            setLoading(false);
            setMessage(t('Неверный инвайт код'));

            return;
          }

          if (code === 404 && url) {
            setLoading(false);
            pushStage(stages[1]);
            return;
          }

          setMessage(null);

          // completing registration
          if (queries.room) {
            history.push({
              search: queryString.stringify({
                room: queries.room,
                loggedByInvite: 1,
              }),
            });
          }
          await authorize(auth_token);
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      }
    } else {
      setValidation(5);
    }
  };

  const fetchEnums = async () => {
    try {
      const data = await getProfileEnums();
      const enums = {
        frequentedPlaces: data.enums.frequented_places.map((f) => ({
          label: f,
          value: f,
        })),
        professionalInterests: data.enums.professional_interests.map((f) => ({
          label: f,
          value: f,
        })),
      };
      setEnums(enums);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnSocialNetworksChange = ({ name, value }) => {
    setSocialNetworks((state) => ({ ...state, [name]: value.trim() }));
  };
  const handleAvatarChange = ({ base64 }) => setAvatarSrc(base64);

  useEffect(() => {
    document.title = t('Uniter — Регистрация');
    fetchEnums();
  }, []); //eslint-disable-line

  useEffect(() => {
    setMessage(null);
  }, [completeStageInvite, stage]);

  useEffect(() => {
    if (currentInputRef) {
      currentInputRef.focus();
    }
  }, [currentInputRef]);

  useEffect(() => {
    setCompleteStageInvite(firstStageInvite);
  }, [firstStageInvite]);

  // GA
  useEffect(() => {
    gaSendEvent({
      eventType: 'registration',
      step: stage === 5 ? 'complete' : `step${stage}`,
    });
  }, [stage]);
  useEffect(() => {
    gaSetUser(email);
  }, [email]);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Spinner size="56" overlay show={loading} />
      <PaymentOverlay show={showPaymentOverlay} />

      <TransitionListItem show={stage === stages[1]} direction={direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Регистрация')}</Heading>
        </WideHeaderContainer>
        <StyledForm onSubmit={onFirstStageSubmit}>
          <FormGroup
            onChange={setEmail}
            value={email}
            label="E-mail"
            placeholder="example@google.com"
            validation={validation === 1 && !formValid.email}
            forwardRef={setCurrentInputRef}
            autoComplete="email"
          />
          <Margin top={12}>
            <FormGroup
              onChange={setFirstName}
              value={firstName}
              label={t('Имя')}
              placeholder={t('Илон')}
              validation={validation === 1 && !formValid.firstName}
              autoComplete="given-name"
            />
          </Margin>
          <Margin top={12}>
            <FormGroup
              onChange={setLastName}
              value={lastName}
              label={t('Фамилия')}
              placeholder={t('Маск')}
              validation={validation === 1 && !formValid.lastName}
              autoComplete="family-name"
            />
          </Margin>
          {!config.defaultInviteCode && (
          <Margin top={12}>
            <FormGroup
              onChange={setFirstStageInvite}
              value={firstStageInvite}
              label={t('Инвайт код (если есть)')}
              placeholder={INVITE_CODE_PLACEHOLDER}
              readonly={!!queries.invite}
            />
          </Margin>)}
          {message && <Hint color="red">{message}</Hint>}
          <div className="place-bottom">
            <Button type="submit">{t('Продолжить')}</Button>
          </div>
        </StyledForm>
      </TransitionListItem>

      <TransitionListItem show={stage === stages[2]} direction={direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Расскажите о себе')}</Heading>
        </WideHeaderContainer>
        <p>{t('Представьте, что вас коротко, но конкретно знакомят в письме:')}</p>
        <StyledForm onSubmit={onSecondStageSubmit}>
          <FormGroup
            as="textarea"
            value={intro}
            onChange={setIntro}
            label={t(`Это {{firstName}} —`, { firstName })}
            placeholder={t(
              'founder, CEO, Product Manager at Telegram Messenger. До этого основал “Вконтакте” — крупнейшую социальную сеть в СНГ',
            )}
            validation={validation === 2 && !formValid.intro}
          />
          <ValidationText validation={(validation && !formValid.intro) || intro.length > INTRO_MAX_LENGTH}>
            {t('От {{introMinLength}} до {{introMaxLength}} символов ({{introLength}} / {{introMaxLength}})', {
              introMinLength: INTRO_MIN_LENGTH,
              introMaxLength: INTRO_MAX_LENGTH,
              introLength: intro.length,
            })}
          </ValidationText>
          {message && <Hint color="red">{message}</Hint>}
          <Margin top={32} bottom={32}>
            <Button type="submit">{t('Продолжить')}</Button>
          </Margin>
          <Tip>{t('Несколько примеров')}</Tip>
          <StyledIntroTextContainer>
            {ICONS.menBLue}
            <StyledIntroText>
              {t('Это Мария, в прошлом аудитор из big4, а сейчас основатель собственного бренда одежды.')}
            </StyledIntroText>
          </StyledIntroTextContainer>
          <StyledIntroTextContainer>
            {ICONS.menRed}
            <StyledIntroText>
              {t('Это Василий, он разработчик в Яндексе, программирует дроны для доставки еды.')}
            </StyledIntroText>
          </StyledIntroTextContainer>
        </StyledForm>
      </TransitionListItem>
      <TransitionListItem show={stage === stages[3]} direction={direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Кое-что уточним')}</Heading>
        </WideHeaderContainer>
        <StyledForm onSubmit={onThirdStageSubmit}>
          <MultiSelect
            label={t('Профессиональные интересы')}
            onChange={setProfessionalInterests}
            placeholder={t('стартапы, инвестиции')}
            value={professionalInterests}
            options={enums.professionalInterests}
            isCreatable
            validation={validation === 3 && !formValid.professionalInterests}
          />
          <Margin top={24}>
            <MultiSelect
              label={t('Город')}
              placeholder={t('Санкт-Петербург')}
              value={city}
              onChange={setCity}
              options={enums.frequentedPlaces}
              isMulti={false}
              isCreatable
              validation={validation === 3 && !formValid.city}
            />
          </Margin>
          {message && <Hint color="red">{message}</Hint>}
          <div className="place-bottom">
            <Button type="submit">{t('Продолжить')}</Button>
          </div>
        </StyledForm>
      </TransitionListItem>

      <TransitionListItem show={stage === stages[4]} direction={direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>{t('Почти готово')}</Heading>
        </WideHeaderContainer>
        <StyledForm onSubmit={onFourthStageSubmit}>
          <Margin bottom={20}>
            <p>
              <Trans t={t}>
                Добавьте аватар и ссылки на свои социальные сети. Так собеседник будет иметь более полное представление
                о вас.
              </Trans>
            </p>
          </Margin>
          <EditAvatar
            firstName={firstName}
            lastName={lastName}
            src={avatarSrc}
            size="xl"
            onSave={handleAvatarChange}
            fromRegistration
          />
          <Margin top={24}>
            <FormGroup
              name="linkedin"
              value={socialNetworks.linkedin}
              onChange={handleOnSocialNetworksChange}
              placeholder="linkedin.com/name"
              label="LinkedIn"
              icon="li"
              validation={validation === 4 && !formValid.linkedInValid}
            />
          </Margin>
          <Margin top={12}>
            <FormGroup
              name="facebook"
              value={socialNetworks.facebook}
              onChange={handleOnSocialNetworksChange}
              placeholder="facebook.com/name"
              label="Facebook"
              icon="fb"
              validation={validation === 4 && !formValid.facebookValid}
            />
          </Margin>
          {message && <Hint color="red">{message}</Hint>}
          <div className="place-bottom">
            <Button type="submit">{t('Завершить регистрацию')}</Button>
          </div>
        </StyledForm>
      </TransitionListItem>

      <TransitionListItem show={stage === stages.complete} direction={direction}>
        <WideHeaderContainer marginTop={80}>
          <Heading>
            <Trans t={t}>
              Ваша инвестиция в окружение <Emoji name="parachute" />
            </Trans>
          </Heading>
        </WideHeaderContainer>
        <Margin bottom={20}>
          <p>
            <Trans t={t}>
              Uniter — это закрытая платформа. <Br />
              Мы хотим сохранять атмосферу приватности и беспокоимся о качестве сообщества.
            </Trans>
          </p>
          <p>
            <Trans t={t}>
              Предлагаем общение с приятными людьми, а взамен — немного денег на развитие. <Br />
              Доступ выдается навсегда. Сделка?
            </Trans>
          </p>
          <p>
            <Trans t={t}>
              Более 7.000 человек уже используют юнайтер для поиска партнёров, идей, инвестиций и нужных контактов.
            </Trans>
          </p>
        </Margin>
        <StyledForm onSubmit={onFifthStageSubmit}>
          <FormGroup
            value={completeStageInvite}
            onChange={setCompleteStageInvite}
            label={t('Инвайт код')}
            placeholder={INVITE_CODE_PLACEHOLDER}
            validation={validation === 5 && !completeStageInvite}
            forwardRef={setCurrentInputRef}
          />
          {validation === 5 && completeStageInvite.length < 2 && <Hint color="red">{t('Введите инвайт-код')}</Hint>}
          {message && (
            <Margin top={12}>
              <Hint color="red">{message}</Hint>
            </Margin>
          )}
          {!config.showPriorityPassPayment && (
            <Margin top={32}>
            <div className="place-bottom">
              <Button type="submit">{t('Завершить регистрацию')}</Button>
            </div>
            </Margin>
          )}
        </StyledForm>

        {config.showPriorityPassPayment && (
          <Margin top={32}>
            <RegistrationPriorityPass
              setShowPaymentOverlay={setShowPaymentOverlay}
              allowGetProduct={!!(email && stage > stages[2])}
              firstName={firstName}
              lastName={lastName}
              email={email}
              invite={completeStageInvite || completeStageInvite}
            />
            <Margin top={32}>
              <p style={{fontSize: "87.5%"}}>Напишите <a href="https://t.me/dimavol" target="_blank" rel="noreferrer">нам в Телеграм</a>, если вы предпочитаете оплатить
              доступ картой зарубежного банка или любым другим способом.</p>

              <TermsText>
                <Trans t={t}>
                  Нажимая «купить», вы соглашаетесь с <TermsText.A href="/offer">условиями оплаты</TermsText.A>. По
                  любым вопросам обращайтесь в нашу службу поддержки:{' '}
                  <TermsText.A href="mailto:hi@uniter.ai">hi@uniter.ai</TermsText.A>
                </Trans>
              </TermsText>
            </Margin>
          </Margin>
        )}
      </TransitionListItem>
    </>
  );
};

Registration.propTypes = {
  transition: PropTypes.shape({
    stage: PropTypes.number,
    stages: PropTypes.shape({
      1: PropTypes.number,
      2: PropTypes.number,
      3: PropTypes.number,
      4: PropTypes.number,
      complete: PropTypes.number,
    }),
    direction: PropTypes.string,
    initialStage: PropTypes.number,
    pushStage: PropTypes.func,
  }),
};

export { Registration };
