import { Button } from "../Button";
import React from 'react';
import PropTypes from 'prop-types';

export const Tag = ({ ...props }) =>
  <Button
    variant="light"
    display="inline-block"
    mr="5px"
    width="auto"
    px="12px"
    py="4px"
    {...props} />;

export const Tags = ({ items = [], onSelect }) =>
  Array.isArray(items) ? items.map(tag => {
    const { title = tag, value = tag } = tag;
    return <Tag onClick={() => {
      onSelect(tag)
    }} key={value}>{title}</Tag>
  }) : null;

Tags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.shape({
      color: PropTypes.string,
      fontSize: PropTypes.number
    }))),
  onSelect: PropTypes.func
};
