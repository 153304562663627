import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants';

// eslint-disable-next-line react/prop-types
const InnerA = ({ className, children, ...props }, ref) => (
  <a target="_blank" rel="noreferrer" className={className} ref={ref} {...props}>
    {children}
  </a>
);

const A = styled(React.forwardRef(InnerA))`
  color: ${({ theme, color }) => theme.colors[color] || color || COLORS.blue};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  background: none;

  &:hover {
    text-decoration: underline;
  }
`;

export { A };
