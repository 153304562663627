import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OuterContainer } from '../../common/components/OuterContainer';
import { Heading } from '../../common/components/Heading';
import { Margin } from '../../common/components/Margin';
import { Button } from '../../common/components/Button';
import { useDispatch } from 'react-redux';
import { TransitionListItem } from '../../common/components/TransitionListItem';
import { useHistory } from 'react-router-dom';
import { patchProfileFields } from '../../common/api/profile-api';
import { actualizeProfile } from '../../common/helpers/actualize-profile';
import { Trans, useTranslation } from 'react-i18next';
import { Br } from '../../common/components/Br';
import newMessage from './assets/new-message.svg';
import videoCall from './assets/video-call.svg';
import balloon from './assets/balloon.svg';
import flyingKit from './assets/flying-kite.svg';

const StyledImage = styled.img`
  width: auto;
  height: 160px;
`;

const DIRECTION = 'down';

const Congratulations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [stage, setStage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    document.title = t('Uniter — Поздравляем!');
  }, []); //eslint-disable-line

  const handleOnReceiveCongratulation = async () => {
    try {
      const data = await patchProfileFields({ congratulated: true });
      if (data.code === 204) {
        await actualizeProfile(dispatch);
        history.push('/networking/settings');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <OuterContainer>
      <TransitionListItem show={stage === 0} direction={DIRECTION}>
        <Margin top={64}>
          <StyledImage src={balloon} height={125} />
        </Margin>
        <Margin top={24}>
          <Heading>{t('Поздравляем!')}</Heading>
        </Margin>
        <Margin top={8}>
          <p>
            <Trans t={t}>
              Вы прошли отбор. <Br /> Добро пожаловать в Юнайтер.
            </Trans>
          </p>
        </Margin>
        <div className="place-bottom place-bottom--how-to">
          <Button onClick={() => setStage(1)}>{t('Начать обучение')}</Button>
        </div>
      </TransitionListItem>

      <TransitionListItem show={stage === 1} direction={DIRECTION}>
        <Margin top={64} bottom={24}>
          <StyledImage src={newMessage} height={125} />
        </Margin>
        <p>
          <strong>{t('Каждую неделю мы присылаем письмо на почту с приглашением')}</strong>
        </p>
        <p>{t('Вам необходимо подтвердить готовность и принять участие')}</p>
        <div className="place-bottom place-bottom--how-to">
          <Button onClick={() => setStage(2)}>{t('Далее')}</Button>
        </div>
      </TransitionListItem>

      <TransitionListItem show={stage === 2} direction={DIRECTION}>
        <Margin top={64} bottom={24}>
          <StyledImage src={videoCall} height={125} />
        </Margin>
        <p>
          <strong>{t('Мы познакомим вас через e-mail так же, как если бы это сделал общий друг')}</strong>
        </p>
        <p>{t('Нажмите кнопку «Ответить всем» прямо в письме, чтобы договориться о встрече')}</p>
        <div className="place-bottom place-bottom--how-to">
          <Button onClick={() => setStage(3)}>{t('Далее')}</Button>
        </div>
      </TransitionListItem>

      <TransitionListItem show={stage === 3} direction={DIRECTION}>
        <Margin top={64} bottom={24}>
          <StyledImage src={flyingKit} height={125} />
        </Margin>
        <p>
          <strong>{t('Окружите себя лучшими профессионалами из разных точек мира, где бы вы ни находились')}</strong>
        </p>
        <p>{t('Юнайтер позволяет «расставить флажки» во всех индустриях, чтобы чувствовать, куда дует ветер')}</p>
        <div className="place-bottom place-bottom--how-to">
          <Button onClick={handleOnReceiveCongratulation}>{t('Поехали!')}</Button>
        </div>
      </TransitionListItem>
    </OuterContainer>
  );
};

export { Congratulations };
