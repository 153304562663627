import React from 'react';
import { Card } from '../Card';
import { Avatar } from '../Avatar/avatar';
import { PlusBadge } from '../PlusBadge';
import { COLORS } from '../../constants';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { A } from '../A';
import Skeleton from 'react-loading-skeleton';

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledCredentialsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 16px;
`;

const StyledName = styled.span`
  font-weight: 600;
`;

const StyledCredential = styled.span`
  color: ${COLORS.grey1Text};
  font-size: 13px;
  white-space: nowrap;
`;

const StyledIntro = styled.div`
  color: ${COLORS.black};
  overflow-wrap: break-word;
  font-size: 16px;
`;

const StyledSocialNetwork = styled(A)`
  color: ${COLORS.grey1Text};
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  margin-right: 12px;

  &:hover {
    text-decoration: underline;
    color: #1877f2;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const UserCard = ({
  firstName,
  lastName,
  intro,
  hasSubscription,
  city,
  pic,
  links,
  footer,
  children,
  showFooterSeparator,
}) => {
  const name = `${firstName ?? ''} ${lastName ?? ''}`;

  return (
    <Card>
      <StyledHeader>
        <Avatar src={pic} firstName={firstName} lastName={lastName} hasSubscription={hasSubscription} size={56} />
        <StyledCredentialsContainer>
          <StyledName>
            {name}
            {hasSubscription && <PlusBadge />}
          </StyledName>
          <StyledCredential>
            {city ??
              (links
                ? links.map(({ href, icon }) => {
                    return (
                      <StyledSocialNetwork key={href} href={href}>
                        <Icon size={18} name={icon} />
                      </StyledSocialNetwork>
                    );
                  })
                : null)}
          </StyledCredential>
        </StyledCredentialsContainer>
      </StyledHeader>
      <StyledIntro>{intro}</StyledIntro>
      <Container />
      {showFooterSeparator && <hr />}
      {footer || children}
    </Card>
  );
};

const StyledCard = styled(Card)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledCardCredentials = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 16px;
`;

const UserCardSkeleton = (props) => (
  <StyledCard {...props}>
    <StyledCardHeader>
      <Skeleton circle height={48} width={48} style={{ marginRight: 16 }} />
      <StyledCardCredentials>
        <Skeleton width={180} />
        <Skeleton width={120} />
      </StyledCardCredentials>
    </StyledCardHeader>
    <Skeleton count={3} />
    <Skeleton height={44} style={{ marginTop: 16 }} />
  </StyledCard>
);

UserCard.Skeleton = UserCardSkeleton;

UserCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  intro: PropTypes.string,
  city: PropTypes.string,
  hasSubscription: PropTypes.bool,
  pic: PropTypes.string,
  footer: PropTypes.element,
  links: PropTypes.array,
  children: PropTypes.any,
  showFooterSeparator: PropTypes.bool,
};

UserCard.Container = Card;

export { UserCard };
