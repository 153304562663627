import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import { space, layout, compose } from 'styled-system';


const buttonProps = compose(
  space,
  layout
);

const transitionMixin = css`
  transition: transform 0.15s;
  backface-visibility: hidden;

  &:hover:not(:disabled) {
    transform: translateY(-1px);
  }
`;

const defaultMixIn = css`
  background-color: ${COLORS.blue};
  box-shadow: 0 4px 8px rgba(38, 105, 227, 0.15);
  border-radius: 6px;
  padding: 16px 34px;
  color: ${COLORS.white};
  text-decoration: none;
  width: 100%;

  ${transitionMixin};
`;

const variants = {
  default: defaultMixIn,
  small: css`
   ${defaultMixIn};
   ${transitionMixin};
   padding-top: 8px;
   padding-bottom: 8px;
  `,
  light: css`
    margin-top: 16px;
    background: #e4ecfb;
    border-radius: 6px;
    color: ${COLORS.blue};
    padding: 12px 16px;
    font-size: 13px;
    width: 100%;
    line-height: 20px;
    ${transitionMixin};

    &:disabled {
      background-color: ${COLORS.grey2};
      color: ${COLORS.grey1Text};

      &:hover {
        cursor: unset;
      }
    }
  `,
  simple: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.grey1Text};
  `,
  square: css`
    ${defaultMixIn};

    padding: 0;
    line-height: 0;
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      stroke: ${COLORS.white};
    }

    &:disabled {
      & svg {
        stroke: ${COLORS.grey2Text} !important;
      }
    }
  `,
  plus: css`
    ${defaultMixIn};
    background: ${COLORS.orangeGradient};
    background-size: 400px 100%;
    box-shadow: 0 4px 8px rgb(239, 173, 41, 0.35);
    font-weight: 600;
    transition: all 0.15s;

    &:hover {
      box-shadow: 0 8px 24px rgb(239, 173, 41, 0.5);
    }

    &:disabled {
      background: ${COLORS.grey2};
      color: ${COLORS.grey1Text};

      &:hover {
        cursor: unset;
        box-shadow: none;
      }
    }
  `,
};

const Button = styled('button')`
  background: none;
  -webkit-appearance: none;
  user-select: none;
  line-height: 24px;
  font-weight: 500;

  font-size: 16px;

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    background-color: ${COLORS.greyBg};
    color: ${COLORS.grey2Text};
  }

  display: flex;
  justify-content: center;

  & svg {
    margin-top: -2px;
  }

  ${(p) => variants[p.variant]};
  
  ${buttonProps}
`;

Button.defaultProps = {
  variant: 'default',
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'small', 'simple', 'light', 'square', 'plus']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Button.Container = Button;

export { Button };
