import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import PropTypes from 'prop-types';

const ValidationText = styled(({ className, text, children }) => {
  return <div className={className}>{text || children}</div>;
})`
  margin-top: 8px;
  line-height: 16px;
  font-size: 12px;
  color: ${(p) => (p.validation ? COLORS.red : COLORS.grey1Text)};
  width: 100%;
`;

ValidationText.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  validation: PropTypes.bool,
};

export { ValidationText };
