import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from '../Heading';
import { Margin } from '../Margin';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

const ANIMATION_DURATION = 200;

const closeIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6L18 18M18 6L6 18" stroke="#131C25" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const transitionController = ({ state, entering, entered, exiting }) => {
  switch (state) {
    case 'entering':
      return entering;
    case 'entered':
      return entered;
    case 'exiting':
      return exiting;
    default:
      return '';
  }
};

const StyledOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;

  transition: opacity 200ms;
  opacity: 0;

  ${(p) =>
    transitionController({
      state: p.state,
      entering: 'opacity: 0;',
      entered: 'opacity: 1;',
      exiting: 'opacity: 0;',
    })}
`;

const StyledModal = styled.div`
  overflow: auto;
  position: fixed;
  background: white;
  border-radius: 6px;
  max-width: 434px;
  max-height: 85vh;
  width: 100%;
  height: auto;
  left: 50%;
  top: 50%;
  z-index: 10;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  transition: all 0.2s ease-out;

  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;

  @media screen and (max-width: 380px) {
    padding: 16px;
  }

  ${(p) =>
    transitionController({
      state: p.state,
      entering: 'opacity: 0; transform: translate(-50%, -50%) scale(0.9);',
      entered: 'opacity: 1; transform: translate(-50%, -50%) scale(1);',
      exiting: 'opacity: 0; transform: translate(-50%, -50%) scale(0.9);',
    })}
`;

const StyledCloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  z-index: 11;
  svg {
    display: block;
  }
`;

const domNode = document.getElementById('modal');

const Modal = ({ show, title, children, onLeave, onExited, showCloseButton }) => {
  const [_show, setShow] = useState(false);

  const handleOnClose = () => {
    onLeave();
    setTimeout(onExited, ANIMATION_DURATION);
  };

  useEffect(() => {
    setShow(show);


    const onEscEventListener = (e) => {
      if (e.keyCode === 27) {
        handleOnClose();
      }
    };

    if (show) {
      window.addEventListener('keyup', onEscEventListener);
    }

    return () => window.removeEventListener('keyup', onEscEventListener);
  }, [show]); //eslint-disable-line

  return ReactDOM.createPortal(
    <Transition in={_show} timeout={{ exit: ANIMATION_DURATION, enter: 200 }} mountOnEnter unmountOnExit>
      {(state) => (
        <>
          <StyledOverlay state={state} onClick={handleOnClose} />
          <StyledModal state={state}>
            {showCloseButton && <StyledCloseButton onClick={handleOnClose}>{closeIcon}</StyledCloseButton>}
            <Margin bottom={32}>
              <Heading level={2}>{title}</Heading>
            </Margin>
            {children}
          </StyledModal>
        </>
      )}
    </Transition>,
    domNode,
  );
};

Modal.defaultProps = {
  showCloseButton: true,
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onLeave: PropTypes.func,
  onExited: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

export { Modal };
