import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { ConfettiContext } from './confetti-context';
import ReactConfetti from 'react-confetti';
import PropTypes from 'prop-types';

const node = document.getElementById('root');

const ConfettiProvider = ({ children }) => {
  const [confetti, setConfetti] = useState(null);
  const showConfetti = useCallback((settings) => setConfetti(settings), []);
  const handleOnConfettiComplete = useCallback(
    function () {
      if (confetti.onConfettiComplete) {
        confetti.onConfettiComplete.call(this, arguments);
      }

      setConfetti(null);
    },
    [confetti],
  );

  return (
    <ConfettiContext.Provider value={{ showConfetti }}>
      {children}
      {confetti &&
        createPortal(
          <ReactConfetti
            run={!!confetti}
            recycle={false}
            {...confetti}
            onConfettiComplete={handleOnConfettiComplete}
          />,
          node,
        )}
    </ConfettiContext.Provider>
  );
};

ConfettiProvider.propTypes = {
  children: PropTypes.element,
};

export { ConfettiProvider };
