import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Heading } from '../../common/components/Heading';
import { FormGroup } from '../../common/components/FormGroup';
import { Button } from '../../common/components/Button';
import { Header } from '../../common/components/Header';
import { COLORS, INTRO_MAX_LENGTH, INTRO_MIN_LENGTH, MOBILE_BREAKPOINT } from '../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileState } from '../../store/profile/selectors';
import { Margin } from '../../common/components/Margin';
import { unauthorize } from '../../common/helpers/unauthorize';
import { Container } from '../../common/components/Container';
import { getProfileEnums, updateProfileFields } from '../../common/api/profile-api';
import { MultiSelect } from '../../common/components/MultiSelect';
import { profileEditingPermissions } from '../../common/permissions';
import { Message } from '../../common/components/Message';
import { getHasSubscription, getMeetingsState } from '../../store/selectors';
import { messageStateController } from '../../common/controllers/message-state-controller';
import { Spinner } from '../../common/components/Spinner';
import { socialNetworkChangeController } from './helpers';
import { ValidationText } from '../../common/components/ValidationText';
import { actualizeProfile } from '../../common/helpers/actualize-profile';
import { facebookLinkValidator, linkedInLinkValidator } from '../../common/validators/validators';
import { useTranslation } from 'react-i18next';
import { TelegramIntegration } from '../TelegramIntegration';
import { PlusBadge } from '../../common/components/PlusBadge';
import { EditAvatar } from '../../common/components/EditAvatar';

const StyledAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  & ${PlusBadge} {
    margin-left: 16px;
    margin-right: -50px;
  }
`;

const StyledLayoutContainer = styled.div`
  position: relative;

  h2 {
    margin-top: 48px;
  }

  .avatar {
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      width: 106px;
      height: 106px;
    }
  }

  ${Margin.Container} {
    width: 100%;
  }
`;

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfileState);
  const { united, meetings } = useSelector(getMeetingsState);
  const message = messageStateController({ status: profile.status, united, meetings });
  const hasSubscription = useSelector(getHasSubscription);

  const modifiedProfessionalInterests = profile.professional_interests.map((pi) => ({ value: pi, label: pi }));
  const modifiedFrequentedPlaces = profile.frequented_places[0]
    ? {
        value: profile.frequented_places[0],
        label: profile.frequented_places[0],
      }
    : null;

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(profile.email);
  const [first_name, setFirstName] = useState(profile.first_name);
  const [last_name, setLastName] = useState(profile.last_name);
  const [intro_text, setIntroText] = useState(profile.intro_text);
  const [frequented_places, setFrequentedPlaces] = useState(modifiedFrequentedPlaces);
  const [professional_interests, setProfessionalInterests] = useState(modifiedProfessionalInterests);
  const [isEdited, setEdited] = useState(false);
  const [introFocused, setIntroFocused] = useState(false);

  const [social_networks, setSocialNetworks] = useState(profile.social_networks);

  const handleSocialNetworksChange = useCallback(({ name, value }) => {
    setSocialNetworks(socialNetworkChangeController(name, value.trim()));
  }, []);

  const [validation, setValidation] = useState(false);

  const linkedin = social_networks?.linkedin || '';
  const facebook = social_networks?.facebook || '';
  const linkedInValid = linkedin?.length === 0 || linkedInLinkValidator.test(linkedin);
  const facebookValid = facebook?.length === 0 || facebookLinkValidator.test(facebook);
  const firstnameValid = first_name?.length > 0;
  const lastnameValid = last_name?.length > 0;
  const professionalInterestsValid = professional_interests?.length > 0;
  const introValid = intro_text?.length >= INTRO_MIN_LENGTH && intro_text?.length <= INTRO_MAX_LENGTH;
  //prettier-ignore
  const formValid = linkedInValid && facebookValid && firstnameValid && lastnameValid && professionalInterestsValid && introValid;

  const [enums, setEnums] = useState({});

  useEffect(() => {
    document.title = t('Uniter — Профиль');
  }, []); //eslint-disable-line

  const fetchEnums = useCallback(async () => {
    setLoading(true);
    const data = await getProfileEnums();
    setEnums({
      frequented_places: data.enums.frequented_places.map((f) => ({
        label: f,
        value: f,
      })),
      professional_interests: data.enums.professional_interests.map((f) => ({
        label: f,
        value: f,
      })),
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchEnums();
  }, [fetchEnums]);

  useEffect(() => {
    const isEdited =
      first_name !== profile.first_name ||
      last_name !== profile.last_name ||
      intro_text !== profile.intro_text ||
      JSON.stringify(modifiedProfessionalInterests) !== JSON.stringify(professional_interests) ||
      JSON.stringify(modifiedFrequentedPlaces) !== JSON.stringify(frequented_places) ||
      JSON.stringify(social_networks) !== JSON.stringify(profile.social_networks);

    setEdited(isEdited);

    if (!isEdited) {
      setValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first_name, last_name, intro_text, professional_interests, frequented_places, social_networks]);

  const handleOnSave = useCallback(async () => {
    if (!formValid) {
      setValidation(true);
      return;
    }

    setValidation(false);

    setLoading(true);

    try {
      const data = await updateProfileFields({
        professional_interests: professional_interests.map((pi) => pi.value),
        frequented_places: frequented_places.value ? [frequented_places.value] : [],
        first_name,
        last_name,
        intro_text,
        social_networks,
      });
      if (data.code === 204) {
        await actualizeProfile(dispatch);
        setEdited(false);
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }, [
    dispatch,
    first_name,
    formValid,
    frequented_places.value,
    intro_text,
    last_name,
    professional_interests,
    social_networks,
  ]);

  const isEditingPermitted = useMemo(() => !profileEditingPermissions(profile.status), [profile.status]);

  return (
    <Container>
      <Header>{t('Профиль')}</Header>
      <StyledLayoutContainer>
        <Spinner show={loading} overlay appOverlay />
        {message && <Message text={message.text} title={message.title} link={message.link} fromProfile />}
        {profile.comment && (
          <Margin top={16}>
            <Message text={profile.comment} backgroundColor={COLORS.greyBg} fromProfile />
          </Margin>
        )}
        <StyledAvatarContainer>
          <EditAvatar
            src={profile.pic}
            disabled={isEditingPermitted}
            firstName={profile?.first_name}
            lastName={profile?.last_name}
            hasSubscription={hasSubscription}
          />
          {hasSubscription && <PlusBadge sm />}
        </StyledAvatarContainer>
        <Margin top={24}>
          <FormGroup disabled value={email} onChange={setEmail} placeholder="example@google.com" label="E-mail" />
        </Margin>
        <Margin top={12}>
          <FormGroup
            disabled={isEditingPermitted}
            value={first_name}
            onChange={setFirstName}
            placeholder={t('Иван')}
            label={t('Имя')}
            validation={validation && !firstnameValid}
          />
        </Margin>
        <Margin top={12}>
          <FormGroup
            disabled={isEditingPermitted}
            value={last_name}
            onChange={setLastName}
            placeholder={t('Иванов')}
            label={t('Фамилия')}
            validation={validation && !lastnameValid}
          />
        </Margin>
        <Margin top={12}>
          <MultiSelect
            label={t('Город')}
            onChange={setFrequentedPlaces}
            placeholder={t('Москва и Область')}
            value={frequented_places}
            options={enums.frequented_places}
            isMulti={false}
            disabled={isEditingPermitted}
            isCreatable
          />
        </Margin>
        <Margin top={12}>
          <FormGroup
            value={intro_text}
            onChange={setIntroText}
            as="textarea"
            placeholder={t(
              'Он технический эксперт и предприниматель, ищет инвестиции для нового проекта, увлекается искусством и путешествиями',
            )}
            label={t('Intro')}
            disabled={isEditingPermitted}
            validation={(validation && !introValid) || intro_text?.length > INTRO_MAX_LENGTH}
            onBlur={() => setIntroFocused(false)}
            onFocus={() => setIntroFocused(true)}
          />
          {profile.intro_text !== intro_text && introFocused && (
            <ValidationText validation={(validation && !introValid) || intro_text?.length > INTRO_MAX_LENGTH}>
              {t('От {{introMinLength}} до {{introMaxLength}} символов ({{introLength}} / {{introMaxLength}})', {
                introMinLength: INTRO_MIN_LENGTH,
                introMaxLength: INTRO_MAX_LENGTH,
                introLength: intro_text?.length,
              })}
            </ValidationText>
          )}
        </Margin>
        <Margin top={12}>
          <MultiSelect
            label={t('Профессиональные интересы')}
            onChange={setProfessionalInterests}
            placeholder={t('стартапы, инвестиции')}
            value={professional_interests}
            options={enums.professional_interests}
            disabled={isEditingPermitted || loading}
            isCreatable
            validation={validation && !professionalInterestsValid}
          />
        </Margin>
        <Margin>
          <Heading level={2}>{t('Мои аккаунты')}</Heading>
        </Margin>
        <Margin top={12}>
          <FormGroup
            name="linkedin"
            value={social_networks?.linkedin}
            onChange={handleSocialNetworksChange}
            placeholder="linkedin.com/name"
            label="LinkedIn"
            disabled={isEditingPermitted}
            icon="li"
            iconLink={social_networks?.linkedin}
            validation={!linkedInValid && validation}
          />
        </Margin>
        <Margin top={12}>
          <FormGroup
            name="facebook"
            value={social_networks?.facebook}
            onChange={handleSocialNetworksChange}
            placeholder="facebook.com/name"
            label="Facebook"
            disabled={isEditingPermitted}
            icon="fb"
            iconLink={social_networks?.facebook}
            validation={!facebookValid && validation}
          />
        </Margin>

        {isEdited && (
          <Margin top={32}>
            <Button onClick={handleOnSave} disabled={loading || (validation && !formValid)}>
              {t('Сохранить')}
            </Button>
          </Margin>
        )}

        <Margin>
          <TelegramIntegration />
        </Margin>
      </StyledLayoutContainer>
      <Margin top={64}>
        <Button onClick={unauthorize} variant="simple">
          {t('Выйти из аккаунта')}
        </Button>
      </Margin>
    </Container>
  );
};

export { Profile };
