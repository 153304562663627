import React from 'react';

const fillProps = {
  stroke: 'none',
  fill: 'currentColor',
};

export const icons = {
  linkedin: {
    props: fillProps,
    rects: (
      <>
        <path d="M20.4491 20.4489H16.8931V14.88C16.8931 13.552 16.8694 11.8425 15.0436 11.8425C13.1915 11.8425 12.9081 13.2894 12.9081 14.7833V20.4486H9.35203V8.99659H12.7658V10.5616H12.8136C13.1553 9.97748 13.649 9.49694 14.2421 9.17118C14.8352 8.84542 15.5056 8.68664 16.1819 8.71174C19.7861 8.71174 20.4506 11.0824 20.4506 14.1666L20.4491 20.4489ZM5.33963 7.43119C4.93148 7.43126 4.53247 7.31031 4.19307 7.08361C3.85367 6.85692 3.58913 6.53467 3.43287 6.15762C3.27661 5.78057 3.23566 5.36566 3.31521 4.96534C3.39477 4.56502 3.59125 4.19729 3.8798 3.90863C4.16835 3.61998 4.53602 3.42337 4.93631 3.34367C5.3366 3.26397 5.75152 3.30477 6.12863 3.46089C6.50573 3.61701 6.82807 3.88145 7.05489 4.22077C7.2817 4.56009 7.40281 4.95905 7.40288 5.3672C7.40293 5.6382 7.34959 5.90655 7.24593 6.15694C7.14227 6.40733 6.99032 6.63485 6.79873 6.82651C6.60714 7.01817 6.37966 7.17021 6.12931 7.27396C5.87895 7.37771 5.61062 7.43114 5.33963 7.43119V7.43119ZM7.11765 20.4489H3.5579V8.99659H7.11765V20.4489ZM22.222 0.0016351H1.77099C1.30681 -0.00360316 0.859518 0.175658 0.52744 0.500026C0.195362 0.824394 0.00566506 1.26733 0 1.73151V22.2673C0.00547117 22.7317 0.195057 23.175 0.527123 23.4997C0.85919 23.8244 1.30658 24.004 1.77099 23.9991H22.222C22.6873 24.0049 23.1359 23.8258 23.4693 23.5011C23.8027 23.1764 23.9936 22.7327 24 22.2673V1.73002C23.9934 1.26493 23.8024 0.821488 23.469 0.497127C23.1356 0.172767 22.6871 -0.00598123 22.222 0.000152817" />
      </>
    ),
  },
  facebook: {
    props: fillProps,
    rects: (
      <>
        <path d="M24 12.0733C24 5.40541 18.6274 0 12 0C5.37258 0 0 5.40541 0 12.0733C0 18.0995 4.38823 23.0943 10.125 24V15.5633H7.07812V12.0733H10.125V9.41343C10.125 6.38755 11.9165 4.71615 14.6576 4.71615C15.9705 4.71615 17.3438 4.95195 17.3438 4.95195V7.92313H15.8306C14.3399 7.92313 13.875 8.85379 13.875 9.80857V12.0733H17.2031L16.6711 15.5633H13.875V24C19.6118 23.0943 24 18.0995 24 12.0733Z" />
      </>
    ),
  },
  emailFill: {
    props: fillProps,
    rects: (
      <>
        <path d="M1.00452 5.87153C1.00152 5.91402 1 5.95686 1 6V18C1 19.1 1.99 20 3.2 20H20.8C22.01 20 23 19.1 23 18V6C23 5.95686 22.9985 5.91402 22.9955 5.87153L12.5547 12.8321C12.2188 13.056 11.7812 13.056 11.4453 12.8321L1.00452 5.87153ZM21.8391 4.23872C21.5292 4.08653 21.1752 4 20.8 4H3.2C2.82482 4 2.47078 4.08653 2.16085 4.23872L12 10.7981L21.8391 4.23872Z" />
      </>
    ),
  },
};
