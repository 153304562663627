import React from 'react';
import styled from 'styled-components';

export const WideHeaderContainer = styled((p) => <div className={p.className}>{p.children}</div>)`
  width: max-content;
  max-width: 500px;
  margin-top: ${(p) => p.marginTop ?? 32}px;
  margin-bottom: ${(p) => p.marginBottom ?? 40}px;

  @media screen and (max-width: 650px) {
    max-width: calc(100vw - 32px);
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;
