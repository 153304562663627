import React from 'react';
import { Login } from '../../features/Login';
import { useStage } from '../../common/hooks/use-stage';
import { LOGIN_STAGES } from './stages';

const RouteLogin = () => {
  const [{ stage, direction }, setStage] = useStage({
    stages: LOGIN_STAGES,
    initialStage: LOGIN_STAGES.email,
  });

  return (
    <Login
      transition={{
        stage,
        stages: LOGIN_STAGES,
        pushStage: setStage,
        direction,
      }}
    />
  );
};

export { RouteLogin };
