import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';
import { UserCard as UserCommonCard } from '../../../common/components/UserCard';
import { FormGroup } from '../../../common/components/FormGroup';
import { COLORS } from '../../../common/constants';
import Box from '../../../common/components/Box';
import { Spinner } from '../../../common/components/Spinner';
import PropTypes from 'prop-types';
import { useRemoteResource } from '../../../common/hooks/use-remote-resource';
import { makeDatingEmailRequest } from '../../../common/api/dating-api';
import { useRouteNavigateCallback } from '../../../common/hooks/use-navigate';
import { ROUTES } from '../../../app/routing';

const UserCard = ({
  id,
  pic,
  firstName,
  lastName,
  intro,
  hasSubscription,
  city,
  alreadySend,
  availableDatingCount,
  allowToSendInvite,
  onInvitationSent
}) => {
  const [emailSendingResource, fetchEmailSendingResource] = useRemoteResource();

  const sendEmailCallback = useCallback(
    text => {
      fetchEmailSendingResource(makeDatingEmailRequest(text, id));
    },
    [fetchEmailSendingResource, id]
  );

  useEffect(() => {
    emailSendingResource.case({
      Loaded: () => {
        onInvitationSent();
      },
      _: () => null
    });
  }, [onInvitationSent, emailSendingResource]);

  return (
    <UserCommonCard
      intro={intro}
      firstName={firstName}
      lastName={lastName}
      hasSubscription={hasSubscription}
      city={city}
      pic={pic}
    >
      <DatingEmailForm
        availableDatingCount={availableDatingCount}
        onFormSubmit={sendEmailCallback}
        allowToSendInvite={allowToSendInvite}
        inviteAlreadySent={emailSendingResource.isLoaded() || alreadySend}
        emailSendingResource={emailSendingResource}
      />
    </UserCommonCard>
  );
};

const letterButtonProps = {
  variant: 'small',
  mt: 10
};

const getDatingButtonText = ({
  t,
  inviteAlreadySent,
  availableDatingCount
}) => {
  if (inviteAlreadySent) {
    return t('Приглашение отправлено');
  }

  if (availableDatingCount === 0) {
    return t('Вы исчерпали лимит приглашений на этой неделе');
  }

  return t('Предложить юнайт -1U');
};
const isDatingButtonDisabled = ({
  availableDatingCount,
  inviteAlreadySent,
  allowToSendInvite
}) => inviteAlreadySent || !allowToSendInvite || availableDatingCount === 0;

const MIN_EMAIL_LENGTH = 15;

const DatingEmailForm = ({
  onFormSubmit,
  availableDatingCount,
  allowToSendInvite,
  inviteAlreadySent,
  emailSendingResource
}) => {
  const [text, setText] = useState('');
  const [datingFormVisible, setDatingFormVisibility] = useState(false);
  const { t } = useTranslation();
  const navigateToPlusCallback = useRouteNavigateCallback({
    to: ROUTES.uniterPlus.path
  });
  return (
    <Box position="relative">
      {!datingFormVisible &&
        (allowToSendInvite ? (
          <Button
            variant="light"
            onClick={() => {
              if (!inviteAlreadySent && allowToSendInvite) {
                setDatingFormVisibility(true);
              }
            }}
            disabled={isDatingButtonDisabled({
              availableDatingCount,
              allowToSendInvite,
              inviteAlreadySent
            })}
          >
            {getDatingButtonText({
              t,
              inviteAlreadySent,
              availableDatingCount
            })}
          </Button>
        ) : (
          <Button variant="light" onClick={navigateToPlusCallback}>
            {t('Улучшите аккаунт до U+ и предложите встречу этому человеку')}
          </Button>
        ))}

      {datingFormVisible && (
        <Box mt={20}>
          <FormGroup
            as="textarea"
            backgroundColor={COLORS.white}
            placeholder={t('Напишите Ваше приветственное письмо')}
            value={text}
            onChange={setText}
          />
          {emailSendingResource.case({
            NotAsked: () => (
              <Button disabled={text.length < MIN_EMAIL_LENGTH } {...letterButtonProps} onClick={() => onFormSubmit(text)}>
                {t('Отправить')}
              </Button>
            ),
            Loading: () => (
              <Button {...letterButtonProps}>
                {t('Отправляем, подождите...')}
              </Button>
            ),
            Loaded: () => setDatingFormVisibility(false),
            Error: () => (
              <Button onClick={() => onFormSubmit(text)} {...letterButtonProps}>
                {t('Возникла ошибка, попробуйте еще')}
              </Button>
            )
          })}
        </Box>
      )}
      <Spinner show={emailSendingResource.isLoading()} overlay />
    </Box>
  );
};

DatingEmailForm.propTypes = {
  emailSendingResource: PropTypes.object,
  onFormSubmit: PropTypes.func,
  allowToSendInvite: PropTypes.bool,
  hasSubscription: PropTypes.bool,
  inviteAlreadySent: PropTypes.bool,
  availableDatingCount: PropTypes.number
};

UserCard.propTypes = {
  id: PropTypes.string,
  pic: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  intro: PropTypes.string,
  city: PropTypes.string,
  allowToSendInvite: PropTypes.bool,
  hasSubscription: PropTypes.bool,
  availableDatingCount: PropTypes.number,
  onInvitationSent: PropTypes.func,
  alreadySend: PropTypes.bool,
};

export { UserCard };
