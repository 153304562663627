/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Logo } from '../Logo';
import { MOBILE_BREAKPOINT } from '../../constants';

const CONTAINER_WIDTH = 370;

const StyledContainerCentring = styled.div`
  max-width: ${CONTAINER_WIDTH * 2 + 64}px;
  margin: 0 auto;

  padding: 66px 32px 96px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px 16px 16px;
    max-width: ${CONTAINER_WIDTH * 2 + 32}px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  max-width: ${CONTAINER_WIDTH}px;
  min-height: 700px;
  width: 100%;

  @media screen and (max-height: 800px) {
    min-height: unset;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
    max-height: unset;
    min-height: calc(${window.innerHeight}px - 32px);
  }

  .place-bottom {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 32px;
    min-height: 56px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      &:not(.place-bottom--how-to) {
        flex: unset;
        justify-content: unset;
      }
    }
  }
`;

const OuterContainer = ({ children, header }) => {
  return (
    <StyledContainerCentring>
      {header ?? <Logo toOutside="/" />}
      <StyledContainer>{children}</StyledContainer>
    </StyledContainerCentring>
  );
};

OuterContainer.Container = StyledContainer;

OuterContainer.defaultProps = {
  logo: false,
};

export { OuterContainer };
