import { readLocalStorage, writeLocalStorage } from '../helpers/ls';
import { isPast, addDays, getUnixTime, fromUnixTime } from 'date-fns';

/**
 * SPAM promotion controller with delay-show-again ability (works only with client's localStorage)
 * @param {string} name name of feature which will use in localStorage
 * @return {(function(delay: {number}): boolean)|boolean} — returns function which writes delay-to-show-again time to localStorage
 */
export const delayedShowController = (name) => {
  let value = readLocalStorage(name);
  let isTimeToShow;

  try {
    value = JSON.parse(value);
    if (Number.isInteger(value)) {
      isTimeToShow = isPast(fromUnixTime(value));
    } else {
      isTimeToShow = value === false ? value : true;
    }
  } catch (e) {
    console.error(e);
  }

  if (isTimeToShow) {
    return (delay) => {
      if (delay === false) {
        writeLocalStorage(name, delay);
        return delay;
      }

      const nextTimeShowFeature = getUnixTime(addDays(new Date(), delay));
      writeLocalStorage(name, nextTimeShowFeature);
      return false;
    };
  }

  return false;
};
